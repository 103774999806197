<template>
    <div class="my-alert-bg" v-if="isShow">
        <div class="my-alert">
            <div class="my-alter-header" v-if="viewTitle">{{ title }}</div>
            <div class="my-alert-main">{{ text }}</div>
            <div class="my-alert-footer d-flex align-items-center">
                <button type="button" class="btn btn-sm btn-radius4 btn-danger ml-auto mr-2" @click="answer('no')"> {{ noButtonText }}</button>
                <button type="button" class="btn btn-sm btn-radius4 btn-green ml-2" @click="answer('yes')"> {{ yesButtonText }}</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "MyConfirm",
        data(){
            return {
                isShow:false,
                title:'',
                text:'',
                yesButtonText:'Ja',
                noButtonText:'Nee',

                resolve:null,
                reject:null
            }
        },
        computed:{
            viewTitle(){
                return this.title.length > 0;
            }
        },
        methods:{
            hideAlert(){
                this.isShow = false;
            },
            answer(a){
                if(this.resolve !== null){
                    this.resolve(a);
                }
                this.hideAlert();
            },
            show(params){
                this.title          = params.title ?? '';
                this.text           = params.text ?? '';
                this.yesButtonText  = params.yesButtonText ?? 'Ja';
                this.noButtonText   = params.noButtonText ?? 'Nee';

                this.resolve        = params.resolve ?? null;
                this.reject         = params.reject ?? null;
                this.isShow         = true;
            }
        },
        mounted() {
            window.eventBus.$on('myConfirmView', this.show);
        },
        destroyed() {
            window.eventBus.$off('myConfirmView', this.show);
        }
    }
</script>

<style scoped>

</style>