<template>
  <div class="container f-page-inner">
    <PageSteps/>
    <div class="page-component">
      <component v-if="existComponent(routeComponent)" v-bind:is="routeComponent"/>
      <div v-else>
        {{ $root.gotoHome() }}
      </div>
    </div>
  </div>
</template>

<script>
    import PageSteps from "../components/PageSteps";
    import Package_select from "../components/Package_select";
    import Samenstellen from "../components/Samenstellen";
    import Telefoonummers from "../components/Telefoonummers";
    import Nieuw_nummer from "../components/Nieuw_nummer";
    import Nummerbehound from "../components/Nummerbehound";
    import Hardware from "../components/Hardware";
    import Ip_toestellen from "../components/Ip_toestellen";
    import Vamo from "../components/Vamo";
    import Vast_op_mobiel_paketten from "../components/Vast_op_mobiel_paketten";
    import Mobil_paketten_samenstellen from "../components/Mobil_paketten_samenstellen";
    import Winkelmand from "../components/Winkelmand";
    import Gebruikers from "@/components/Gebruikers";

    export default {
        name: "MainPage",
        components: {
            PageSteps,
            Package_select,
            Samenstellen,
            Telefoonummers,
            Nieuw_nummer,
            Nummerbehound,
            Hardware,
            Ip_toestellen,
            Vamo,
            Vast_op_mobiel_paketten,
            Mobil_paketten_samenstellen,
            Winkelmand,
            Gebruikers
        },
        computed: {
            routeComponent() {
                if (this.$route.params.hasOwnProperty("sub")) {
                    return this.$options.filters.capitalize(this.$route.params.sub).replace(/\-/g, '_');
                }
                return '';
            }
        },
        methods: {
            existComponent(componentName) {
                let names = Object.keys(this.$options.components);
                let name = this.$options.filters.capitalize(componentName);
                name = name.replace(/\-/g, '_');
                if(names.includes(name)){
                    this.$root.appEvents.emit('changed-page', this.$options.filters.deCapitalize(name));
                }
                return names.includes(name);
            }
        }
    }
</script>

<style lang="scss">
.f-page-inner{
  padding-top: 65px;
}
</style>