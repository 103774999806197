<template>
    <div class="hw-basket-action number-counter d-flex justify-content-center align-items-center">
        <button class="btn btn-icon-border up-btn" :disabled="minusDisable" @click="decreaseProduct()">
            <i class="fal fa-minus"/>
        </button>
        <div class="circle-progress hidden-percent mx-2" :id="'chart_'+chartID">
            <div class="hw-counter-text" :class="addMe">
                <span class="gebruiker-count" :id="product.type">{{ basketCount }}</span>
            </div>
            <canvas :id="'chart_canvas_'+chartID"/>
        </div>
        <button class="btn btn-icon-border down-btn" @click="increaseProduct()">
            <i class="fal fa-plus"/>
        </button>
    </div>
</template>

<script>
    export default {
        name: "NumberCounter",
        props:{
            product:{
                type:Object,
                required:true
            },
            pType:String,
            basketCount:[String, Number]
        },
        data(){
            return {
                chart:null,
                chart_canvas:null,
                chart_size:45,
                chart_line:4,
                chart_rotate:5,
                elementId:''
            }
        },
        computed:{
            productId(){
                try{
                    return this.product.id;
                }catch (e) {
                    return 0;
                }
            },
            chartID(){
                return this.productId+this.elementId;
            },
            minusDisable(){
                return this.basketCount < 1;
            },
            addMe(){
                if(this.basketCount > 0){
                    return {};
                }
                return {
                    animate__animated:true,
                    animate__pulse:true,
                    animate__infinite:true
                }
            },
            chart_color1(){ return '#E7E7E7'; },
            chart_color2(){
                return this.basketCount > 0 ?  '#82B636' : this.chart_color1;
            },
            chart_percent(){
                //return this.productCount * 2;
                return (100 * ( Math.sin(Math.PI * ( (0.9 * this.basketCount) / 100 ) ) ));
            }
        },
        watch:{
            chart_percent(val) {
                this.drawCircle(this.chart_color1, 100 / 100);
                this.drawCircle(this.chart_color2, val / 100);
            }
        },
        methods:{
            decreaseProduct(){
                this.$emit("changeCount", "down");
            },
            increaseProduct(){
                this.$emit("changeCount", "up");
            },
            drawCircle(color, pecent) {
                //this.chart_percent = Math.min(Math.max(0, this.chart_percent || 1), 1);
                this.chart._c_ctx.beginPath();
                this.chart._c_ctx.arc(0, 0, this.chart._c_radius, 0, Math.PI * 2 * pecent, false);
                this.chart._c_ctx.strokeStyle = color;
                this.chart._c_ctx.lineCap = 'round'; // butt, round or square
                this.chart._c_ctx.lineWidth = this.chart_line;
                this.chart._c_ctx.stroke();
            },
            chartInit(){
                this.chart          = document.getElementById('chart_'+this.chartID);
                this.chart_canvas   = document.getElementById('chart_canvas_'+this.chartID);

                if (typeof(G_vmlCanvasManager) !== 'undefined') {
                    G_vmlCanvasManager.initElement(this.chart_canvas);
                }
                this.chart._c_ctx = this.chart_canvas.getContext('2d');
                this.chart_canvas.width = this.chart_canvas.height = this.chart_size;
                this.chart._c_ctx.translate(this.chart_size / 2, this.chart_size / 2); /* change center*/
                this.chart._c_ctx.rotate((-1 / 2 + this.chart_rotate / 180) * Math.PI); /* rotate -90 deg */
                this.chart._c_radius = (this.chart_size - this.chart_line) / 2;

                this.drawCircle(this.chart_color1, 100 / 100);
                this.drawCircle(this.chart_color2, this.chart_percent / 100);
            }
        },
        created() {
            this.elementId = this.$options.filters.makeid(6);
        },
        mounted() {
            this.chartInit();

            let evt = new CustomEvent("reinit_owl_carousel", {});
            document.dispatchEvent(evt);
        }
    }
</script>

<style lang="scss">
    .number-counter{
        .hw-counter-text{
            text-align: center;
            line-height: 45px;
            color: #102B44;
            font-weight: bold;
            font-size: 14px;
        }
        /********/
        .circle-progress{
            position:relative;
            width: 45px;
            height: 45px;
        }
        .circle-progress canvas {
            display: block;
            position:absolute;
            top:0;
            left:0;
            z-index: 1;
        }
    }
</style>