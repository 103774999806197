/**
 * HTTP Network Access
 *
 * Used as a fetch wrapper for the HTTP protocol.
 *
 * @author Ayhan Eraslan ayer50@gmail.com
 *
 * Usage:
 *
 * Http.post(process.env.VUE_APP_UNITEPHONE_URL+ "auth.php", function (http) {
 *       http.header("Content-Type", "application/x-www-form-urlencoded");
 *       http.data("method", "save_login_token");
 *       http.data("token", that.login_token);
 *       http.setOption("redirect", "follow");
 *       http.setOption("referrerPolicy", "no-referrer");
 *       http.success = function(response){ resolve(http) };
 *       http.error = function(error){ reject(http) };
 *   });
 *
 *
 */
class Http {

    static METHOD_GET = 'GET';
    static METHOD_POST = 'POST';
    static METHOD_DELETE = 'DELETE';
    static METHOD_PATCH = 'PATCH';
    static METHOD_PUT = 'PUT';
    static METHOD_OPTIONS = 'OPTIONS';

    constructor(){

        /**
         * @var string The HTTP address to use.
         */
        this.url = '';
        /**
         * @var string The method the request should use.
         */
        this.method = '';
        /**
         * @var object The headers to be sent with the request.
         */
        this.headers = {};
        /**
         * @var string The last response body.
         */
        this.body = '';
        /**
         * @var string The last response body (without headers extracted).
         */
        this.rawBody = '';
        /**
         * @var object The last returned HTTP code.
         */
        this.code = {};
        /**
         * @var object response information.
         */
        this.info = {};

        /**
         * @var object request Options.
         * https://javascript.info/fetch-api
         * mode|cache|credentials|redirect|referrerPolicy|...
         */
        this.requestOptions = {};

        /**
         * @var object Request data.
         */
        this.requestData = {};

        this.success = function () {};

        this.error = function () {}

        this._availableOptions = ["referrer", "referrerPolicy", "mode", "credentials", "cache", "redirect ", "integrity ", "keepalive ", "signal ", "window"];
    }

    static make(url, method, option){
        let http = new this();
        http.url = url;
        http.method = method;

        if(typeof option === "function"){
            option(http);
        }

        return http;
    }

    static get(url, option){
        let http = this.make(url, this.METHOD_GET, option);
        return http.send();
    }

    static post(url, option){
        let http = this.make(url, this.METHOD_POST, option);
        return http.send();
    }

    static delete(url, option){
        let http = this.make(url, this.METHOD_DELETE, option);
        return http.send();
    }

    static patch(url, option){
        let http = this.make(url, this.METHOD_PATCH, option);
        return http.send();
    }

    static put(url, option){
        let http = this.make(url, this.METHOD_PUT, option);
        return http.send();
    }


    static options(url, option){
        let http = this.make(url, this.METHOD_OPTIONS, option);
        return http.send();
    }

    _prepareRequest(){
        let that = this;
        let requestObject = {
            method: this.method,
        };

        if(Object.keys(this.headers).length > 0){
            if(!this.headers.hasOwnProperty("Content-Type")){
                this.headers['Content-Type'] = 'application/json';
            }
            //this.headers['X-Requested-With'] = 'XMLHttpRequest';
            requestObject.headers = this.headers;
            requestObject.headers = Object.assign(requestObject.headers, {'X-Requested-With':'XMLHttpRequest'})
        }

        if(Object.keys(this.requestData).length > 0){
            if(this.headers['Content-Type'] === 'application/json'){
                requestObject.body = JSON.stringify(this.requestData);
            }
            else if(this.headers['Content-Type'] === 'application/x-www-form-urlencoded'){
                let urlPrm = new URLSearchParams();
                Object.entries(this.requestData).forEach(function ([k,v]) {
                    urlPrm.append(k, v);
                });
                requestObject.body = urlPrm;
            }
            else {
                let formData = new FormData();
                Object.entries(this.requestData).forEach(function ([k,v]) {
                    formData.append(k, v);
                });
                requestObject.body = formData;
            }
        }

        if(Object.keys(this.requestOptions).length > 0){
            Object.keys(this.requestOptions).forEach(function (key) {
                if(that._availableOptions.includes(key)){
                    requestObject[key] = that.requestOptions[key];
                }
            });
        }

        if(typeof requestObject.mode === "undefined"){
            requestObject.mode = 'cors';
        }
        if(typeof requestObject.credentials === "undefined"){
            requestObject.credentials = 'same-origin';
        }
        return requestObject;
    }

    send(){
        let requestObject = this._prepareRequest();
        let that = this;
        try {
            return fetch(this.url, requestObject).then(function (response) {
                that.rawBody = response;
                that.info.status = response.status;
                that.info.statusText = response.statusText;
                that.info.headers = response.headers;
                that.info.url = response.url;
                return response.text();
            }).then(function (response) {
                that.body = response;
                that.success(that.body);
                return that.body;
            }).catch(function (error) {
                //console.log(error);
                that.error(error);
            });
        }catch (e) {
            that.error(e);
        }
    }

    async syncSend(){
        let requestObject = this._prepareRequest();
        let that = this;
        let response = await fetch(this.url, requestObject);
        //that.success(response);

        return response;
    }

    data(key, value= null){
        let that = this;
        if((typeof key === "object" && !Array.isArray(key) && key !== null)){
            Object.keys(key).forEach(function (k) {
                that.data(k, key[k]);
            });
            return this;
        }
        this.requestData[key] = value;
        return this;
    }

    header(key, value= null){
        let that = this;
        if((typeof key === "object" && !Array.isArray(key) && key !== null)){
            Object.keys(key).forEach(function (k) {
                that.header(k, key[k]);
            });
            return this;
        }
        this.headers[key] = value;
        return this;
    }

    setOption(key, value= null){
        let that = this;
        if((typeof key === "object" && !Array.isArray(key) && key !== null)){
            Object.keys(key).forEach(function (k) {
                that.setOption(k, key[k]);
            });
            return this;
        }
        this.requestOptions[key] = value;
        return this;
    }

    /***** HELPER ****/
    giveAsObject(){
        let data;
        try{
            data = JSON.parse(this.body);
        }
        catch (e) {
            data = {};
            this.$root.errorLog(e);
        }
        return data;
    }
}

export { Http }