const Promotions = {
    data(){
        return {
            promotionData:{},
            annuallyPromotions:{},
            monthlyPromotions:{},
            promotionCodes:{}
        }
    },
    computed:{
        currentPromotion(){
            return this.$root.undertaking === 'annually' ? this.annuallyPromotions : this.monthlyPromotions;
        },
        currentPromotionCode(){
            try{
                return this.promotionCodes[this.$root.undertaking];
            }catch (e) {
                return [];
            }
        },
        annuallyFlexPromotionPercent(){
            let percent = 0;
            try{
                if(this.annuallyPromotions.products.includes(this.$root.flexProduct.id)){
                    if(parseInt(this.annuallyPromotions.type) < 1){
                        percent = this.annuallyPromotions.value;
                    }
                    if(Object.prototype.hasOwnProperty.call(this.annuallyPromotions, 'customValue')){
                        if(typeof this.annuallyPromotions.customValue[this.$root.flexProduct.id] !== "undefined"){
                            percent = this.annuallyPromotions.customValue[this.$root.flexProduct.id];
                        }
                    }

                    if(Object.prototype.hasOwnProperty.call(this.annuallyPromotions, 'customValueType')){
                        if(typeof this.annuallyPromotions.customValueType[this.$root.flexProduct.id] !== "undefined"){
                            if(parseInt(this.annuallyPromotions.customValueType[this.$root.flexProduct.id]) > 0){ //type not percent
                                percent = 0;
                            }
                        }
                    }

                }
            }catch (e) {}
            return percent;
        }
    },
    methods:{
        setPromotionsProductPrice(productType, productID, total){
            let that = this;
            let calculate = false;
            let hasDiscount = false, returnTotal = total;
            let promotion = that.currentPromotion;
            let promotionValue = 0, promotionType = 0;

            if(typeof promotion === "undefined"){
                return {hasDiscount, 'total':returnTotal};
            }

            promotionValue = parseFloat(promotion.value);
            promotionType = parseInt(promotion.type);

            if(Object.prototype.hasOwnProperty.call(promotion, 'customValue')){
                if(typeof promotion.customValue[productID] !== "undefined"){
                    promotionValue = parseFloat(promotion.customValue[productID]);
                }
            }

            if(Object.prototype.hasOwnProperty.call(promotion, 'customValueType')){
                if(typeof promotion.customValueType[productID] !== "undefined"){
                    promotionType = parseInt(promotion.customValueType[productID]);
                }
            }

            if(productType === "monthly" && parseInt(promotion.isrecurring) === 1){
                calculate = true;
            }
            else{
                calculate = productType === "oneTime" && parseInt(promotion.isonetime) === 1;
            }

            try {
                if (!promotion.products.includes(productID)) {
                    calculate = false;
                }
            }catch (e) {
                calculate = false;
            }

            if(parseInt(promotion.dontapply_firstinvoice) > 0){
                calculate = false;
            }

            if(calculate && promotionValue > 0) {
                if (promotionType < 1) {
                    returnTotal = that.getPercentValue(total, promotionValue).minusValue;
                    hasDiscount = true;
                } else if (promotionType === 1) {
                    returnTotal = total - promotionValue;
                    hasDiscount = true;
                } else {
                    //setup promotion
                }
            }
            if(returnTotal < 0){
                returnTotal = 0;
            }
            return {hasDiscount, 'total': returnTotal, pValue: promotionValue, pType: promotionType, pCode: promotion.code};
        },
    },
    mounted() {
        let that = this;
        that.$root.appEvents.on('request_ended.onGetPromotionInfo', function (data) {
            if(Object.prototype.hasOwnProperty.call(data, 'codes')){
                that.promotionCodes = data.codes;
            }
            if(Object.prototype.hasOwnProperty.call(data, 'monthly')){
                that.monthlyPromotions = data.monthly;
            }
            if(Object.prototype.hasOwnProperty.call(data, 'annually')){
                that.annuallyPromotions = data.annually;
            }
        });
    }
}

export { Promotions }