<template>
  <div id="app">
    <div class="bottom-mask" @click="hideMask()" v-if="maskView"></div>
    <router-view/>
    <BottomBasket/>

    <my-dialog />
  </div>
</template>
<script>
  import MyDialog from "./plugins/dialog/MyDialog";
  import BottomBasket from "./components/BottomBasket";
  export default {
    name:'App',
    components:{BottomBasket, MyDialog},
    data(){
      return {
        maskView:false
      }
    },
    methods:{
      hideMask(){
          this.maskView = false;
          this.$root.appEvents.emit('funnel-bottombasket-hide');
      }
    },
    mounted() {
        const that = this;
        document.addEventListener("funnel-bottombasket-status", function (e) {
          that.maskView = e.detail.status === true
        });
    }
  }
</script>
<style lang="scss">
.bottom-mask{
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba(0,0,0,.3);
}
</style>
