const Dialog = {
    install(Vue, options){
        if (this.installed) {
            return
        }
        this.installed = true;
        this.params = options;
        //Vue.prototype.$eventBus = new Vue();


        Vue.prototype.$myAlert = function (params) {
            if (typeof params != 'object'){
                throw new Error(
                    `Options type must be an object. Caught: ${caughtType}. Expected: object`
                )
            }
            window.eventBus.$emit('myAlertView', params);
        };

        Vue.prototype.$myConfirm = function (params){
            let myResolve, myReject;
            return new Promise(function (resolve, reject) {
                params.resolve  = resolve;
                params.reject   = reject;
                window.eventBus.$emit('myConfirmView', params);
            });
        }
    }
};

export default Dialog;