<template>
    <div id="vast-op-mobiel-paketten">

        <div class="map-box mb-5 position-relative">
            <div class="map-box-header">
                <span class="theme-text-dark font-weight-bold font-size-18">Vast en mobiel nummer op simkaart <small>(contract 12 mnd)</small></span>
                <a class="theme-text-blue hand ml-2" data-toggle="modal" data-target="#wamo-modal"><i class="fa fa-info-circle"></i> </a>
            </div>
            <div class="height-380" v-if="!$root.readyVastOpMobielProducts">
              <loading :addCss="'abslt'" />
            </div>
            <MobileProductContainer :pType="'vamo'" v-else />
        </div>

        <div class="map-box mb-5 position-relative">
            <div class="map-box-header">
                <span class="theme-text-dark font-weight-bold font-size-18">Sim Only simkaart <small>(contract 12 mnd)</small></span>
                <a class="theme-text-blue hand ml-2" data-toggle="modal" data-target="#simonly-modal"><i class="fa fa-info-circle"></i> </a>
            </div>
            <div class="height-380" v-if="!$root.readyVastOpMobielProducts">
              <loading :addCss="'abslt'" />
            </div>
            <MobileProductContainer ref="sim_only" :pType="'onlySim'" v-else />
        </div>

        <!--
        <div class="map-box position-relative">
            <Loading :addCss="'abslt'" v-if="!$root.readyVastOpMobielProducts"/>
            <div class="map-box-main funnel-container py-5" v-else>
                <div class="row">
                    <div class="col-md-12">
                        <h2 class="mobile-product-title">Mobiele telefonie</h2>
                        <MobileProductContainer :pType="'onlySim'" />
                    </div>
                    <div class="col-md-12 mt-5">
                        <h2 class="mobile-product-title">VaMo (Vast mobiel integratie)</h2>
                        <MobileProductContainer :pType="'vamo'" />
                    </div>
                </div>
            </div>
        </div>
        -->

        <div class="map-box-external-footer mt-5 d-flex align-items-center flex-column flex-sm-row mb-5">
            <a @click="$root.gotoPreviousPage('vast_op_mobiel_paketten')" class="theme-text-blue" v-if="$root.hasprevious('vast_op_mobiel_paketten')"><i class="far fa-arrow-left"/> Vorige stap</a>
        </div>


        <div class="modal fade" id="wamo-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title theme-text-dark font-weight-bold p-0 m-0">Wat is Vast op Mobiel VaMo?</h5>
                        <a class="close" data-dismiss="modal" aria-label="Close">
                            <i class="fal fa-times theme-text-dark"></i>
                        </a>
                    </div>
                    <div class="modal-body">
                        Met Vast op Mobiel (VaMo) wordt de VOIPZeker simkaart van je mobiele telefoon geïntegreerd met je online telefooncentrale. Maak gebruik van alle functies van je vaste telefonie, zoals (intern) doorverbinden en een zakelijke voicemail. Met de VaMo simkaart ontvang je oproepen van je vaste nummer direct op je mobiele telefoon en kan je hiermee ook uitbellen.
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="simonly-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title theme-text-dark font-weight-bold p-0 m-0">Wat is Sim Only?</h5>
                        <a class="close" data-dismiss="modal" aria-label="Close">
                            <i class="fal fa-times theme-text-dark"></i>
                        </a>
                    </div>
                    <div class="modal-body">
                        Sim Only is een mobiele abonnement zonder telefoon. Kies uit verschillende Sim Only abonnementen met belminuten en data. Een Sim Only abonnement is nadrukkelijk geen Vast op Mobiel (VaMo) abonnement. De simkaart is niet geïntegreerd met je online VoIP telefooncentrale. Je kunt dus niet bellen en gebeld worden op je vaste nummer via je mobiele telefoon.
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import Loading from "./Loading";
    import MobileProductContainer from "./vast_op_mobiel_paketten/MobileProductContainer";
    export default {
        name: "Vast_op_mobiel_paketten",
        components: {Loading, MobileProductContainer},
        computed:{
            mobileIds(){
                return [
                    this.$root.productTypes.vamo.id,
                    this.$root.productTypes.onlySim.id
                ];
            },
            hasBasket(){
                let that = this;
                let has = false;
                ['vamo', 'onlySim'].forEach(function (id) {
                    if(typeof that.$root.basket[id] !== "undefined"){
                        has = true;
                    }
                });
                return has
            }
        },
        methods:{
            goForward(){
                if(this.hasBasket){
                    this.$root.gotoNextPage('vast_op_mobiel_paketten', 0);
                }else{
                    this.$root.gotoNextPage('vast_op_mobiel_paketten', 1);
                }
            },
            checkQuery(){
                let query = this.$route.query;
                let zoomRf = '';
                let el;
                if(query.hasOwnProperty("zoom")){
                    zoomRf = query.zoom.replace(/\-/g, '_');
                    el = this.$refs[zoomRf];
                    if(el){
                        if(el.$el.clientHeight){
                            window.scrollTo(0, (el.$el.clientHeight + 150));
                        }
                    }
                }
            }
        },
        mounted() {
            let that = this;
            this.$root.getVastOpMobielProducts(function () {

            });
            this.$root.appEvents.on('next'+this.$route.params.sub, this.goForward);
            setTimeout(function () {
                that.checkQuery();
            },2000);

        },
        destroyed() {
            this.$root.appEvents.removeListener('next'+this.$route.params.sub, this.goForward);
        }
    }
</script>

<style lang="scss">
    .mobile-product-title{
        font-weight: 800;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        margin-bottom: 15px;
    }
</style>