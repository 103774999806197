<template>
    <div id="ip_toestellen" v-if="readyPage">
        <div class="map-box mb-5 position-relative" v-for="(ids, k) in groups" :key="k">
            <div class="map-box-header">
                <span class="theme-text-dark font-weight-bold font-size-18">{{ groupName(k) }}</span>
            </div>
            <div class="map-box-main p-3 p-md-5">
                <div class="owl-carousel product-owl owl-theme" :id="'ovlcarousel-'+k"
                     data-owl-carousel
                     data-owl-dots="true"
                     data-owl-nav="true"
                     data-dot-class="owl-dot dark-dot"
                     data-owl-items="2"
                     data-owl-loop="false"
                     data-owl-autoplay="false"
                     data-owl-autoplay-timeout="2000"
                     data-owl-autoplay-speed="2000"
                     data-owl-margin="40"
                     data-rwd="1-1-2-3-3">
                    <HardwareProductItem v-for="productID in ids" :key="'hw_product'+productID" :productId="productID" />
                </div>
            </div>
        </div>

        <div class="map-box-external-footer mt-5 d-flex align-items-center flex-column flex-sm-row mb-5">
            <a @click="$root.gotoPreviousPage('vast_op_mobiel_paketten')" class="theme-text-blue" v-if="$root.hasprevious('vast_op_mobiel_paketten')"><i class="far fa-arrow-left"/> Vorige stap</a>
        </div>

    </div>
    <div class="product-loader" v-else>
        <Loading :addCss="'abslt absolute bgWhite'" />
    </div>
</template>

<script>
    import Loading from "./Loading";
    import HardwareProductItem from "./hardware/HardwareProductItem";
    export default {
        name: "Ip_toestellen",
        components:{Loading, HardwareProductItem},
        data(){
            return {
                readyPage:false
            }
        },
        computed:{
            groups(){
                return this.$root.productTypes.hardware.groups;
            },
        },
        methods:{
            groupName(groupKey){
                if(groupKey === 'Vaste_telefoon'){
                    return 'Vaste toestellen';
                }
                else if(groupKey === 'DECT_telefoon'){
                    return 'DECT basestation';
                }
                else if(groupKey === 'Complete_telefoonset'){
                    return 'DECT handset';
                }
                else if(groupKey === 'Headsets'){
                    return 'Headsets (Unite Phone)';
                }
                return 'Hardware';
            },
            goForward(){
                this.$root.gotoNextPage('ip_toestellen', 0);
            }
        },
        mounted() {
            let that = this;
            this.$root.getHardwareProducts(function () {
                let evt = new CustomEvent("reinit_owl_carousel", {});
                document.dispatchEvent(evt);
                that.readyPage = true;
            });
            this.$root.appEvents.on('next'+this.$route.params.sub, this.goForward);
        },
        destroyed() {
            this.$root.appEvents.removeListener('next'+this.$route.params.sub, this.goForward);
        }
    }
</script>

<style lang="scss">
    .owl-carousel{
        &.product-owl{
            position: relative;
            .owl-dots{
                margin-top: 15px;
                text-align: center;
                button{
                    span{
                        display: block;
                        width: 10px;
                        height: 10px;
                        background: #C4C4C4;
                        border-radius: 50%;
                        margin: 0 6px;
                    }
                    &.active{
                        span{
                            background: #102B44;
                        }
                    }
                }
            }
            .owl-nav{
                position: absolute;
                left: 0;
                top: calc(50% - 13px);
                width: 100%;
                height: 35px;
                z-index: -1;
                button{
                    width: 32px;
                    height: 32px;
                    color: #CFD4D9;
                    font-size: 26px;
                    position: absolute;
                    &.disabled{
                        display: none;
                    }
                    &:hover{
                        color: #777777;
                    }
                }
                button.owl-prev{
                    left: -40px;
                }
                button.owl-next{
                    right: -40px;
                }
            }
        }
    }/******/
    .product-loader{
        width: 100%;
        height: 380px;
        position: relative;
    }
</style>