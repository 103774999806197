<template>
    <div class="card-number-list">

        <!-- <BasketItemHeader :headerValues="{collapse, allCount:allCountProps(), goTo:'telefoonummers', icon:'fa-light fa-list-ol',  basketType:'newNumber', basket:listItems.newNumber, total:newNumberTotal, title:'Telefoonnummer'}" v-on:toggleCollapse="toggleCollapse" v-if="viewHeader"/>-->
      <div class="row mb-1" v-for="(opt,i) in itemOptions" :key="'phonenummer_item_'+i">
        <div class="col-6 col-sm-8 pr-0 mb-1 text-left">
          <span class="mr-1 mr-md-3">
            <i class="fal fa-times hand card-delete-btn" @click="removeProduct(i)" />
          </span>
          <span>{{ getPhoneNumber(opt, i) }}</span>
        </div>
        <div class="col-3 col-sm-2 pr-0 d-flex align-items-center justify-content-end"><span class="text-nowrap">{{ numberSetupPrice|price }}</span></div>
        <div class="col-3 col-sm-2 d-flex align-items-center justify-content-end"><span class="text-nowrap">{{ numberPrice|price }}</span></div>
      </div>
      <input id="delete-focus" type="hidden">
    </div>
</template>

<script>
    import { Abstract_wilkelmand} from "../../mixins/Abstract_wilkelmand";
    import BasketItemHeader from "./BasketItemHeader";
    export default {
        name: "NewNumberBasketList",
        mixins:[Abstract_wilkelmand],
        components:{ BasketItemHeader },
        props:{
          viewHeader:{
            type: Boolean,
            required:false,
            default:true
          }
        },
        computed:{
          numberPrice(){
            return this.$root.newNumberConf.price;
          },
          numberSetupPrice(){
            return this.$root.newNumberConf.setupPrice;
          },
          basketPhoneNumbers(){
            if(Object.prototype.hasOwnProperty.call(this.$root.basket, 'phoneNumbers')){
              return this.$root.basket['phoneNumbers'];
            }
          },
          itemOptions(){
            if(Object.prototype.hasOwnProperty.call(this.$root.basket, 'phoneNumbers')){
              return this.$root.basket['phoneNumbers'].options;
            }
            return {};
          }
        },
        methods:{
            removeProduct(key){
                let id  = 0
                try{
                  id = this.basketItem.options[key].id;
                }catch (e) {}
                this.$root.forNumberRequest(this.basketItem.options[key], 'remove', 'new');
                delete this.basketItem.options[key];
                if(Object.keys(this.basketItem.options) < 1){
                    delete this.$root.basket['phoneNumbers'];
                }


                this.$root.appEvents.emit('basket-re-calculate');
                this.$root.appEvents.emit('change_basket', this.basket);
                //this.$forceUpdate();
                this.calculateNewNumber(this.basketItem, true);
                //this.$root.appEvents.emit('remove-phone-number', id);
                document.getElementById("delete-focus").focus();
            },
            getPhoneNumber(opt, k){
                  if(opt.hasOwnProperty('phoneformat')){
                      return opt.phoneformat;
                  }
                  else if(opt.hasOwnProperty('number_start_full')){
                      this.$root.reEditPhoneNumber(k);
                      return opt.number_start_full.replace(/\+/g, ' ');
                  }
                  if(opt.hasOwnProperty('phone')){
                      return opt.areacode+""+opt.phone;
                  }else{
                      this.$root.reEditPhoneNumber(k);
                      return opt.areacode+""+opt.number_start;
                  }
            },
            lastCookieChanged(manuel){
                if(manuel !== true){
                    this.calculateNewNumber(this.basketItem, true);
                }
            },
            allCountProps(){
                try{
                    return Object.keys(this.basketItem.options).length;
                }catch (e) {
                    return '';
                }
            }
        },
        mounted() {
            if(this.viewHeader === false){
              this.collapse = true;
            }
            this.calculateNewNumber(this.basketItem);
            this.$root.appEvents.on('newNumber-re-calculate', this.lastCookieChanged);
        },
        destroyed() {
            this.$root.appEvents.removeListener('newNumber-re-calculate', this.lastCookieChanged);
        }
    }
</script>

<style scoped>

</style>