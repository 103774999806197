<template>
    <div class="line-box mb-3" :class="itemContainerCss">
        <div class="line-box-header number-form-header d-flex align-items-center">
            <div class="added-number d-flex align-items-center mr-3" :class="{'theme-text-green':isCheckedInfo, 'text-danger':hasParentError}">
                <i class="fal fa-check theme-text-green card-delete-btn"/>
            </div>
            <span class="font-size-15 font-weight-bold">0{{ item.formatNumber }}</span>
            <div class="number-action ml-auto">
                <!--<i class="fas fa-trash-alt theme-text-blue hand" @click="deleteItem()"/>-->
                <i class="fal fa-times hand card-delete-btn" @click="deleteItem()"/>
            </div>
            <!--
            <div class="number-owner-data d-flex align-items-center mr-2 mr-md-5">
                <span class="d-none d-sm-block mr-2 mr-md-0">Nummerbehoud gegevens</span>
            </div>
            -->
        </div>
        <!--
        <div class="map-box-main number-owner-form py-4" :class="{'display-none':!item.tabs}">
            <p class="font-size-14 d-flex">
              <span class="text-danger font-weight-bold mr-1">*</span>
              <span>Vul hier je nummerbehoud gegevens in</span>
            </p>

            <div class="row">
                <div class="col-md-5 pr-md-0">
                    <div class="form-group">
                        <label>Bedrijfsnaam <span class="text-danger">*</span></label>
                        <input type="text" class="form-control" v-model="item.companyName">
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group post-code-area">
                        <label>Postcode <span class="text-danger">*</span></label>
                        <input type="text" maxlength="6" pattern="[a-zA-Z0-9]" class="form-control" :class="{'is-invalid':hasError, 'is-valid':item.isValidPostCode}" v-on:blur="checkPostCode()" v-on:keyup="clearPostCode()" v-model="item.postCode">
                        <i class="fa fa-pulse fa-spinner" v-if="startRequest"/>
                    </div>
                </div>
                <div class="col-md-4 pl-md-0">
                    <div class="row">
                        <div class="col-6 pr-md-0">
                            <div class="form-group">
                                <label class="text-nowrap">Huisnummer <span class="text-danger">*</span></label>
                                <input type="number" class="form-control" v-on:blur="checkPostCode()" v-model="item.homeNumber">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label>Toevoeging</label>
                                <input type="text" maxlength="5" class="form-control" v-model="item.additionalNo" v-on:keyup="clearAdditionalNo()">
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="nummer-adress-text" v-if="isCheckedInfo">
                <i class="fa fa-check theme-text-green mr-2"/>
                <span><strong class="mandatory">Adres:</strong> {{ item.streetName }} {{ item.homeNumber }} {{ item.additionalNo }} - {{ item.city }} - {{ item.region }}</span>
            </div>


            <div class="nummer-adress-text" v-if="showFormText">
                <i class="fa fa-times text-danger mr-2"/>
                <span class="text-danger" v-if="item.isValidPostCode">Gelieve de verplichte velden in te vullen (<b>*</b>)</span>
                <span class="text-danger" v-else>De postcode-huisnummer combinatie is niet bekend voer hieronder de gegevens in.</span>
            </div>

            <div class="row" v-if="showForm">
                <div class="col-md-5 pr-md-0">
                    <div class="form-group">
                        <label>Straatnaam <span class="text-danger">*</span></label>
                        <input type="text" class="form-control" v-model="item.streetName">
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label>Stad <span class="text-danger">*</span></label>
                        <input type="text" class="form-control" v-model="item.city">
                    </div>
                </div>
                <div class="col-md-4 pl-md-0">
                    <div class="form-group">
                        <label>Regio</label>
                        <input type="text" class="form-control" v-model="item.region">
                    </div>
                </div>
            </div>


        </div>
        -->
    </div>
</template>

<script>
    export default {
        name: "TransferNumberItem",
        props:{
            numberIndex:{
                type:[Number, String],
                require: true
            },
            item:{
                type: Object,
                require: true
            }
        },
        data(){
            return {
                startRequest:false,
                sendRequest:false,
                hasParentError:false,
            }
        },
        computed:{
            isCheckedInfo(){
                let that = this;
                let status = true;
                try {
                    ["companyName", "postCode", "homeNumber", "streetName", "city"].forEach(function (key) {
                        if (that.item[key]+"".length < 1 || typeof that.item[key] === "undefined") {
                            status = false;
                        }
                    });
                }catch (e) {
                    status = false;
                }
                return status;
            },
            showForm(){
                if(this.item.isValidPostCode === false){
                    return true;
                }
                return this.sendRequest === true && this.isCheckedInfo !== true && this.item.isValidPostCode === false;
            },
            showFormText(){
                return this.sendRequest === true && this.isCheckedInfo !== true;
            },
            hasError(){
                return this.showFormText && this.item.isValidPostCode !== true;
            },
            itemContainerCss(){
                let cssObj = {};
                if(this.item.tabs){
                    cssObj.active = true;
                }else{
                    cssObj.active = false;
                }
                if(this.hasParentError){
                    cssObj.animate__flash = true;
                    cssObj.animate__animated = true;
                }
                else{
                    cssObj.animate__flash = false;
                    cssObj.animate__animated = false;
                }
                return cssObj;
            }
        },
        watch:{
          isCheckedInfo(newValue){
            if(newValue === true){
              this.$emit("applyInfoToOhters", this.numberIndex);
            }
          }
        },
        methods:{
            accordionToggle(){
                this.item.tabs = !this.item.tabs;
            },
            checkPostCode(){
                let that = this;
                if(that.item.postCode.length > 0){
                    that.item.postCode      = that.item.postCode.toUpperCase();
                }
                else{
                    that.item.isValidPostCode = false;
                }
                if(that.item.homeNumber.length > 5){
                    that.item.homeNumber    = that.item.homeNumber.slice(0,5);
                }

                if(that.item.postCode.length > 0 && that.item.homeNumber.length > 0){
                    that.startRequest = true;
                    that.item.isValidPostCode = false;
                    that.sendRequest = false;
                    this.$root.getPostCodeInfo(that.item.postCode, function (receiveObject) {
                        try {
                            that.item.streetName    = receiveObject.tapi_streetname;
                            that.item.city          = receiveObject.tapi_cityname;
                            that.item.region        = receiveObject.tapi_provincename;
                            if(receiveObject.tapi_result === "success"){
                                that.item.isValidPostCode = true;
                            }else{
                                that.item.isValidPostCode = false;
                            }
                        }catch (err) {
                            that.item.streetName    = '';
                            that.item.city          = '';
                            that.item.region        = '';
                            that.item.isValidPostCode = false;
                            that.$root.errorLog(err);
                        }
                        that.sendRequest    = true;
                        that.startRequest   = false;
                    });
                }
            },
            clearPostCode(){
                this.item.postCode = this.item.postCode.replace(/\W/g, '').toUpperCase();
            },
            clearAdditionalNo(){
                this.item.additionalNo = this.item.additionalNo.replace(/\W/g, '').toUpperCase();
            },
            deleteItem(){
                this.$emit("deleteItem", this.numberIndex);
            },
            setError(){
                let that = this;
                this.item.tabs = true;
                this.hasParentError = true;

                setTimeout(function () {
                    that.hasParentError = false;
                },5000);
            }
        }
    }
</script>

<style lang="scss">
.line-box{
  background-color: #FFFFFF;
  border-bottom: 1px solid #EAECEF;
  padding: 17px 20px;
  border-radius: 4px;
}
    .number-form-header{
        .number-owner-data{
            .fa-info-circle{
                font-size: 12px !important;
            }
        }
    }
    .number-owner-form{
        .form-group{
            label{
                color: #187DC1;
                font-size: 15px;
                font-weight: bold;
            }
            &.post-code-area{
                position: relative;
                .fa-spinner{
                    position: absolute;
                    top: calc(50% + 7px);
                    right: 7px;
                }
            }
        }
    }
</style>