<template>
    <div id="vamo" class="funnel-container">
        <div class="map-box">
            <div class="map-box-header p-4 p-sm-5 d-flex align-items-center">
                <i class="far fa-sim-card fa-2x theme-text-blue mr-4"/>
                <!--<span class="theme-text-dark font-weight-bold font-size-18">Wil je ook een Vast op Mobiel integratie?</span>-->
                <span class="theme-text-dark font-weight-bold font-size-18">Wil je een simkaart met een vast of mobiel nummer?</span>
            </div>
            <div class="map-box-main font-weight-bold px-md-5">
                <div class="way-item d-flex align-items-center flex-column flex-sm-row">
                    <div class="d-flex align-items-center flex-nowrap text-center text-sm-left">
                        <span>Ja, ik wil een Vast op Mobiel integratie</span>
                    </div>
                    <div class="clearfix d-block d-sm-none"></div>
                    <button class="btn btn-vzkr btn-green ml-sm-auto mt-4 mt-sm-0" @click="$root.gotoNextPage('vamo', 0)">Kiezen</button>
                </div>
                <div class="way-item d-flex align-items-center flex-column flex-sm-row">
                    <div class="d-flex align-items-center flex-nowrap text-center text-sm-left">
                        <span>Nee, ik heb geen interesse </span>
                    </div>
                    <div class="clearfix d-block d-sm-none"></div>
                    <button class="btn btn-vzkr btn-orange ml-sm-auto mt-4 mt-sm-0" @click="$root.gotoNextPage('vamo', 1)">Overslaan</button>
                </div>
            </div>
        </div>

        <div class="map-box-external-footer mt-5 d-flex align-items-center flex-column flex-sm-row">
            <a @click="$root.gotoPreviousPage('vamo')" class="theme-text-blue"><i class="far fa-arrow-left"/> Terug</a>
            <!-- <button class="btn btn-vzkr btn-green ml-sm-auto mt-4 mt-sm-0" @click="$root.gotoNextPage('hardware', 2)">Overslaan <i class="far fa-arrow-right"/></button> -->
        </div>

    </div>
</template>

<script>
    export default {
        name: "Vamo",
        methods:{
            goForward(){
                this.$root.gotoNextPage('vamo', 0);
            }
        },
        mounted() {
            this.$root.appEvents.on('next'+this.$route.params.sub, this.goForward);
        },
        destroyed() {
            this.$root.appEvents.removeListener('next'+this.$route.params.sub, this.goForward);
        }
    }
</script>

<style scoped>

</style>