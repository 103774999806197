<template>
    <div id="nieuw_nummer" class="funnel-container">
        <div class="map-box2">
            <div class="map-box-header2 pt-4 pb-3 px-5 d-flex align-items-start">
              <span class="badge badge-primary mr-3 mt-1 py-1 px-2">2</span>
              <div class="d-flex align-items-center flex-wrap">
                <strong class="mandatory text-nowrap mr-1">Nummerbehoud - </strong>
                <span>Nummer meeverhuizen <span class="text-nowrap">{{ numberPrice|price }}</span> p.m.</span>
              </div>
            </div>

            <div class="map-box-main px-5">
                <!--
                <p class="theme-text-dark font-weight-bold font-size-15 mb-2">Selecteer je regio </p>
                <div class="row py-2 mb-4">
                    <div class="col-md-6">
                        <div class="vue-select">

                            <vSelect :options="araCodeOpt" v-model="selectedAreCode" :reduce="label => label.code">
                                <template v-slot:no-options="{ search, searching }">
                                    <template v-if="searching">
                                        Helaas, deze regio is niet gevonden
                                    </template>
                                    <em style="opacity: 0.5;" v-else>Start typing to search for a country.</em>
                                </template>
                            </vSelect>
                        </div>
                    </div>
                </div>
                -->


                <div class="row mb-2">
                    <div class="col-md-12"><span class="theme-text-dark">Vul je nummer in</span></div>
                </div>
                <div class="row mb-4">
                    <div class="col-sm-8 col-md-6">
                        <div class="input-text" :class="{error:(numberError.length > 1)}">
                            <div class="transfer-input d-flex align-items-center pl-3">
                                <!--<div class="area-code d-flex align-items-center flex-nowrap"><span>0{{ selectedAreCode }}</span> <span class="ml-2">-</span> </div>-->
                                <input type="number" id="transfer-number-no" placeholder="0858008000" v-model="textNumber" v-on:keyup.enter="addNumber()" v-on:keyup="clearTransferNumber()">
                            </div>
                            <div class="code-error" v-if="numberError"><i class="fas fa-exclamation-triangle"/></div>
                        </div>
                        <div class="col-12"><p class="text-danger font-weight-bold font-size-14 p-0 pt-1 m-0">{{ numberError }}</p></div>
                    </div>
                    <div class="col-sm-4 col-md-5 pl-md-0">
                        <button class="btn btn-vzkr btn-green float-right float-md-left" :class="buttonCss" @click="addNumber()">
                            <span v-if="!requestNumber">Toevoegen <i class="fal fa-plus ml-2"/></span>
                            <span v-else><i class="fa fa-spinner fa-pulse"/></span>
                        </button>
                    </div>
                </div>

                <div class="transfer-number-list">
                    <TransferNumberItem v-for="(item, k) in numberList" :key="item.number" :ref="'t'+item.number" :numberIndex="k" :item="item" v-on:applyInfoToOhters="applyInfoToOhters" v-on:deleteItem="deleteItem"/>
                </div>

            </div>

        </div>
        <!--
        <div class="map-box-external-footer mt-5 d-flex align-items-center flex-column flex-sm-row mb-5">
            <a @click="changePagesBefore( 1)" class="theme-text-blue"><i class="far fa-plus"/> Nieuw nummer uitkiezen</a>
        </div>
        -->
    </div>
</template>

<script>
    import TransferNumberItem from "./nummerbehound/TransferNumberItem";
    import vSelect from 'vue-select'
    export default {
        name: "Nummerbehound",
        components:{TransferNumberItem, vSelect},
        data(){
            return {
                selectedAreCode:'85',
                textNumber:'',
                numberError:'',
                numberList:[],
                errorTimer:null,
                requestNumber:false,
                buttonCss:{
                    animate__animated:false,
                    animate__shakeX:false
                }
            }
        },
        computed:{
            areaCodes:{
                set:function (val) {
                    this.$root.areaCodes = val;
                },
                get:function () {
                    return this.$root.areaCodes;
                }
            },
            araCodeOpt(){
                let that = this;
                let opt = [];
                Object.values(this.areaCodes).forEach(function (val) {
                    if(that.isActiveAreCode(val)) {
                        opt.push({
                            label: '0' + val.areacode + ' ' + val.name,
                            code: val.areacode
                        });
                    }
                });
                return opt;
            },
            formattedNumber(){
                let that = this;
                let tmp = that.textNumber.trim();
                let number = that.selectedAreCode+that.textNumber.trim();
                let asYouType = new libphonenumber.AsYouType();
                asYouType.input("+31"+number)
                try{
                    tmp = asYouType.populatedNationalNumberTemplate.substr(that.selectedAreCode.length).replace(/x/g,'');
                    asYouType = null;
                    return tmp;
                }catch (e) {
                    asYouType = null;
                    //that.$root.errorLog(e);
                    return that.textNumber.trim();
                }
            },
            numberPrice(){
              return this.$root.transferNumbersConf.price;
            },
        },
        watch:{
            numberList:{
                handler: function(newVal, oldVal){
                    this.optSynchronous();
                },
                deep:true
            },
            buttonCss:function () {
                let that = this;
                if(this.errorTimer !== null){
                    clearTimeout(this.errorTimer);
                }
                this.errorTimer = setTimeout(function () {
                    that.buttonCss = {
                        animate__animated:false,
                        animate__shakeX:false
                    };
                    clearTimeout(this.errorTimer);
                    this.errorTimer = null;
                },5000);
            }
        },
        methods:{
            isActiveAreCode(opt){
                if(opt.transfer !== true){
                    return false;
                }
                return true;
            },
            addNumber(){
                let that = this;
                //let number = this.selectedAreCode+this.textNumber.trim();
                let number = this.textNumber.trim();
                let oldNumber = ""+this.textNumber.trim();
                //this.textNumber = "";
                let numberInfo = that.$root.numberInfo(number);

                if (number.isValid === false || numberInfo.areaCode === '' || numberInfo.number.substring(numberInfo.number.length - 1) === "x") {
                    this.numberError = '* Dit nummer kan niet geporteerd worden';
                    this.buttonCss = {
                        animate__animated:true,
                        animate__shakeX:true
                    };
                }
                else{
                    that.requestNumber = true;
                    that.numberError = '';
                    that.selectedAreCode = numberInfo.areaCode;

                    if(numberInfo.areaCode === "6"){
                      if(typeof that.$root.basket.onlySim === "undefined" && typeof that.$root.basket.vamo === "undefined"){
                        this.numberError = '* Dit mobiele nummer kan niet geporteerd worden.';
                        that.requestNumber = false;
                        return;
                      }
                    }


                    if(!this.hasNumber(numberInfo.number, numberInfo.areaCode)) {
                        this.forNumberRequest({
                            phoneformat:numberInfo.numberTemplate,
                            phone: numberInfo.number,
                            areacode:numberInfo.areaCode
                        }).then(function (http) {
                            let res = http.giveAsObject();
                            that.requestNumber = false;

                            if(parseInt(res.code) === 200){
                                let newNumber = {
                                  areacode: numberInfo.areaCode,
                                  number: numberInfo.number,
                                  phone: numberInfo.number,
                                  //phone: this.textNumber.trim(),
                                  formatNumber: numberInfo.numberTemplate,
                                  companyName:'',
                                  postCode:'',
                                  homeNumber:'',
                                  additionalNo:'',
                                  streetName:'',
                                  city:'',
                                  region:'',
                                  isValidPostCode:false,
                                  isValidHomeNumber:false,
                                  tabs:false
                                }
                                if(typeof that.numberList[0] !== "undefined"){
                                  newNumber = Object.assign(newNumber, that.getNumberCommonDataByNumber(0));
                                }
                                that.numberList.push(newNumber);
                                that.textNumber = '';
                            }else{
                                // that.$myAlert({
                                //     title:'',
                                //     text:res.message
                                // });
                                that.textNumber = oldNumber;
                                that.numberError = 'Dit nummer kan niet geporteerd worden.';
                                that.buttonCss = {
                                    animate__animated:true,
                                    animate__shakeX:true
                                };
                            }
                        });
                    }else{
                      that.requestNumber = false;
                      this.numberError = 'Dit nummer is al toegevoegd.';
                      that.requestNumber = false;
                    }
                }
            },

            deleteItem(numberIndex){
                let number;
                if(typeof this.numberList[numberIndex] === "undefined"){
                    return;
                }
                number = this.numberList[numberIndex];

                this.forNumberRequest({
                    phoneformat:number.formatNumber,
                    phone: number.number,
                    areacode:number.areacode
                },'remove');

                this.numberList = this.numberList.filter(function (item) {
                    return item.number !== number.number;
                });
            },

            getNumberCommonDataByNumber(numberIndex){
              let setInfo = Object.assign({},this.numberList[numberIndex]);
              delete setInfo.number;
              delete setInfo.formatNumber;
              delete setInfo.phone;
              delete setInfo.areacode;
              delete setInfo.tabs;
              return setInfo;
            },

            applyInfoToOhters(numberIndex){

              return;
              /*
              let that = this;
              let setInfo = that.getNumberCommonDataByNumber(numberIndex);

              if(this.numberList.length <= 1){
                return;
              }

              this.numberList.forEach((item, index)=>{
                if(index !== numberIndex){
                    let status = true;
                    ["companyName", "postCode", "homeNumber", "streetName", "city"].forEach(function (key) {
                      if (item[key]+"".length < 1 || typeof item[key] === "undefined") {
                        status = false;
                      }
                    });
                    if(status !== true){
                      that.numberList[index] = Object.assign(that.numberList[index], setInfo);
                    }
                }
              });
              */
            },
            hasNumber(number, areaCode=null){
                let has;
                let aCode;
                if(areaCode === null){
                    aCode = this.selectedAreCode;
                }else{
                    aCode = areaCode;
                }
                has = this.numberList.filter(function (num) {
                    return num.number === number && num.areacode === aCode;
                });
                return has.length > 0;
            },

          /**
           * area codes many
           */
            getAreaCode(){
                let that = this;
                if(Object.keys(this.$root.areaCodes) < 1) {
                    that.$root.getAreaCode();
                }
            },

            clearTransferNumber(){
                this.textNumber = this.textNumber.replace(/\W/g, '').toUpperCase();
                if(this.textNumber.length > 11){
                    this.textNumber = this.textNumber.slice(0,11);
                }
            },

            optSynchronous(){
                let basketNumberList;
                if(!this.$root.basket.hasOwnProperty('transferNumbers')){
                    this.$root.basket.transferNumbers = {};
                }

                basketNumberList = {
                    formType:'transferNumber',
                    options:this.numberList
                };

                this.$root.basket.transferNumbers = Object.assign(
                    this.$root.basket.transferNumbers,
                    basketNumberList
                );
                this.$root.appEvents.emit('basket-re-calculate');
                this.$root.appEvents.emit('change_basket', this.basket);
            },

            forNumberRequest(k, process='add'){
                let that = this;
                return this.$root.sendRequest({
                    handler:'onNumberAction',
                    process,
                    number_start:k.phone,
                    number_end:'',
                    number_start_full:k.phoneformat,
                    type:'transfer',
                    areacode:k.areacode
                },function () {

                });
            },

            changePagesBefore(nextIndex = 0){
                let changePage = true;
                let errorItem;
                if(this.numberList.length > 0){
                    for(let x = 0; x < this.numberList.length; x++){
                        let item = this.numberList[x];
                        ["streetName", "city", "companyName", "homeNumber", "postCode"].forEach(function (key) {
                            if (item[key]+"".length < 1 || typeof item[key] === "undefined") {
                                changePage = false;
                                errorItem = item;
                                return;
                            }
                        });
                    }
                    if(changePage){
                        this.$root.gotoNextPage('nummerbehound', nextIndex);
                    }
                    else{
                        this.$myAlert({
                            title:'Nummerhouder',
                            text:'Voer de ontbrekende nummerhouder gegevens in.'
                        });
                        let refName = 't'+errorItem.number;
                        this.$refs[refName][0].setError();
                        //console.log(this.$refs);
                    }
                }
                else{
                    this.$root.gotoNextPage('nummerbehound', nextIndex);
                }
            },

            goForward(){
                this.changePagesBefore( 0);
            },

            refreshPageLoadData(){
                let that = this;
                if(this.$root.basket.hasOwnProperty('transferNumbers')){
                    if(this.$root.basket.transferNumbers.options.length > 0){
                        this.$root.basket.transferNumbers.options.forEach(function (item) {
                            if(!that.hasNumber(item.number)){
                                that.numberList.push(item);
                            }
                        });
                    }
                }
            }
        },

        mounted() {
            let that = this;
            if(Object.keys(this.areaCodes).length < 1){
                this.getAreaCode();
            }

            if(this.$root.basket.hasOwnProperty("transferNumbers")){
                if(this.$root.basket.transferNumbers.options.length > 0){
                    this.numberList = this.$root.basket.transferNumbers.options
                }
            }
            this.$root.appEvents.on('cookie-product-loaded',this.refreshPageLoadData);
            this.$root.appEvents.on('next'+this.$route.params.sub, this.goForward);

            this.$root.appEvents.on("cookie-product-loaded", function () {
                  if(that.$route.params.sub === "nummerbehound"){
                    that.$root.gotoPage('telefoonummers', {hash:'#nummerbehound'});
                  }
            });
            this.$root.appEvents.emit("page.step", {step:2});
        },
        destroyed() {
            this.$root.appEvents.removeListener('cookie-product-loaded',this.refreshPageLoadData);
            this.$root.appEvents.removeListener('next'+this.$route.params.sub, this.goForward);
        }

    }
</script>

<style lang="scss">
    #nieuw_nummer{
        .map-box {
            overflow: unset;
        }
    }
    .input-text{
        background: #FFFFFF;
        border: 1px solid #E5E5E5;
        box-sizing: border-box;
        border-radius: 4px;
        width: 100%;
        height: 46px;
        position: relative;
        .transfer-input{
            width: inherit;
            height: inherit;
            position: relative;
            z-index: 1;
            font-weight: bold;
            .area-code{
               padding: 0 5px 0 15px;
            }
            input{
                width: 100%;
                height: 41px;
                border: none;
                padding-bottom: 3px;
                //font-weight: bold;
                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
                &:active, &:focus{
                    outline: none;
                    box-shadow: none;
                }
            }
        }
        .code-error{
            color: #CA444A;
            position: absolute;
            right: 15px;
            top: 10px;
            z-index: 10;
        }
        &.error{
            .transfer-input{
                input{
                    color: #CA444A;
                }
            }
        }
    }
</style>