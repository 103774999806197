<template>
    <div class="height-380 position-relative" v-if="viewLoading">
        <loading :addCss="'abslt'" v-if="viewLoading" />
    </div>
    <div class="funnel-container" v-else-if="readyPackage">
        <div class="map-box position-relative mb-0">
            <div class="map-box-main p-3 p-md-5">

                <div class="d-flex align-items-start mb-4">
                    <span class="badge badge-primary mr-3 mt-1 py-1 px-2">1</span>
                    <p class="m-0 p-0">
                        <b class="mr-1">Looptijd</b>
                    </p>
                </div>

                <div class="number-tab d-flex align-items-center flex-column flex-md-row">

                    <div class="selec-transfer tab-selector w-100 mr-md-2 d-flex py-3 align-items-center" :class="{selected:$root.undertaking==='monthly'}" @click="selectUndertakingProcess('monthly')">
                      <i class="fa-circle mr-2" :class="{far:$root.undertaking!=='monthly', fas:$root.undertaking==='monthly'}"></i>
                      <span>Maandelijks opzegbaar</span>
                    </div>

                    <div class="selec-new tab-selector w-100 ml-md-2 mb-3 mb-md-0 d-flex py-3 align-items-center" :class="{selected:$root.undertaking==='annually'}" @click="selectUndertakingProcess('annually')">
                        <i class="fa-circle mr-2" :class="{far:$root.undertaking!=='annually', fas:$root.undertaking==='annually'}"></i>
                        <div class="d-flex align-items-center">
                          <span class="mr-1">12</span>
                          <span class="d-block d-sm-none">mnd.</span>
                          <span class="d-none d-sm-block">maanden</span>
                          <span class="badge badge-arrow-left ml-4" v-if="$root.annuallyFlexPromotionPercent > 0">Bespaar {{ $root.annuallyFlexPromotionPercent|rawPrice }}%</span>
                        </div>
                    </div>



                </div>

                <hr>

                <div class="d-flex align-items-start mb-4">
                    <span class="badge badge-primary mr-3 mt-1 py-1 px-2">2</span>
                    <p class="m-0 p-0">
                        <b class="mr-1">Gebruikers -</b>Kies het aantal gebruikers die gaan bellen.<br>
                        <span v-if="gebruikerCount < 6">
                          <small>Vanaf 6 gebruikers, per gebruiker <span class="text-nowrap">{{ flex6DiscountPrice|price }}</span></small>
                        </span>
                    </p>
                </div>


                <div class="d-flex justify-content-sm-center justify-content-md-start flex-column align-items-center flex-md-row">
                    <div class="d-md-block d-none mr-5"></div>
                    <span class="mr-3 mr-md-5 mb-4 mb-md-0 text-nowrap"><i class="fa fa-user theme-text-blue mr-2 mr-md-3"></i> <b>Aantal gebruikers</b></span>

                    <number-counter :product="{gebruikers:0, id:999}" pType="gebruikers" :basketCount="gebruikerCount" v-on:changeCount="changeCount" />

                    <!--
                    <span class="ml-md-3 my-2 my-md-0 text-nowrap" v-if="gebruikerCount < 6"><small>{{ flexPrice|price }}</small></span>
                    <span class="ml-md-3 my-2 my-md-0 text-nowrap" v-if="gebruikerCount >= 6"><small>{{ flex6Price|price }}</small></span>
                    <span class="font-weight-bold ml-3 badge text-white badge-arrow-right" style="background-color: #82b636;">1 maand gratis</span>
                    -->

                  <div class="hw-price-list">
                    <div class="" v-if="hasDiscount">
                      <div class="bottom-price d-flex align-items-center justify-content-center">

                        <span class="old-price ml-md-3 my-2 my-md-0 text-nowrap" v-if="gebruikerCount < 6"><small>{{ flexPrice|price }} *</small></span>
                        <span class="old-price ml-md-3 my-2 my-md-0 text-nowrap" v-if="gebruikerCount >= 6"><small>{{ flex6Price|price }} *</small></span>

                        <span class="new-price ml-3 my-2 my-md-0 text-nowrap font-weight-bold" style="color: #187DC1;" v-if="gebruikerCount < 6">{{ flexDiscountPrice|price }} *</span>
                        <span class="new-price ml-3 my-2 my-md-0 text-nowrap font-weight-bold" style="color: #187DC1;" v-if="gebruikerCount >= 6">{{ flex6DiscountPrice|price }} *</span>

                      </div>
                    </div>
                    <div class="bottom-price d-flex align-items-center justify-content-center" v-else>

                      <span class="ml-md-3 my-2 my-md-0 text-nowrap font-weight-bold" style="color: #187DC1;" v-if="gebruikerCount < 6">{{ flexPrice|price }} *</span>
                      <span class="ml-md-3 my-2 my-md-0 text-nowrap font-weight-bold" style="color: #187DC1;" v-if="gebruikerCount >= 6">{{ flex6Price|price }} *</span>

                    </div>
                  </div>

                </div>

                <div id="has-gebruiker-action" v-if="gebruikerCount > 0">
                    <hr>

                    <div class="d-flex align-items-start mb-4">
                        <span class="badge badge-primary mr-3 mt-1 py-1 px-2">3</span>
                        <div class="">
                            <p class="m-0 p-0">
                                <b class="mr-1">Inbegrepen apparaten per gebruiker -</b> Onbeperkt bellen via vast, mobiel en desktop.
                            </p>
                        </div>
                    </div>

                    <div class="gebruiker-types mb-5" v-if="!customizeUsers">
                        <gebruiker-type-select gebrukerKey="all" :gebruiker="gebrukersData[0]" :gebruikerCount="gebruikerCount" v-on:selectedExType="selectedProductType" />
                    </div>
                  <!--
                  <div class="gebruiker-types mb-5" v-if="customizeUsers">

                      <div class="extension-accordion" v-for="(item, index) in gebrukersData">
                          <div class="e-accordion-header parent d-flex align-items-center flex-nowrap" :class="{opened:groupAccordionKey===index}" @click="setOpnedDiv(index)">
                              <span class="ml-2 font-weight-bold"><i class="fa fa-user theme-text-blue mr-2 mr-md-3"></i> Gebruiker  {{ index + 1}}</span>
                              <div class="ml-3 ml-md-5">
                                  <small class="d-flex flex-column flex-sm-row">
                                      <span>Gekozen : </span>
                                      <span v-html="selectedItemIcons(item)"></span>
                                  </small>
                              </div>
                              <i class="fas fa-chevron-right hand ml-auto" :class="{'fa-chevron-right':groupAccordionKey !==index, 'fa-chevron-down':groupAccordionKey === index}"></i>
                          </div>

                          <div class="e-accordion-body" :class="{opened:groupAccordionKey===index, 'empty-caution':groupAccordionErrorKey===index}">
                              <gebruiker-type-select :gebrukerKey="index" :gebruiker="item" v-on:selectedExType="selectedProductType" />
                          </div>

                      </div>

                  </div>
                  -->
                    <!--
                    <div class="d-flex align-items-start" v-if="gebruikerCount > 1">
                        <div class="vue-checkbox mr-2 mt-1">
                            <input type="checkbox" id="customize-users" v-model="customizeUsers">
                            <div class="checkbox-tick"></div>
                        </div>
                        <label for="customize-users">
                            <p class="m-0 p-0">
                                Per gebruiker het aantal apparaten selecteren
                            </p>
                            <p class="m-0 p-0 mt-1 font-size-12">Je kunt meerdere apparaten aan 1 gebruiker koppelen. Stel deze hier naar wens samen. </p>
                        </label>
                    </div>
                    -->

                </div>

            </div>
        </div>
      <p class="mt-3 p-0">*Inclusief onbeperkt bellen (<a href="https://voipzeker.nl/wp-content/uploads/2022/02/Hfd2.1_D1_Fair_use_policy.pdf" class="hover-under" target="_blank">fair use policy</a>)</p>
    </div>
</template>

<script>
import NumberCounter from "@/components/tools/NumberCounter";
import GebruikerTypeSelect from "@/components/gebruikers/GebruikerTypeSelect";
import Loading from "./Loading";
export default {
    name: "Gebruikers",
    components:{Loading, NumberCounter, GebruikerTypeSelect},
    data(){
        return {
            viewLoading:true,
            customizeUsers:false,
            gebrukersData:[],
            emptyModel:{desktop:true, mobiel:true, vast:true},
            groupAccordionKey:'',
            groupAccordionErrorKey:'',
            subProduct:{},
            vastProduct:{},
        }
    },
    watch:{
        gebruikerCount: function (newVal, oldVal) {
            if(newVal < 2){
                this.customizeUsers = false;
            }
        },
        customizeUsers:function (newVal){
            if(typeof this.$root.basket[this.$root.flexProduct.id] === "object"){
                this.$root.basket[this.$root.flexProduct.id].customize = newVal;
            }
        }
    },
    computed:{
        gebruikerCount(){
            return this.gebrukersData.length;
        },
        flexPrice(){
            return this.$root.productPrice(this.$root.flexProduct.id, 1);
        },

        flexDiscountPrice(){
            return this.$root.setPromotionsProductPrice("monthly", this.$root.flexProduct.id, this.flexPrice).total;
        },

        /**
         * 6 ve üzeri bir kullanıcı seçiminde her kullanıcı için olan fiyat
         * @returns {number|*}
         */
        flex6Price(){
            let manyPrice = this.$root.productPrice(this.$root.flexProduct.id, 6);
            if(manyPrice > this.flexPrice){
                return manyPrice / 6;
            }
            return this.flexPrice;
        },

        flex6DiscountPrice(){
            return this.$root.setPromotionsProductPrice("monthly", this.$root.flexProduct.id, this.flex6Price).total;
        },

        subProductPrice(){
            return this.$root.productPrice(this.subProduct.id, 1, this.$root.flexProduct.relation);
        },

        hasDiscount(){
            return this.$root.setPromotionsProductPrice("monthly", this.$root.flexProduct.id, this.flexPrice).hasDiscount;
        },
        readyPackage(){
            return this.$root.loadedPackage === true;
        },
    },
    methods:{
        changeCount(upOrDown){
            let dataCount = this.gebruikerCount; //first count
            let basketOptions = [];

            if(upOrDown === "down" && dataCount > 0){
                this.gebrukersData = this.gebrukersData.filter(function (item, key) {
                    return key < (dataCount - 1)
                }, this);
            }else{
                if(this.gebruikerCount < 50){
                    let modelData = Object.assign({}, this.emptyModel);
                    if(!this.customizeUsers && this.gebrukersData.length > 0){
                        modelData = Object.assign(modelData, this.gebrukersData[0]);
                    }

                    this.gebrukersData.push(modelData);
                }
            }

            if(this.gebrukersData.length > 0){
                this.$root.basket[this.$root.flexProduct.id] = {};
                this.$root.basket[this.$root.flexProduct.id].formType = this.$root.productTypes.flex.strType;
                this.$root.basket[this.$root.flexProduct.id].id = this.$root.flexProduct.id;
                this.$root.basket[this.$root.flexProduct.id].options = [];
                this.$root.basket[this.$root.flexProduct.id].orderCount = this.gebrukersData.length;
                this.$root.basket[this.$root.flexProduct.id].customize = this.customizeUsers;

                this.gebrukersData.forEach(function (item,key) {
                    this.$root.basket[this.$root.flexProduct.id].options.push(this.getOptionItem(key));
                },this);

            }else{
                delete this.$root.basket[this.$root.flexProduct.id];
            }

            this.$root.appEvents.emit('change_basket', this.basket);
            this.$root.appEvents.emit('basket-re-calculate');
        },

        selectUndertakingProcess(type){
            this.$root.undertaking = type;
            this.$root.appEvents.emit('change_basket', this.basket);
            this.$root.appEvents.emit('basket-re-calculate');
        },

        getOptionItem(itemKey){
            return {
                name:'Gebruiker '+(itemKey + 1),
                vastProductId:this.vastProduct.id,
                mobielProductId:this.subProduct.id,
                vast1: this.gebrukersData[itemKey].vast,
                mobiel: this.gebrukersData[itemKey].mobiel,
                desktop: this.gebrukersData[itemKey].desktop,
                vastPrice:this.vastProduct.priceList[1],
                mobilePrice:this.subProduct.priceList[1],
                freeFeature:this.setFreeFeature(this.gebrukersData[itemKey]),
                vastOrderCount:0,
                usesim:false,
                selectInternetPackage:{},
                useDijitalSim:false,
                useTransfer:false,
                transferNumber:'',
                isValidTransferNumber:false,
                internationalTransferNumber:'',
                formatterTransferNumber:''
            }
        },

        selectedProductType(data){
            let flexOption = this.$root.basket[this.$root.flexProduct.id].options;
            if(data.key !== "all") {
                this.gebrukersData[data.key] = Object.assign({},this.emptyModel);

                data.selected.forEach(function (typeItem) {
                    this.gebrukersData[data.key][typeItem] = true;
                }, this);

            }else{
                this.gebrukersData.forEach(function (item, key) {
                    //this.selectedProductType({key, selected:data.selected});
                    this.gebrukersData[key] = Object.assign({},this.emptyModel);
                    data.selected.forEach(function (typeItem) {
                        this.gebrukersData[key][typeItem] = true;
                    }, this);
                }, this);
            }

            this.gebrukersData.forEach(function (item, key) {
                Object.entries(item).forEach(function ([extype, boolValue]) {
                   if(extype === "vast"){
                       flexOption[key].vast1 = boolValue;
                   }else{
                       flexOption[key][extype] = boolValue;
                   }
                   flexOption[key].freeFeature = this.setFreeFeature(item);
                }, this);
            }, this);

            this.$root.basket[this.$root.flexProduct.id].options = Array.from(flexOption);

            this.$root.appEvents.emit('change_basket', this.basket);
            this.$root.appEvents.emit('basket-re-calculate');
        },

        setFreeFeature(gebrukersData){
            if(gebrukersData.desktop){
                return 'desktop';
            }else if(gebrukersData.mobiel){
                return 'mobiel';
            }
            return 'vast';
        },

        setOpnedDiv(index){
            if(this.groupAccordionKey === index){
                this.groupAccordionKey = '';
                return;
            }
            this.groupAccordionKey = index;
        },

        basketSync(){
            this.prepareExtraProduct();
            this.removeLoading();

            if(!this.$root.basket.hasOwnProperty(this.$root.flexProduct.id)){
                this.changeCount("up");
                this.gebrukersData[0].desktop = true;
                return;
            }

            let basketOptions = this.$root.basket[this.$root.flexProduct.id].options;
            if(this.$root.basket[this.$root.flexProduct.id].hasOwnProperty("customize")){
                this.customizeUsers = this.$root.basket[this.$root.flexProduct.id].customize;
            }

            basketOptions.forEach(function (value, key) {
                let model = Object.assign({}, this.emptyModel); //{desktop:false, mobiel:false, vast:false},
                if(value.vast1){
                    model.vast = true;
                }
                if(value.mobiel){
                    model.mobiel = true;
                }
                if(value.desktop){
                    model.desktop = true;
                }
                this.gebrukersData.push(model);
            }, this);
        },

        goForward(){
            let that = this;
            let flexBasket  = this.$root.basket[this.$root.productTypes.flex.id];
            let status      = true;
            let errorKey    = -1;

            that.gebrukersData.forEach(function (item,key) {
                if(item.desktop === false && item.mobiel === false && item.vast === false){
                    status = false;
                    errorKey = key;
                }
            });
            if(status === true){
                that.$root.gotoNextPage('gebruikers');
            }else{
                if(that.customizeUsers){
                    that.groupAccordionKey = errorKey;
                    setTimeout(function () {
                        that.setCaution(errorKey);
                    }, 300);
                }
                that.$myAlert({
                    title:'Selecteer apparaat',
                    text:'Om verder te gaan dien je eerst een apparaat te selecteren'
                });
                that.$root.gotoPage('gebruikers');
            }
            return false;
        },

        setCaution(key){
            let that = this;
            this.groupAccordionErrorKey = key;
            setTimeout(function () {
                that.groupAccordionErrorKey = -1;
            }, 5000);
        },

        prepareExtraProduct(){
            let that = this;
            that.subProduct = {};
            /*
            Object.entries(that.$root.flexProduct.relation).forEach(function ([k,v]) {
                if(v.type === 120 || parseInt(v.type) === 120){
                    that.subProduct = v;
                }
                else if(v.type === 115 || parseInt(v.type) === 115){
                    that.vastProduct = v;
                }
            });
             */
            that.subProduct = that.$root.flexSubProduct;
            that.vastProduct = that.$root.flexVastProduct;
        },

        selectedItemIcons(data){
            let selected = [];
            let icons = this.$root.productTypes.flex.propsIcons;
            Object.entries(data).forEach(function ([val, key]) {
                if(key){
                    if(icons.hasOwnProperty(val)){
                        selected.push('<i class="'+icons[val]+' mx-0 mx-sm-1"></i>');
                    }
                }
            });
            if(selected.length > 0){
                return selected.join(' ');
            }
            return '';
        },

        removeLoading(){
            this.viewLoading = false;
        }
    },
    mounted() {
        let that        = this;
        if(that.$root.cookieProductLoaded){
            that.removeLoading();
            that.basketSync();
        }
        that.$root.appEvents.on("cookie-product-loaded", that.basketSync);
        that.$root.appEvents.on('next'+this.$route.params.sub, that.goForward);
        //that.$root.appEvents.on('nexttelefoonummers', that.goForward);
        /*that.$root.appEvents.on("loaded_package_products", that.removeLoading);*/
        that.$root.appEvents.emit("page.step", {step:1});
    },
    destroyed() {
        let that = this;
        that.$root.appEvents.removeListener("cookie-product-loaded", that.basketSync);
        that.$root.appEvents.removeListener('next'+that.$route.params.sub, that.goForward);
        //that.$root.appEvents.removeListener('nexttelefoonummers', that.goForward);
        /*that.$root.appEvents.removeListener("loaded_package_products", that.removeLoading);*/
    }
}
</script>

<style lang="scss">
    .gebruiker-types{

        .extension-list{
            border-bottom: 1px solid #EAECEF;
            &:last-child{
                border-bottom: none;
            }

            .extension-accordion{
                border-bottom: 1px solid #EAECEF;
                padding: 10px 0;
                &:last-child{
                    border-bottom: none;
                }
            }
        }
    }

    .e-accordion-header{
        &.parent {
            border-bottom: 1px solid #EAECEF;
            border-top: 1px solid #EAECEF;
            padding: 15px;
        }
        &.opened{
            border-bottom: 1px solid #EAECEF;
            padding-bottom: 15px;
        }
        img{
            height: 45px;
            margin: 0 auto
        }
    }
    .e-accordion-body{
        padding: 25px;
        display: none;
        transition:  all 600ms linear;
        &.opened{
            display: block;
        }
        &.empty-caution{
            background-color:rgba(226,137,142,0.4);
        }
    }
    .badge.badge-arrow-left{
        position: relative;
        height: 30px;
        padding: 0 10px;
        background-color: #82b636;
        z-index: 10;
        color: #ffffff;
        border-radius:  0 4px 4px 0;
        font-weight: normal !important;
        line-height: 30px;
        &:after{
          content: "";
          position: absolute;
          top: 0;
          left: -14.7px;
          width: 0;
          height: 0;
          border: 0 solid transparent;
          border-top-width: 15px;
          border-bottom-width: 15.1px;
          border-right: 15px solid #82b636;

        }
    }
</style>