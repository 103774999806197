<template>
  <div id="telefoonummers" class="funnel-container">
    <!--
    <div class="map-box">
        <div class="map-box-header p-4 p-sm-5 d-flex align-items-center">
            <i class="far fa-list-ol fa-2x theme-text-dark mr-4"/>
            <span class="theme-text-dark font-weight-bold font-size-18">Nieuw nummer kiezen of nummerbehoud</span>
        </div>
        <div class="map-box-main font-weight-bold px-md-5">

            <div class="way-item d-flex align-items-center flex-column flex-sm-row">
                <div class="d-flex align-items-center flex-nowrap text-center text-sm-left">
                    <span class="d-none d-sm-block">Ik wil zelf een nieuw nummer kiezen</span>
                    <span class="d-block d-sm-none">Nieuw nummer kiezen</span>
                </div>
                <div class="clearfix d-block d-sm-none"></div>
                <button class="btn btn-vzkr btn-green ml-sm-auto mt-4 mt-sm-0" @click="$root.gotoNextPage('telefoonummers', 0)">Kiezen</button>
            </div>

            <div class="way-item d-flex align-items-center flex-column flex-sm-row">
                <div class="d-flex align-items-center flex-nowrap text-center text-sm-left">
                    <span class="d-none d-sm-block">Ik wil mijn bestaande nummer(s) behouden</span>
                    <span class="d-block d-sm-none">Bestaand nummer meeverhuizen</span>
                </div>
                <div class="clearfix d-block d-sm-none"></div>
                <button class="btn btn-vzkr btn-green ml-sm-auto mt-4 mt-sm-0" @click="$root.gotoNextPage('telefoonummers', 1)">Kiezen</button>
            </div>

        </div>
    </div>
    -->
    <div class="map-box">
      <div class="map-box-header p-5">
        <div class="box-title d-flex align-items-start mb-3">
          <span class="badge badge-primary mr-3 mt-1 py-1 px-2">1</span>
          <p class="p-0 m-0"><strong class="mandatory text-nowrap">Telefoonnummer -</strong> Nieuw vast nummer en/of nummerbehoud</p>
        </div>
        <div class="number-tab d-flex align-items-center flex-column flex-md-row">

          <div class="selec-new tab-selector w-100 mr-md-2 mb-3 mb-md-0 py-3 d-flex align-items-center" :class="{selected:numberSelectType==='Nieuw_nummer'}" @click="selectPhoneProcess('Nieuw_nummer')">
            <i class="fa-circle mr-2" :class="{far:numberSelectType!=='Nieuw_nummer', fas:numberSelectType==='Nieuw_nummer'}"></i>
            <span>Nieuw nummer uitkiezen</span>
          </div>

          <div class="selec-transfer tab-selector w-100 ml-md-2 py-3 d-flex align-items-center" :class="{selected:numberSelectType==='Nummerbehound'}" @click="selectPhoneProcess('Nummerbehound')">
            <i class="fa-circle mr-2" :class="{far:numberSelectType!=='Nummerbehound', fas:numberSelectType==='Nummerbehound'}"></i>
            <span>Nummer meeverhuizen</span>
          </div>

        </div>
      </div>

      <component v-if="existComponent(numberSelectType)" v-bind:is="numberSelectType"/>

    </div>
    <div class="map-box-external-footer mt-5 d-flex align-items-center flex-column flex-sm-row">
      <a @click="$root.gotoPreviousPage('telefoonummers')" class="theme-text-blue"><i class="far fa-arrow-left"/> Terug
        naar aantal gebruikers</a>
    </div>

  </div>
</template>

<script>
import Nieuw_nummer from "@/components/Nieuw_nummer";
import Nummerbehound from "@/components/Nummerbehound";

export default {
  name: "Telefoonummers",
  components: {Nieuw_nummer, Nummerbehound},
  data() {
    return {
      numberSelectType: 'Nieuw_nummer',
    }
  },
  methods: {
    goForward() {
      if(this.numberSelectType === 'Nieuw_nummer'){
        this.$root.gotoNextPage('nieuw_nummer', 0);
      }else{
        this.$root.gotoNextPage('nummerbehound', 0);
      }
    },
    selectPhoneProcess(componentName) {
      this.numberSelectType = componentName;
    },
    existComponent(componentName) {
      let names = Object.keys(this.$options.components);
      let name = this.$options.filters.capitalize(componentName);
      name = name.replace(/\-/g, '_');
      return names.includes(name);
    }
  },
  mounted() {
    this.$root.appEvents.on('next' + this.$route.params.sub, this.goForward);
    if(Object.prototype.hasOwnProperty.call(this.$route.params, 'hash')){
      if(this.$route.params.hash === "nummerbehound"){
        this.numberSelectType = 'Nummerbehound';
      }
    }
    this.$root.appEvents.emit("page.step", {step:2});
  },
  destroyed() {
    this.$root.appEvents.removeListener('next' + this.$route.params.sub, this.goForward);
  }

}
</script>
<style lang="scss">
.number-tab{
  cursor: pointer;
  .tab-selector{
    background-color: #FFFFFF;
    border-bottom: 1px solid #EAECEF;
    padding: 28px 20px;
    border-radius: 4px;
    border-top: 1px solid transparent;
    &.selected{
      border: 1px solid #187DC1;
    }
    .fas{
      color: #187DC1;
    }
  }
}
</style>