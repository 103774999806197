<template>
    <div class="map-box mobile-edit-item mb-4" :class="{active:collapse}" v-if="!deleted">

        <div class="map-box-main mobile-product-item hand row py-4 theme-text-dark" :class="{active:collapse}" @click="toggleCollapse()">
            <div class="col-12 col-md-7 d-flex justify-content-start align-items-center">
                <i class="fas fa-sim-card theme-text-blue font-size-28 mr-2"/>
                <span class="font-weight-bold">{{ productTitle }}</span>
                <span class="ml-1 d-inline-block" v-if="product.data_size">- {{ product.data_size|dataText }}</span>
            </div>
            <div class="col-12 col-md-5 d-flex justify-content-start align-items-center mt-3 mt-md-0">
                <i class="far fa-trash-alt ml-0 ml-md-auto mr-4" @click="removeMobileProduct()"/>

                <span class="font-size-20 font-weight-bold mr-5" v-if="!hasDiscount">{{ productPrice|price }} p.m.</span>
                <div class="d-flex align-items-center justify-content-end font-weight-bold mr-5" v-else>
                    <div class="old-price text-nowrap mr-2">{{ productPrice|price }}</div>
                    <div class="new-price text-nowrap font-size-20">{{ discountPrice|price }} p.m.</div>
                </div>

            </div>
        </div>

        <div class="map-form py-5 mx-3 mx-md-5" v-if="collapse">
            <div class="row">
                <div class="col-12 col-md-4 checkbox-group d-flex align-items-center">
                    <div class="vue-checkbox">
                        <input type="checkbox" :id="'esim_'+labelId" v-model="selfData.useDijitalSim" @click="synBasket()">
                        <div class="checkbox-tick"></div>
                    </div>
                    <label :for="'esim_'+labelId"><span>Ik wil een eSIM</span> <i class="fa fa-info-circle hand" @click="smInfoText()"/> </label>
                </div>
                <div class="col-12 col-md-4 checkbox-group d-flex align-items-center">
                    <div class="vue-checkbox">
                        <input type="checkbox" :id="'transfer_'+labelId" v-model="selfData.useTransfer" @click="synBasket()">
                        <div class="checkbox-tick"></div>
                    </div>
                    <label :for="'transfer_'+labelId"><span>Ik wil nummerbehoud</span> </label>
                </div>
                <div class="col-12 col-md-4" v-if="selfData.useTransfer">
                    <input type="number" placeholder="Voorbeeld 06 12345678" class="form-control" v-model="selfData.transferNumber">
                    <div class="clearfix"></div>
                    <span class="font-size-12 text-success" v-if="selfData.isValidTransferNumber && selfData.formatterTransferNumber">
                        <i class="fa fa-check"/> {{ selfData.formatterTransferNumber }}
                    </span>
                    <span class="font-size-12 text-danger" v-else>
                        <i class="fa fa-times" v-if="selfData.formatterTransferNumber"/>
                        <span v-if="selfData.formatterTransferNumber">{{ selfData.formatterTransferNumber }} <em class="text-gray">Voorbeeld 06 12345678</em></span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "MobileProductInfoEdit",
        props:{
            item:{
                type: Object,
                require: true
            }
        },
        data(){
            return {
                collapse:false,
                deleted:false,
                selfData:{},
                productBasket:{},
                mobilBasket:{},

                tumberChangeTimer:null
            }
        },
        computed:{
            product(){
                return this.$root.products[this.item.productID];
            },
            productTitle(){
                return this.product.name;
                  //return this.$root.productTypes.vamo.id === this.item.basketType ? 'Vast op Mobiel':'Sim only';
            },
            productPrice(){
                return this.$root.productPrice(this.product.id, 1);
            },
            discountPrice(){
                return this.$root.setPromotionsProductPrice("monthly", this.product.id, this.productPrice).total;
            },
            hasDiscount(){
                return this.$root.setPromotionsProductPrice("monthly", this.product.id, this.productPrice).hasDiscount;
            },
            labelId(){
                return this.item.basketType+'_'+this.item.productID+'_'+this.item.key;
            },

            transferNumber(){
                return this.selfData.transferNumber;
            }
        },
        watch:{
            transferNumber:function (val) {
                let that = this;
                let numInfo = this.$root.numberInfo(val);
                this.selfData.formatterTransferNumber       = numInfo.formatter;
                this.selfData.internationalTransferNumber   = numInfo.international;
                this.selfData.isValidTransferNumber         = numInfo.isValid;

                if(that.tumberChangeTimer !== null){
                    clearTimeout(that.tumberChangeTimer);
                }

                that.tumberChangeTimer = setTimeout(function () {
                    that.synBasket();
                    try{
                        clearTimeout(that.tumberChangeTimer);
                    }catch (e) {}
                    that.tumberChangeTimer = null;
                },2000)
            },
        },
        methods:{
            toggleCollapse(){
                this.collapse = !this.collapse;
            },
            setCollapse(bool){
                this.collapse = bool;
            },
            removeMobileProduct(){
                let that = this;

                this.productBasket.info = this.productBasket.info.filter(function (val,key) {
                    return key !== that.item.key;
                });

                if(this.productBasket.info.length < 1){
                    delete this.mobilBasket.options[this.item.productID];

                    if(Object.keys(this.mobilBasket.options).length < 1){
                        delete this.$root.basket[this.item.basketType];
                        //delete this.mobilBasket;
                        this.$root.basket = Object.assign({}, this.$root.basket);
                    }else{
                        this.$set(this.$root.basket[this.item.basketType], 'options', this.mobilBasket.options);
                    }
                }else{
                    this.productBasket.orderCount = this.productBasket.info.length;
                    this.$set(this.mobilBasket.options, this.item.productID, this.productBasket);
                }
                //that.deleted = true;
                that.$emit("deletedOneItem");
            },
            synBasket(){
                this.$root.appEvents.emit('change_basket', this.$root.basket);
            },
            smInfoText(){
                this.$myAlert({
                    title:'Toestellen met eSIM bij VOIPZeker',
                    htmlText:`<p class="p-0 m-0">iPhone 12 mini, iPhone 12, iPhone 12 Pro en iPhone 12 Pro Max</p>
                        <p class="p-0 m-0">iPhone 11 en iPhone 11 Pro</p>
                        <p class="p-0 m-0">iPhone XR</p>
                        <p class="p-0 m-0">iPhone SE</p>
                        <p class="p-0 m-0">Samsung Galaxy S20, Galaxy S20 Plus en Galaxy S20 FE</p>
                        <p class="p-0 m-0">Samsung Galaxy S21 5G, Galaxy S21 Plus 5G en Galaxy S21 Ultra 5G</p>
                        <p class="p-0 m-0">Samsung Galaxy Note20 en Galaxy Note 20 Ultra</p>
                        <p class="p-0 m-0">Samsung Galaxy Fold</p>
                        <p class="p-0 m-0">Samsung Galaxy A32</p>
                        <p class="p-0 m-0">Sony Xperia 5 II</p>`
                });
            }
        },
        mounted() {
            this.selfData       = this.$root.basket[this.item.basketType].options[this.item.productID].info[this.item.key];
            this.productBasket  = this.$root.basket[this.item.basketType].options[this.item.productID];
            this.mobilBasket    = this.$root.basket[this.item.basketType];
        }
    }
</script>

<style lang="scss">
    .map-box{
        &.mobile-edit-item{
            min-height: unset;
            position: relative;
            .mobile-product-item {
                position: relative;
                &:after {
                    font-family: 'Font Awesome 5 Pro';
                    content: "\f054";
                    position: absolute;
                    right: 30px;
                    top: calc(50% - 11px);
                    cursor: pointer;
                }
                &.active:after{
                    content: "\f078";
                }
            }
            .map-form{
                border-top: 1px solid #DDDDDD;
            }
        }
    }
</style>