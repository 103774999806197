<template>
    <div class="samenstellen">
        <!--
        <div class="load-screen" v-if="viewLoading">
            <div class="grid-loads height-100"></div>

            <div class="row">
                <div class="col-6">
                    <div class="grid-loads height-75"></div>
                </div>
                <div class="col-6">
                    <div class="grid-loads height-30"></div>
                    <div class="grid-loads height-30"></div>
                </div>
            </div>
            <div class="grid-loads height-50"></div>
        </div>
        -->
        <div class="height-380 position-relative" v-if="viewLoading">
            <loading :addCss="'abslt'" v-if="viewLoading" />
        </div>

        <div id="ip_toestellen" v-if="readyPackage">
            <div class="map-box mb-5 position-relative">
                <div class="map-box-header">
                    <span class="theme-text-dark font-size-15">
                        <strong class="mandatory font-size-18">Telefonie samenstellen -</strong> Kies het aantal gebruikers voor de apparaten waarmee je wilt bellen (Slechts €12,50. bij 6+ gebruikers).
                    </span>
                </div>
                <div class="map-box-main p-3 p-md-5">
                    <div class="packet-product" v-if="readyPackage">
                        <div class="owl-carousel product-owl owl-theme" id="ovlcarousel"
                             data-owl-carousel
                             data-owl-dots="true"
                             data-owl-nav="true"
                             data-owl-center="false"
                             data-owl-start-position="1"
                             data-dot-class="owl-dot dark-dot"
                             data-owl-items="1"
                             data-owl-loop="false"
                             data-owl-autoplay="false"
                             data-owl-autoplay-timeout="2000"
                             data-owl-autoplay-speed="2000"
                             data-owl-margin="15"
                             data-rwd="1-1-2-3-3">
                                <FlexBasisProduct :product="basis" pType="basis" :basketCount="basketBaisCount" :productProp="basisProps" productTitle="Vaste telefonie" v-if="basis.id > 0" />
                                <FlexBasisProduct :product="flex" pType="flex" :basketCount="basketFlexWithOutVastCount" :productProp="flexProps" productTitle="Desktop bellen" v-if="flex.id > 0" />
                                <FlexBasisProduct :product="flex" pType="flex" :basketCount="basketFlexWithVastCount" :productProp="flexVastPops" :variants="true" variant="vast1" productTitle="Desktop + Vast" v-if="flex.id > 0" />
                        </div>
                    </div>
                </div>
            </div>
            <!--
            <p class="package-footer-info-text">De gratis maand zal worden verrekend met de tweede reguliere factuur die je van ons ontvangt.</p>
            -->
            <!--
            <p class="package-footer-info-text">*Dit actietarief is geldig tot 06-08-2021. Daarna is het reguliere tarief van € 17,50 ( 1- 5 gebruikers) en € 12,50 (6+ gebruikers) van toepassing. De gratis maand zal worden verrekend met de tweede reguliere factuur die je van ons ontvangt.</p>
            -->
            <div class="clearfix"></div>
        </div>
        <p class="clearfix my-5"></p>
        <!--
        <div class="samenstellen-forms mb-5" v-for="basketItem in $root.basket" :key="basketItem.id">
            <component v-if="existComponent(basketComponent(basketItem.formType))" :ref="basketItem.formType" :id="basketItem.id" v-bind:is="basketComponent(basketItem.formType)"/>
        </div>
        -->
        <div class="samenstellen-footer my-5 d-flex align-items-center mt-5">
            <a @click="$root.gotoHome()" class="theme-text-blue"><i class="far fa-arrow-left"/> Terug naar pakketten</a>
            <!--
            <button class="btn btn-vzkr btn-green ml-auto" @click="goForward()">Ga verder <i class="far fa-arrow-right"/></button>
            -->
        </div>
    </div>
</template>

<script>
    //import FlexForm from "./samenstellen/FlexForm";
    //import BasisForm from "./samenstellen/BasisForm";
    import FlexBasisProduct from "./samenstellen/FlexBasisProduct";
    import Loading from "./Loading";
    export default {
        name: "Samenstellen",
        //components:{FlexForm, BasisForm, Loading},
        components:{FlexBasisProduct, Loading},
        data(){
            return {
                viewLoading:true,
                basisProps:["Basis gebruiker",
                  "Gebruiker Vast of App op mobiel",
                  "Nationaal bellen (500 minuten)",
                  "75+ telefooncentrale functies",
                  "Te gebruiken op vast of mobiel",
                  "Onderling gratis bellen",
                  "Vaste telefoon of DECT handset",
                  "Automatische provisioning",],
                flexProps:["Flex gebruiker",
                    "Desktop bellen (Unite Phone)",
                    "Nationaal bellen (500 minuten)",
                    "75+ telefooncentrale functies",
                    "Videobellen (intern en extern)",
                    "Onbeperkt vergaderen",
                    "Receptie dashboard",
                    "Bedrijfsnaamherkenning"],
                flexVastPops:["1 Flex gebruiker op 2 apparaten",
                    "Vaste telefoon + desktop bellen",
                    "Nationaal bellen (500 minuten)",
                    "75+ telefooncentrale functies",
                    "Videobellen (intern en extern)",
                    "Onbeperkt vergaderen",
                    "Receptie dashboard",
                    "Bedrijfsnaamherkenning"]
            }
        },
        computed:{
            flex(){
                return this.$root.flexProduct;
            },
            basis(){
                return this.$root.basisProduct;
            },
            readyPackage(){
                return this.$root.loadedPackage === true;
            },
            basketBaisCount(){
                try{
                    return this.$root.basket[this.basis.id].orderCount;
                }
                catch (e) {
                    return 0;
                }
            },
            basketFlexCount(){
                try{
                    return this.$root.basket[this.flex.id].orderCount;
                }
                catch (e) {
                    return 0;
                }
            },
            basketFlexWithVastCount(){
                if(this.$root.basket.hasOwnProperty(this.flex.id)){
                    return this.$root.basket[this.flex.id].options.filter(function (item) {
                        return item.vast1;
                    }).length;

                }else{
                    return 0
                }
            },
            basketFlexWithOutVastCount(){
                if(this.$root.basket.hasOwnProperty(this.flex.id)){
                    return this.$root.basket[this.flex.id].options.filter(function (item) {
                        return !item.vast1;
                    }).length;

                }else{
                    return 0
                }
            },
        },
        methods:{

            basketComponent(formType){
                return this.$options.filters.capitalize(formType)+'Form';
            },
            existComponent(componentName){
                let names = Object.keys(this.$options.components);
                let name = this.$options.filters.capitalize(componentName);
                return names.includes(name);
            },
            isEmptyBasket(basket){
                let that        = this;
                let query       = that.$route.query;
                let flexID      = that.$root.productTypes['flex'].id;
                let basisID     = that.$root.productTypes['basis'].id;
                let returnHome  = false;
                if(typeof basket === "undefined"){
                    basket = that.$root.basket;
                }

                if(Object.keys(basket).length < 1 && that.$route.params.hasOwnProperty("sub")){
                    //that.$root.gotoHome();
                    //return;
                    //returnHome = true;
                }
                if(!that.$root.basket.hasOwnProperty(flexID) && !that.$root.basket.hasOwnProperty(basisID)){
                    //that.$root.gotoHome();
                    //return;
                    returnHome = true;
                }

                if(returnHome){
                    //that.$root.gotoHome();
                    //return;
                }
                that.viewLoading = false;
            },
            goForward(){
                let that = this;
                let flexBasket  = this.$root.basket[this.$root.productTypes.flex.id];
                let status      = true;
                if(typeof flexBasket !== "undefined"){
                    flexBasket.options.forEach(function (item, key) {
                        if(item.useTransfer === true && item.isValidTransferNumber !== true){
                            status = false;
                            that.$myAlert({
                                title:'Het opgegeven nummer is niet geldig',
                                text:'Voer een geldig telefoonnummer in die je wilt porteren.'
                            });
                            that.$refs.flex[0].setSelect(key);
                            return;
                        }
                    });
                }
                if(status === true){
                    this.$root.gotoNextPage('samenstellen');
                }
            },
            checkProduct(){
                let that = this;
                if(Object.keys(that.$root.basket).length < 1){
                    that.$root.gotoHome();
                }
            },
            checkQuery(){
                let that = this;
                let query       = that.$route.query;
                if(query.hasOwnProperty("add")){
                    if(that.$root.productTypes.hasOwnProperty(query.add)){
                        that.$root.addToBasket(that.$root.productTypes[query.add].id, 1);
                    }
                }
                //that.isEmptyBasket();
                that.viewLoading = false;
            }
        },
        mounted() {
            let that        = this;
            let query       = that.$route.query;


            that.$root.appEvents.on("change_basket", that.isEmptyBasket);
            that.$root.appEvents.on("cookie-product-loaded", that.isEmptyBasket);

            if(that.$root.cookieProductLoaded){
                that.viewLoading = false;
            }

            if(that.$root.loadedPackage){
                that.checkQuery();
            }else{
                that.$root.appEvents.on("loaded_package_products", that.checkQuery);
            }

            if(that.$root.mapLoading){
                that.$root.gotoPage("gebruikers");
            }else{
                that.$root.appEvents.on("map-loaded", function (){
                    that.$root.gotoPage("gebruikers");
                });
            }

            // setTimeout(function () {//cookie ready
            //     that.isEmptyBasket();
            //     that.viewLoading = false;
            // },4000);
            that.$root.appEvents.on('next'+this.$route.params.sub, that.goForward);
        },
        destroyed() {
            let that = this;
            that.$root.appEvents.removeListener("change_basket", that.isEmptyBasket);
            that.$root.appEvents.removeListener('next'+this.$route.params.sub, that.goForward);
            that.$root.appEvents.removeListener("cookie-product-loaded", that.isEmptyBasket);
            that.$root.appEvents.removeListener("loaded_package_products", that.checkQuery);
        }
    }
</script>

<style lang="scss">
    .samenstellen{
        width: 100%;
        margin: 0 auto;
    }
    .height-380{
        height: 380px;
    }
    .package-footer-info-text{
        width: 100%;
        clear: right;
        display: block;
        margin: -30px 0 0 0;
    }
</style>