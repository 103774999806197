const Abstract_wilkelmand  = {

    props:{
        basketItem:{
            type: Object,
            require: true
        },
        totalItem:{
            type: Object,
            require: true
        }
    },

    data(){
        return {
            collapse:false,
            listItems:{},
            setupPriceDetail:{},

            flexTotal:0,
            basisTotal:0,
            flexSubProductTotal:0,
            plugPlayTotal:0,
            newNumberTotal:0,
            transferNumberTotal:0,
            hardwareTotal:0,
            mobileVamoTotal:0,
            mobileOnlySimTotal:0,
            onlySetupPrice:0,

            hasMonthlyDiscount:false,
            hasOneTimeDiscount:false,
            monthlyOldTotal:0,
            oneTimeOldTotal:0,

            allProductCount:0,

            /**
             * urun id lerini tutar toplamlarını değil, bir ürün için sunucudan istek yapıldımı yapılmadımı onu anlamak için
             */
            productRequestList:[]
        }
    },
    computed:{
        basketTotal(){
            return this.flexTotal + this.flexSubProductTotal + this.basisTotal + this.newNumberTotal + this.transferNumberTotal +
                this.hardwareTotal + this.mobileVamoTotal + this.mobileOnlySimTotal + this.onlySetupPrice;
        },
        monthlyTotal(){
            return (this.basketTotal - this.hardwareTotal) - this.onlySetupPrice;
        },
        oneTimeTotal(){
            //return this.hardwareTotal + this.onlySetupPrice + this.plugPlayTotal;
            return this.hardwareTotal + this.onlySetupPrice;
        },
        fullTotal(){
            //return this.monthlyTotal + this.oneTimeTotal;
            return this.remainingDayPrice + this.oneTimeTotal;
        },
        remainingDayPrice(){
            let dayInfo = this.$root.daysLeftToMonth();
            let monthly = this.monthlyTotal - this.transferNumberTotal;
            let perDay = monthly / dayInfo.totalDay;
            return (perDay * dayInfo.remainingDay);
        },
        btvPrice(){
            return this.$root.getPercentValue(this.fullTotal, 21).percentValue;
        },
        currentTotal(){
            return  this.remainingDayPrice + this.oneTimeTotal + this.btvPrice;
        },

        monthlyName(){
            let names = ["januari", "februari", "maart", "april", "mei", "juni", "juli", "augustus", "september", "oktober", "november", "december"];
            let month = new Date().getMonth();
            if(typeof names[month] !== "undefined"){
                return names[month]
            }
            return '';
        },
        subPropsValue(){
            return {
                basketTotal: this.basisTotal,
                monthlyTotal: this.monthlyTotal,
                oneTimeTotal: this.oneTimeTotal,
                fullTotal: this.fullTotal,
                remainingDayPrice: this.remainingDayPrice,
                btvPrice: this.btvPrice,
                currentTotal: this.currentTotal,
                hasNumber: this.hasNumber,
                hasGebruiker: this.hasGebruiker,
                monthlyName: this.monthlyName
            }
        },
        hasNumber:{
            set:function (val) {
                this.$root.hasNumber = val;
            },
            get:function () {
                return this.$root.hasNumber;
            }
        },
        hasGebruiker:{
            set:function (val) {
                this.$root.hasGebruiker = val;
            },
            get:function () {
                return this.$root.hasGebruiker;
            }
        }
    },
    methods:{
        toggleCollapse(){
            this.collapse = !this.collapse;
        },
        clearTotals(){
            this.flexTotal              = 0;
            this.flexSubProductTotal    = 0;
            this.basisTotal             = 0;
            this.plugPlayTotal          = 0;
            this.newNumberTotal         = 0;
            this.transferNumberTotal    = 0;
            this.hardwareTotal          = 0;
            this.mobileVamoTotal        = 0;
            this.mobileOnlySimTotal     = 0;
            this.onlySetupPrice         = 0;

            this.hasMonthlyDiscount = false;
            this.hasOneTimeDiscount = false;
            this.monthlyOldTotal = 0;
            this.oneTimeOldTotal = 0;

            this.setupPriceDetail   = {};
            this.listItems          = {};
            this.hasNumber          = false; //computed
            this.hasGebruiker       = false; //computed

            this.$root.hasVamo      = false;
            this.$root.hasSimOnly   = false;
            this.allProductCount    = 0;
        },
        getProductPrice(productID, per=1){
            return this.$root.productPrice(productID, per);
        },
        getProductsPrice(products, productID, per=1){
            return this.$root.productPrice(productID, per, products);
        },

        basketReCalculate(){
            let that = this;
            that.clearTotals();
            that.listItems = {};

            Object.entries(this.$root.basket).forEach(function ([k,v]) {
                if(v.formType === 'flex'){
                    that.calculateFlex(v);
                }
                else if(v.formType === 'basis'){
                    that.calculateBasis(v);
                }
                else if(v.formType === 'newNumber'){
                    that.calculateNewNumber(v);
                }
                else if(v.formType === 'transferNumber'){
                    that.calculateTransferNumber(v);
                }
                else if(v.formType === 'hardware'){
                    that.calculateHardware(v);
                }
                else if(v.formType === 'vamo' || v.formType === 'onlySim'){
                    that.calculateVamo(v);
                }
                else{
                    that.$root.infoLog("empty type: ", 'for: ', k, v.formType);
                }
            });

            that.listItems = Object.assign({}, that.listItems);

            that.onlySetupPrice = 0;
            Object.entries(this.setupPriceDetail).forEach(function ([k,v]) {
                that.onlySetupPrice += v;
            });

            Object.entries(this.listItems).forEach(function ([k,v]) {
                if(k === "hardware"){
                    that.oneTimeOldTotal += v.oldTotal;
                    if(v.hasDiscount === true){
                        that.hasOneTimeDiscount = true;
                    }
                }else{
                    if(v.hasOwnProperty("oldTotal")){
                        that.monthlyOldTotal += v.oldTotal;
                    }else{
                        that.monthlyOldTotal += v.total;
                    }
                    try{
                        if(v.hasDiscount === true){
                            that.hasMonthlyDiscount = true;
                        }
                    }catch (e) {}
                }
            });
            try{
                that.plugPlayTotal = that.$root.plugPlayProduct.price;
            }catch (e) {
                that.plugPlayTotal = 0;
            }
            //@todo setup promosyonlarını degerlendirdiğinde bu kısmı düzenle
            that.oneTimeOldTotal += that.onlySetupPrice;
            that.$root.appEvents.emit('basket.calculated', {items:this.listItems});

            let htmlCount = document.getElementById('header_basketcount');
            if(typeof htmlCount !== "undefined" && htmlCount !== null){
                htmlCount.innerHTML = this.allProductCount.toString();
            }

        },

        calculateFlex(flexBasketItem, manuel= false){
            let that = this;
            let product = this.$root.products[flexBasketItem.id];
            let perAmount = this.$root.calculateProductPrice(flexBasketItem.id, flexBasketItem.orderCount) / flexBasketItem.orderCount;
            let setupPerAmount = this.$root.productSetupPrice(flexBasketItem.id, 1);
            let allFlexTotal = this.$root.calculateProductPrice(flexBasketItem.id, flexBasketItem.orderCount); //sadece flex urunlerin toplamı
            let allPropertiesTotal = 0; //flex urunlere eklenen ozelliklerin toplamı
            let allPropertiesOldTotal = 0; //flex urunlere eklenen ozelliklerin toplamı
            let total = 0; //genel toplam (indirim varsa indirimli)
            let oldTotal = 0; //genel toplam (indirimsiz)
            let promotion;

            this.allProductCount += flexBasketItem.orderCount;

            promotion = that.$root.setPromotionsProductPrice("monthly", flexBasketItem.id, allFlexTotal);
            if(promotion.hasDiscount){
                total += promotion.total;
            }else{
                total += allFlexTotal;
            }

            oldTotal += allFlexTotal;

            let mobilePerPrice = this.$root.calculateProductPrice(this.$root.flexSubProduct.id, flexBasketItem.orderCount) / flexBasketItem.orderCount
            let vastPerPrice = this.$root.calculateProductPrice(this.$root.flexVastProduct.id, flexBasketItem.orderCount) / flexBasketItem.orderCount



            //ozellikleri hesapla
            flexBasketItem.options.forEach(function (opt) {
                let subProductPromotion = that.$root.setPromotionsProductPrice("monthly", opt.mobielProductId, mobilePerPrice);
                if(subProductPromotion.hasDiscount){
                    allPropertiesTotal += subProductPromotion.total; // mobil (subProductPromotion.total * 3); //vast, mobil, desktop
                    allPropertiesOldTotal += mobilePerPrice; //(parseFloat(opt.mobilePrice) * 3);
                }else{
                    allPropertiesTotal += mobilePerPrice; //(parseFloat(opt.mobilePrice) * 3);//vast, mobil, desktop
                    allPropertiesOldTotal += mobilePerPrice; //(parseFloat(opt.mobilePrice) * 3);//vast, mobil, desktop
                }

                //vastProductId
                subProductPromotion = that.$root.setPromotionsProductPrice("monthly", opt.vastProductId, vastPerPrice);
                if(subProductPromotion.hasDiscount){
                    allPropertiesTotal += subProductPromotion.total; // mobil (subProductPromotion.total * 3); //vast, mobil, desktop
                    allPropertiesOldTotal += vastPerPrice; //(parseFloat(opt.mobilePrice) * 3);
                }else{
                    allPropertiesTotal += vastPerPrice; //(parseFloat(opt.mobilePrice) * 3);//vast, mobil, desktop
                    allPropertiesOldTotal += vastPerPrice; //(parseFloat(opt.mobilePrice) * 3);//vast, mobil, desktop
                }
            });

            that.flexTotal  = total;//(total + allPropertiesTotal);
            //oldTotal        += allPropertiesOldTotal;
            this.flexSubProductTotal = allPropertiesTotal;


            if(flexBasketItem.orderCount > 0){
                that.hasGebruiker = true;

                if(setupPerAmount > 0) {
                    if (that.$root.flexConf.setup === 'per') {
                        that.setupPriceDetail[flexBasketItem.id] = flexBasketItem.orderCount * setupPerAmount
                    } else {
                        that.setupPriceDetail[flexBasketItem.id] = setupPerAmount
                    }
                }
                try {
                    if (that.$root.productSetupPrice(that.$root.flexSubProduct.id, flexBasketItem.orderCount)) {
                        that.setupPriceDetail[that.$root.flexSubProduct.id] = that.$root.productSetupPrice(that.$root.flexSubProduct.id, flexBasketItem.orderCount)
                    }
                    if (that.$root.productSetupPrice(this.$root.flexVastProduct.id, flexBasketItem.orderCount)) {
                        that.setupPriceDetail[this.$root.flexVastProduct.id] = that.$root.productSetupPrice(this.$root.flexVastProduct.id, flexBasketItem.orderCount)
                    }
                }
                catch (e) {}
            }

            that.listItems[flexBasketItem.formType] = {
                name: 'Abonnement',
                count: flexBasketItem.orderCount,
                countName: 'gebruiker(s)',
                hasDiscount: promotion.hasDiscount,
                total: that.flexTotal,
                setupTotal: that.setupPriceDetail[flexBasketItem.id],
                oldTotal,
                type:flexBasketItem.formType,
                goto:'gebruikers',
                basketIndex: flexBasketItem.id
            };

            if(manuel !== true) {
                that.$root.appEvents.emit('flex-re-calculate', manuel);
            }
        },

        calculateBasis(basisBasketItem, manuel = false){
            let that = this;
            let product = this.$root.products[basisBasketItem.id];
            let perAmount = this.$root.calculateProductPrice(basisBasketItem.id, 1);
            let setupPerAmount = this.$root.productSetupPrice(basisBasketItem.id, 1);
            let total = this.$root.calculateProductPrice(basisBasketItem.id, basisBasketItem.orderCount);
            let promotion;

            this.allProductCount += basisBasketItem.orderCount;

            promotion = this.$root.setPromotionsProductPrice("monthly", basisBasketItem.id, total);

            if(promotion.hasDiscount){
                this.basisTotal = promotion.total;
            }else{
                this.basisTotal = total;
            }

            if(basisBasketItem.orderCount > 0){
                this.hasGebruiker = true;
                if(setupPerAmount > 0) {
                    if (that.$root.basisConf.setup === 'per') {
                        that.setupPriceDetail[basisBasketItem.id] = basisBasketItem.orderCount * setupPerAmount
                    } else {
                        that.setupPriceDetail[basisBasketItem.id] = setupPerAmount
                    }
                }
            }

            this.listItems[basisBasketItem.formType] = {
                name: 'Unite basis gebruiker',
                count: basisBasketItem.orderCount,
                countName: 'gebruiker(s)',
                hasDiscount: promotion.hasDiscount,
                total: this.basisTotal,
                setupTotal: that.setupPriceDetail[basisBasketItem.id],
                oldTotal:total,
                type:basisBasketItem.formType,
                goto:'samenstellen',
                basketIndex: basisBasketItem.id
            };

            if(manuel !== true) {
                this.$root.appEvents.emit('basis-re-calculate', manuel);
            }
        },

        calculateNewNumber(numberBasketItem, manuel = false){
            let totalPhoneNumber = Object.keys(numberBasketItem.options).length;
            this.newNumberTotal = totalPhoneNumber * this.$root.phoneNumbersConf.price;

            this.allProductCount += totalPhoneNumber;

            if(this.$root.phoneNumbersConf.setupPrice > 0){
                if(this.$root.phoneNumbersConf.setup === 'per'){
                    this.setupPriceDetail.phoneNumbers = totalPhoneNumber * this.$root.phoneNumbersConf.setupPrice
                }else{
                    this.setupPriceDetail.phoneNumbers = this.$root.phoneNumbersConf.setupPrice
                }
            }
            if(totalPhoneNumber > 0) {
                this.listItems[numberBasketItem.formType] = {
                    name: 'Telefoonnummer',
                    count: totalPhoneNumber,
                    countName: 'nummer(s)',
                    type: numberBasketItem.formType,
                    total: this.newNumberTotal,
                    //goto: 'nieuw_nummer',
                    goto: 'telefoonummers',
                    basketIndex: 'phoneNumbers'
                };
                this.hasNumber = true;
            }else{
                delete this.listItems[numberBasketItem.formType];
                delete this.$root.basket['phoneNumbers'];
            }
            if(manuel !== true) {
                this.$root.appEvents.emit('newNumber-re-calculate', manuel);
            }
        },

        calculateTransferNumber(numberBasketItem, manuel = false){
            let totalTransferPhoneNumber = Object.keys(numberBasketItem.options).length;
            this.transferNumberTotal = totalTransferPhoneNumber * this.$root.transferNumbersConf.price;

            this.allProductCount += totalTransferPhoneNumber;

            if(this.$root.transferNumbersConf.setupPrice > 0){
                if(this.$root.transferNumbersConf.setup === 'per'){
                    this.setupPriceDetail.transferNumbers = totalTransferPhoneNumber * this.$root.transferNumbersConf.setupPrice
                }else{
                    this.setupPriceDetail.transferNumbers = this.$root.transferNumbersConf.setupPrice
                }
            }
            if(totalTransferPhoneNumber > 0) {
                this.listItems[numberBasketItem.formType] = {
                    name: 'Nummerbehoud',
                    count: totalTransferPhoneNumber,
                    countName: 'nummer(s)',
                    total: this.transferNumberTotal,
                    type: numberBasketItem.formType,
                    //goto: 'nummerbehound',
                    goto: 'telefoonummers',
                    basketIndex: 'transferNumbers'
                };
                this.hasNumber = true;
            }else{
                delete this.listItems[numberBasketItem.formType];
                delete this.$root.basket['transferNumbers'];
            }
            if(manuel !== true) {
                this.$root.appEvents.emit('transferNumber-re-calculate', manuel);
            }
        },
        calculateHardware(hardwareBasketItem, manuel = false){
            let that        = this;
            let total       = 0;
            let oldTotal    = 0;
            let orderCount  = 0;
            let hasDiscount = false;
            let otherProductID = []; //cekilmemis session ile gelen bir urun var onu tespit et toplamadan geç, urun için istek gönder bilgi geldiğinde topla

            Object.entries(hardwareBasketItem.options).forEach(function ([productID, opt]) {
                let itemProduct;
                let productTotal, tmpTotal;
                if(that.$root.products.hasOwnProperty(productID)) {
                    itemProduct = that.$root.products[productID];

                    productTotal    = (parseFloat(itemProduct.price) * opt.orderCount);
                    tmpTotal        = that.$root.setPromotionsProductPrice("oneTime", productID, productTotal);

                    if(tmpTotal.hasDiscount){
                        total += tmpTotal.total;
                        hasDiscount = true;
                    }
                    else{
                        total += productTotal;
                    }

                    oldTotal += productTotal;
                    orderCount += parseInt(opt.orderCount);

                    if(that.$root.productSetupPrice(productID, opt.orderCount) > 0){
                        that.setupPriceDetail[productID] = that.$root.productSetupPrice(productID, opt.orderCount)
                    }

                }
                else{
                    otherProductID.push(productID);
                    that.$root.getOneProduct(productID).then(function (product) {
                        try{
                            //total += (parseFloat(product.price) * opt.orderCount);
                            //orderCount += parseInt(opt.orderCount);
                            if(!that.productRequestList.includes(productID)){// aynı urun icin daha onceden bir recursive cağrım olmadıysa tekrar isle, yoksa donguye girmesin
                                that.productRequestList.push(productID);
                                that.calculateHardware(hardwareBasketItem, manuel);
                            }
                        }catch (e) {}
                    });
                }
            });

            that.hardwareTotal = total;
            this.allProductCount += orderCount;
            this.listItems[hardwareBasketItem.formType] = {
                name: 'IP toestellen',
                count: orderCount,
                countName: 'IP toestellen',
                total: that.hardwareTotal,
                hasDiscount,
                oldTotal,
                type:hardwareBasketItem.formType,
                goto:'ip_toestellen',
                basketIndex: 'hardware'
            };
            if(manuel !== true) {
                this.$root.appEvents.emit('hardware-re-calculate', manuel);
            }

            if(Object.keys(this.$root.basket.hardware.options).length < 1){
                delete this.$root.basket.hardware;
            }
        },

        calculateVamo(basketItem, manuel = false){
            let that            = this;
            let mainAmout       = 0;
            let oldTotal        = 0;
            let orderCount      = 0;
            let hasDiscount = false;
            let getId           = 0;
            this.setupPriceDetail[basketItem.formType] = 0;
            Object.entries(basketItem.options).forEach(function ([k,v]) {
                let productPrice        = that.getProductPrice(v.id, 1);
                let productPieceTotal   = (productPrice * v.orderCount);

                let promotion = that.$root.setPromotionsProductPrice("monthly", v.id, productPieceTotal);

                if(promotion.hasDiscount){
                    mainAmout += promotion.total;
                    hasDiscount = true;
                }else{
                    mainAmout += productPieceTotal;
                }

                if(that.$root.productSetupPrice(v.id) > 0){
                    that.setupPriceDetail[basketItem.formType] += (that.$root.productSetupPrice(v.id) * v.orderCount);
                }

                oldTotal   += productPieceTotal;
                orderCount += v.orderCount;
            });

            if(basketItem.formType === 'vamo'){
                that.mobileVamoTotal = mainAmout;
            }
            else{
                that.mobileOnlySimTotal = mainAmout;
            }

            this.allProductCount += orderCount;

            this.listItems[basketItem.formType] = {
                subName:'Simkaart',
                name: basketItem.formType === 'vamo' ? 'VaMo (Vast mobiel integratie)' : 'Mobiele telefonie (sim-only)',
                count: orderCount,
                countName: '',
                total: mainAmout,
                setupTotal: that.setupPriceDetail[basketItem.formType],
                oldTotal,
                orderCount,
                hasDiscount,
                type:basketItem.formType,
                goto:'vast_op_mobiel_paketten',
                basketIndex: basketItem.id
            };

            if(orderCount > 0){
                if(basketItem.formType === 'vamo'){
                    this.$root.hasVamo      = true;
                }else{
                    this.$root.hasSimOnly   = true;
                }
            }

            if(manuel !== true) {
                this.$root.appEvents.emit(basketItem.formType+'-re-calculate', manuel);
            }
        },

        flattenSimProduct(basketItem){
            let that = this;
            let productInfos = [];
            let parentProductId = basketItem.id;

            Object.entries(basketItem.options).forEach(function ([productID, opt]) {
                opt.info.forEach(function (info,key) {
                    productInfos.push({
                        parentProductId,
                        productID,
                        key,
                        info
                    });
                });
            });
            return productInfos;
        }
    }

};

export { Abstract_wilkelmand }