<template>
    <div id="bottom-basket" class="d-flex flex-column" :class="{opened:isOpen}" v-if="isView">

        <div class="bottom-basket-main" v-if="isOpen">
            <BottomProductsList :items="listItems" :setupPrices="setupPriceDetail" :monthlyTotal="monthlyTotal" :oneTimeTotal="oneTimeTotal" :allValues="subPropsValue" />
        </div>

        <div class="bottom-basket-header">
            <div class="basket-container container">

                <div class="row">
                    <div class="col-6 col-md-8">
                        <div class="row pt-2 pt-md-4">
                            <div class="col-12 col-md-6 pt-0 pt-lg-2">
                                <a class="basket-collapse text-nowrap" @click="toggleBasket()">
                                    <i class="far fa-angle-up" v-if="!isOpen"/>
                                    <i class="far fa-angle-down" v-else/>
                                    <span class="ml-1 d-none d-sm-inline-block d-md-none d-lg-inline-block">Bekijk winkelwagen</span>
                                    <span class="ml-1 d-inline-block d-sm-none d-md-inline-block d-lg-none">Winkelwagen</span>
                                </a>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="bottom-baskt-total" v-if="!isOpen">
                                    <strong class="mandatory mr-2">{{ monthlyTotal|price }}</strong>
                                    <div class="clearfix d-block d-sm-none"></div>
                                    <span class="d-none d-sm-inline-block d-md-none d-lg-inline-block">per maand excl. BTW</span>
                                    <span class="d-inline-block d-sm-none d-md-inline-block d-lg-none">p.m. excl. BTW</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-md-4 text-right pt-4">
                        <button :class="buttonCss" @click="nextTo()" v-if="buttonView" :disabled="buttonDisable">{{ buttonText }} <i class="far fa-arrow-right d-none d-sm-inline-block"/></button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import {Abstract_wilkelmand} from "../mixins/Abstract_wilkelmand";
    import BottomProductsList from "./basket/BottomProductsList";
    export default {
        name: "BottomBasket",
        components:{BottomProductsList},
        mixins:[Abstract_wilkelmand],
        data(){
            return {
                isOpen:false,
                manageNexButton:false,
                nextButtonOptions:null,
                buttonView:true,
                buttonDisable:false,
                buttonText: 'Ga verder',
                defaultButtonText: 'Ga verder',
                buttonCss:'btn btn-vzkr btn-green',
                defaultButtonCss:'btn btn-vzkr btn-green'
            }
        },
        computed:{
            isView(){
                let status = this.$route.params.hasOwnProperty('sub');
                if(status){
                    return this.$route.params.sub !== 'winkelmand';
                }
                return status;
            },
            products(){
                return this.$root.products;
            },
            basketItemCount(){
                return Object.keys(this.$root.basket).length;
            }
        },
        watch:{
            basketItemCount:function (val) {
                if(val < 1){
                    this.clearTotals();
                }
            }
        },
        methods:{
            toggleBasket(){
                let that = this;
                this.isOpen = !this.isOpen;
                let evt = new CustomEvent("funnel-bottombasket-status", {
                    bubbles:true,
                    detail:{status:that.isOpen}
                });
                document.dispatchEvent(evt);
            },
            hideBottomView(){
              let that = this;
              this.isOpen = false;
              let evt = new CustomEvent("funnel-bottombasket-status", {
                bubbles:true,
                detail:{status:that.isOpen}
              });
              document.dispatchEvent(evt);
            },
            nextTo(){
                if(this.manageNexButton) {
                    try {
                        if (this.nextButtonOptions.hasOwnProperty("currentKey") && this.nextButtonOptions.hasOwnProperty("nextIndex")) {
                            if(this.nextButtonOptions.nextIndex === "-1" || this.nextButtonOptions.nextIndex < 0){
                                this.$root.appEvents.emit('next'+this.$route.params.sub);
                            }else{
                                this.$root.gotoNextPage(this.nextButtonOptions.currentKey, this.nextButtonOptions.nextIndex);
                            }
                            return;
                        }
                    } catch (e) {}
                }
                this.$root.appEvents.emit('next'+this.$route.params.sub);
            },
            setButtonDefault(){
                this.manageNexButton  = false;
                this.nextButtonOptions = null;
                this.buttonView       = true;
                this.buttonDisable    = false;
                this.buttonText       = this.defaultButtonText;
                this.buttonCss        = this.defaultButtonCss;
            },
            setManageButon(mapItem = null, mapKey){
                let that = this;
                let hasType = "disable", hasProduct = false;

                if(mapItem === null){
                  this.setButtonDefault();
                }else{
                    this.manageNexButton = true;
                    if(typeof mapItem.basketButton === "object"){
                        this.nextButtonOptions = Object.assign({
                            currentKey:mapKey,
                            nextIndex:0,
                            buttonText:this.defaultButtonText,
                            buttonCss: this.defaultButtonCss,
                            hasItem:[],
                            emptyAction:null
                        }, mapItem.basketButton);

                        if(Array.isArray(mapItem.basketButton.hasItem)) {
                            if (mapItem.basketButton.hasItem.length > 0) {
                                mapItem.basketButton.hasItem.forEach(function (basketIndex) {
                                    if(that.listItems.hasOwnProperty(basketIndex)){
                                        if(that.listItems[basketIndex].count > 0){
                                          hasProduct = true;
                                        }
                                    }
                                });

                                if(hasProduct !== true && mapItem.basketButton.hasOwnProperty("emptyAction")){
                                    if(mapItem.basketButton.emptyAction === "hidden"){
                                      this.buttonView = false;
                                    }
                                    else if(mapItem.basketButton.emptyAction === "disable"){
                                        this.buttonDisable = true;
                                        if(mapItem.basketButton.hasOwnProperty("emptyCss")){
                                            this.buttonCss = mapItem.basketButton.emptyCss;
                                        }
                                    }
                                }
                                if( hasProduct !== true && mapItem.basketButton.hasOwnProperty("emptyText") ){
                                    that.buttonText = mapItem.basketButton.emptyText;
                                }
                            }
                        }

                        if(mapItem.basketButton.hasOwnProperty("buttonText")){
                            that.buttonText = mapItem.basketButton.buttonText;
                        }
                        if(mapItem.basketButton.hasOwnProperty("buttonCss")){
                            that.buttonCss = mapItem.basketButton.buttonCss;
                        }

                    }else{
                        if (mapItem.basketButton === "hidden") {
                            this.buttonView = false;
                        } else if (mapItem.basketButton === "disable") {
                            this.buttonDisable = true;
                        }
                    }
                }
            },
            reCalculate(){
                this.basketReCalculate();
                this.watchRoute(this.$route.params.sub);
            },
            watchRoute(index){
                let info = this.$root.getMapIndexInfo(index);
                this.setButtonDefault();
                try {
                    if (info.hasOwnProperty('basketButton')) {
                        this.setManageButon(info, index);
                    } else {
                        this.setManageButon(null);
                    }
                }catch (e) {
                    this.setManageButon(null);
                }
                //this.$root.appEvents.emit('funnel-bottombasket-hide');
            }
        },
        mounted() {
            this.$root.appEvents.on('basket-re-calculate', this.reCalculate);
            this.$root.appEvents.on('changed-page', this.watchRoute);
            this.$root.appEvents.on('route-changed', this.watchRoute);
            this.$root.appEvents.on('funnel-bottombasket-hide', this.hideBottomView);
        },
        destroyed() {
            this.$root.appEvents.removeListener('basket-re-calculate', this.reCalculate);
            this.$root.appEvents.removeListener('changed-page', this.watchRoute);
            this.$root.appEvents.removeListener('route-changed', this.watchRoute);
            this.$root.appEvents.removeListener('funnel-bottombasket-hide', this.hideBottomView);
        }
    }
</script>

<style lang="scss">
    #bottom-basket{
        position: fixed;
        width: 100%;
        height: auto;
        max-height: 90%;
        left: 0;
        bottom: 0;
        z-index: 99999;
        //background-color: #ffffff;
        @include border-top-radius(10px);
        &.opened:after{
            content: " ";
            position: absolute;
            left: 0;
            top: 1px;
            height: 2px;
            width: 100%;
            //background-color: #0c5460;
            -webkit-box-shadow: 0 -2px 5px 0 #787878;
            box-shadow: 0 -2px 5px 0 #787878;
            z-index: -1;
        }
        .bottom-basket-main{
            width: 100%;
            height: 100%;
            max-height: calc(100% - 84px);
            overflow-y: auto;
            position: relative;
            background-color: #ffffff;
            z-index: 999999;
            @include border-top-radius(10px);
        }
        .bottom-basket-header{
            width: 100%;
            height: 84px;
            padding: 0 15px;
            border-top: 2px solid transparent;
            box-shadow: 0px -4px 3px rgba(0, 0, 0, 0.25);
            background: #FAFAFA;
            z-index: 99999;
            .basket-container{
                height: inherit;
                margin: 0 auto;
            }
            .basket-collapse{
                font-size: 13px;
                color: #187DC1;
                font-weight: bold;
            }
            .bottom-baskt-total{
                font-size: 15px;
                color:#002d47;
                strong{
                    font-size: 24px;
                }
                @include media-breakpoint-down(md) {
                    font-size: 13px;
                    strong{
                        font-size: 15px;
                    }
                }
            }
        }
        &.opened{
          .bottom-basket-header{
            box-shadow: none;
            border-top: 2px solid #f3f3f3;
          }
        }
    }
</style>