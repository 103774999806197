<template>
    <div class="dialog-bg">
        <my-alert/>
        <my-confirm/>
        <my-prompt/>
    </div>
</template>

<script>
    import MyAlert from "./MyAlert";
    import MyConfirm from "./MyConfirm";
    import MyPrompt from "./MyPrompt";
    export default {
        name: "MyDialog",
        components:{MyPrompt, MyConfirm, MyAlert},
        data(){
            return {
                isView:false,
                dialogType:'alert'
            }
        },
        methods:{

        }
    }
</script>

<style scoped>

</style>