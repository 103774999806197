<template>
    <div id="mobil_paketten_samenstellen" class="mb-5 pb-5" v-if="isReady">

        <div class="alert alert-warning d-flex justify-content-start">
            <i class="fa fa-info-circle mr-2 pt-1"/>
            <div class="flex-alert-text">
                <p class="p-0 m-0"><strong class="mandatory">Informatie: </strong>
                    <span>Indien je gebruik wilt maken van eSIM of nummerbehoud dan kan je dit hieronder opgeven</span></p>
            </div>
        </div>

        <MobileProductInfoEdit v-for="editItem in flattenProducts" :ref="'mobilEdit'+itemKey(editItem)" :key="itemKey(editItem)" :item="editItem" v-on:deletedOneItem="deletedOneItem" v-if="viewEditComponent" />

        <div class="map-box-external-footer mt-5 d-flex align-items-center flex-column flex-sm-row mb-5">
            <a @click="$root.gotoPreviousPage('mobil_paketten_samenstellen')" class="theme-text-blue" v-if="$root.hasprevious('mobil_paketten_samenstellen')"><i class="far fa-arrow-left"/> Vorige stap</a>
        </div>

    </div>
</template>

<script>
    import MobileProductInfoEdit from "./mobil_paketten_samenstellen/MobileProductInfoEdit";
    export default {
        name: "Mobil_paketten_samenstellen",
        components:{MobileProductInfoEdit},
        data(){
            return {
                isReady:false,
                viewEditComponent:true,
                flattenProducts:[]
            }
        },
        computed:{
            mobileIds(){
                return [
                    this.$root.productTypes.vamo.id,
                    this.$root.productTypes.onlySim.id
                ];
            }
        },
        methods:{
            itemKey(editItem){
                return editItem.basketType+'_'+editItem.productID+'_'+editItem.key
            },
            basketOptions(productId){
                try{
                    return this.$root.basket[productId].options;
                }catch (e) {
                    return {};
                }
            },
            flattenBasketProduct(){
                let that = this;
                let productInfos = [];
                ['vamo', 'onlySim'].forEach(function (basketType) {
                    Object.entries(that.basketOptions(basketType)).forEach(function ([productID, opt]) {
                        opt.info.forEach(function (info,key) {
                            productInfos.push({
                                basketType,
                                productID,
                                key,
                                info
                            });
                        });
                    });
                });
                that.flattenProducts    = productInfos;
                that.isReady            = true;
            },
            checkBasketProduct(){
                if(typeof this.$root.basket['vamo'] === "undefined" && typeof this.$root.basket['onlySim'] === "undefined"){
                    this.$myAlert({
                        title:'U heeft geen product gekozen',
                        text:'Om door te gaan dien je minimaal één product te kiezen.',
                    });
                    this.$root.gotoPreviousPage('mobil_paketten_samenstellen');
                    return;
                }
                this.flattenBasketProduct();
            },
            deletedOneItem(){
                this.flattenBasketProduct();
                this.checkBasketProduct();
                this.$root.appEvents.emit('change_basket', this.$root.basket);
                this.$root.appEvents.emit('basket-re-calculate');
            },
            goForward(){
                let that = this;
                let status = true;
                this.flattenBasketProduct();

                this.flattenProducts.forEach(function (opt,key) {

                    if(opt.info.useTransfer === true && opt.info.isValidTransferNumber !== true){
                        status = false;
                        that.$myAlert({
                            title:'Het opgegeven nummer is niet geldig',
                            text:'Voer een geldig telefoonnummer in die je wilt porteren.'
                        });
                        try{
                            that.$refs['mobilEdit'+that.itemKey(opt)][0].setCollapse(true);
                        }catch (e) {}
                        return;
                    }
                });

                if(status){
                    this.$root.gotoNextPage('mobil_paketten_samenstellen', 0);
                }
            }
        },
        mounted() {
            let that = this;
            //this.checkBasketProduct();
            this.$root.appEvents.on("cookie-product-loaded", this.checkBasketProduct);

            setTimeout(function () {//cookie ready
                //that.checkBasketProduct();
            },4000);

            this.flattenBasketProduct();
            this.$root.appEvents.on('next'+this.$route.params.sub, this.goForward);
        },
        destroyed() {
            this.$root.appEvents.removeListener('next'+this.$route.params.sub, this.goForward);
            this.$root.appEvents.removeListener("cookie-product-loaded", this.checkBasketProduct);
        }
    }
</script>

<style lang="scss">

</style>