<template>
    <div class="my-alert-bg" v-if="isShow">
        <div class="my-alert">
            <div class="my-alter-header" v-if="viewTitle">{{ title }}</div>
            <div class="my-alert-main" v-if="!viewHtml">{{ text }}</div>
            <div class="my-alert-main" v-else><div v-html="htmlText"></div></div>
            <div class="my-alert-footer">
                <button type="button" class="btn btn-sm btn-radius4 btn-orange" @click="hideAlert()"> {{ buttonText }}</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "MyAlert",
        data(){
            return {
                isShow:false,
                title:'',
                text:'',
                htmlText:'',
                buttonText:'',
            }
        },
        computed:{
            viewTitle(){
                return this.title.length > 0;
            },
            viewHtml(){
                return this.htmlText.length > 0;
            }
        },
        methods:{
            hideAlert(){
                this.isShow = false;
            },
            show(params){
                this.isShow     = true;
                this.title      = params.title ?? 'Info';
                this.text       = params.text ?? 'undefined';
                this.htmlText   = params.htmlText ?? '';
                this.buttonText = params.buttonText ?? 'OK'
            }
        },
        mounted() {
            window.eventBus.$on('myAlertView', this.show);
        },
        destroyed() {
            window.eventBus.$off('myAlertView', this.show);
        }
    }
</script>

<style lang="scss">
    .my-alert-bg{
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999999999999;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.4);
        padding: 15px;
        .my-alert{
            width:100%;
            max-width: 345px;
            height: auto;
            background-color: white;
            border: 1px solid #CCCCCC;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            -webkit-border-radius: 5px;
            border-radius: 5px;
            .my-alter-header{
                font-weight: bold;
                font-size: 15px;
                color:#002d47;
                padding: 15px;
                border-bottom: 1px solid #CCCCCC;
            }
            .my-alert-main{
                padding: 15px;
                font-size: 15px;
            }
            .my-alert-footer{
                border-top: 1px solid #CCCCCC;
                padding: 15px;
                text-align: center;
            }
        }
    }

</style>