<template>
    <div class="map-box mobile-edit-item mb-4">
        <!--
        <div class="map-box-main mobile-product-item hand row py-4 theme-text-dark" :class="{active:collapse}" @click="toggleCollapse()">

            <div class="col-12 col-sm-7 d-flex justify-content-start align-items-center">
                <i class="fas fa-server theme-text-blue font-size-28 mr-2"/>
                <span class="font-weight-bold">Unit Basis  </span>
            </div>

            <div class="col-12 col-sm-5 d-flex justify-content-start align-items-center mt-3 mt-md-0">
                <span class="font-size-20 font-weight-bold ml-sm-auto mr-5">{{ basisTotal|price }}</span>
            </div>

        </div>
        -->
        <BasketItemHeader :headerValues="{collapse, allCount, goTo:'samenstellen', icon:'fa-server', basketType:'basis', basket:listItems.basis, total:basisTotal, title:'Unite basis gebruiker'}" v-on:toggleCollapse="toggleCollapse" v-if="viewHeader"/>
        <div class="product-list" v-if="collapse">
            <div class="basket-item pl-0 pl-md-5" v-for="i in basketItem.orderCount" :key="'basis_item_'+i">
                <div class="container">
                    <div class="basket-list-item row">
                        <div class="item-name col-12 col-md-6 mb-2 mb-md-0">Gebruiker</div>
                        <div class="col-12 col-md-6 text-right">
                            <span>{{ perPrice|price }} p.m.</span>
                            <i class="fa fa-trash hand ml-1 ml-md-5" @click="removeProduct(i)" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Abstract_wilkelmand} from "../../mixins/Abstract_wilkelmand";
    import BasketItemHeader from "./BasketItemHeader";
    export default {
        name: "BasisBasketList",
        mixins:[Abstract_wilkelmand],
        components:{ BasketItemHeader },
        props:{
          viewHeader:{
            type: Boolean,
            required:false,
            default:true
          }
        },
        data(){
            return {

            }
        },
        computed:{
            perPrice(){
                //yeniden hesaplama yerine var olan fiyati kullanıcı sayısına bol
                return this.basisTotal / this.basketItem.orderCount;
            },
            allCount(){
                try{
                    return this.basketItem.orderCount;
                }catch (e) {
                    return '';
                }
            }
        },
        methods:{
            removeProduct(){
                this.$root.removeToBasket(this.basketItem.id, 1);
                this.calculateBasis(this.basketItem);
                this.$root.appEvents.emit('basket-re-calculate');
                this.$root.appEvents.emit('change_basket', this.basket);
            }
        },
        mounted() {
            if(this.viewHeader === false){
              this.collapse = true;
            }
            this.calculateBasis(this.basketItem);
        }

    }
</script>

<style scoped>

</style>