<template>
    <div class="card-harware-list">

      <div class="row text-left" v-for="(opt,i) in basketItem.options" :key="'hardware_item_'+i">
        <div class="col-6 col-sm-8 pr-0 mb-1 d-flex align-items-center">
          <i class="fa fa-trash hand mr-1" @click="removeProduct(i)" />
          <span class="mr-1">{{ opt.orderCount }}x</span>
          <span>{{ productName(i) }}</span>
        </div>
        <div class="col-3 col-sm-2 pr-0 d-flex align-items-center justify-content-end"><span class="text-nowrap">{{ $root.productSetupPrice(i)|price }}</span></div>
        <div class="col-3 col-sm-2 d-flex align-items-center justify-content-end"><span class="text-nowrap">{{ discountPerPrice(i, opt.orderCount)|price }}</span></div>
      </div>

    </div>
</template>

<script>
    import { Abstract_wilkelmand} from "../../mixins/Abstract_wilkelmand";
    import BasketItemHeader from "./BasketItemHeader";
    export default {
        name: "HardwareBasketList",
        mixins:[Abstract_wilkelmand],
        components:{ BasketItemHeader },
        props:{
          viewHeader:{
            type: Boolean,
            required:false,
            default:true
          }
        },
        methods:{
            perPrice(keyId, orderCount = 1){
                let price = this.$root.products[keyId].price;
                if(orderCount < 1){
                    orderCount = 1;
                }
                return price * orderCount;
            },
            discountPerPrice(i, orderCount){
                return this.$root.setPromotionsProductPrice("oneTime", i, this.perPrice(i, orderCount)).total;
            },
            hasDiscount(i){
                return this.$root.setPromotionsProductPrice("oneTime", i, this.perPrice(i, 1)).hasDiscount;
            },
            removeProduct(keyId){
                try {
                    this.basketItem.options[keyId].orderCount = 0;
                    this.$root.setserverHadware(this.basketItem.options[keyId]);
                }catch (e) {}

                delete this.basketItem.options[keyId];

                if(Object.keys(this.basketItem.options).length < 1){
                    delete this.$root.basket['hardware'];
                }
                else{
                    this.calculateHardware(this.basketItem);
                }

                this.$root.appEvents.emit('basket-re-calculate');
                this.$root.appEvents.emit('change_basket', this.basket);
            },
            productName(keyId){
                try{
                    return this.$root.products[keyId].name;
                }catch (e) {
                    return '';
                }
            },
            lastCookieChanged(manuel){
                if(manuel !== true){
                    //this.$root.infoLog("product basket events");
                    this.calculateHardware(this.basketItem, true);
                }
            },
            allCount(){
                let t = 0;
                try{
                    return this.listItems.hardware.count;
                }catch (e) {
                    return Object.keys(this.basketItem.options).length;
                }
            }
        },
        mounted() {
            if(this.viewHeader === false){
              this.collapse = true;
            }
            this.calculateHardware(this.basketItem);
            this.$root.appEvents.on('hardware-re-calculate', this.lastCookieChanged);
        },
        destroyed() {
            this.$root.appEvents.removeListener('hardware-re-calculate', this.lastCookieChanged);
        }
    }
</script>

<style scoped>

</style>