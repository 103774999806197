<template>
    <div class="item" v-if="isProduct">
        <div class="hardware-product px-md-5">
            <div class="device-photo">
                <img :src="pictureUrl" :alt="product.name">
            </div>
            <div class="device-info mb-3">
                <h3 class="device-title">{{ product.name }}</h3>
                <ul class="device-props">
                    <li class="text-truncate" v-if="hasProperties" v-for="pItem in properties">{{ pItem }}</li>
                </ul>
                <div class="hw-price-list">
                    <div class="" v-if="hasDiscount">
                        <div class="bottom-price d-flex align-items-center justify-content-end mr-5" v-if="hasDiscount">
                            <div class="old-price font-weight-bold text-nowrap mr-2">{{ product.price|price }}</div>
                            <div class="new-price hw-new-price font-weight-bold text-nowrap font-size-20">{{ discountPrice|price }}</div>
                        </div>
                    </div>
                    <span class="hw-new-price" v-else>{{ product.price|price }}</span>
                </div>
            </div>
            <div class="hw-basket-action d-flex justify-content-center align-items-center">
                <button class="btn btn-icon-border up-btn" :disabled="minusDisable" @click="decreaseProduct()">
                    <i class="fal fa-minus"/>
                </button>
                <div class="circle-progress hidden-percent mx-2" :id="'chart_'+product.id">
                    <div class="hw-counter-text" :class="addMe">
                        <span class="gebruiker-count" :id="product.type">{{ basketCount }}</span>
                    </div>
                    <canvas :id="'chart_canvas_'+product.id"/>
                </div>
                <button class="btn btn-icon-border down-btn" @click="increaseProduct()">
                    <i class="fal fa-plus"/>
                </button>
            </div>
            <div class="hw-is-added mt-3">
                <span class="added" v-if="basketCount > 0"><i class="fa fa-check"/> Toegevoegd</span>
                <span class="no-added" v-else>Selecteer toestel</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "HardwareProductItem",
        props:{
            productId:{
                type:[Number, String],
                required: true
            }
        },
        data(){
            return {
                chart:null,
                chart_canvas:null,
                chart_size:45,
                chart_line:4,
                chart_rotate:5,
            }
        },
        computed:{
            product(){
                return this.$root.products[this.productId];
            },
            isProduct(){
                if(this.product){
                    if(typeof this.product !== "undefined"){
                        return true;
                    }
                }
                return false;
            },
            hasDiscount(){
                return this.$root.setPromotionsProductPrice("oneTime", this.product.id, this.product.price).hasDiscount;
            },
            discountPrice(){
                return this.$root.setPromotionsProductPrice("oneTime", this.product.id, this.product.price).total;
            },
            basketCount(){
                try {
                    return parseInt(this.$root.basket.hardware.options[this.productId].orderCount);
                }catch (e) {
                    return 0;
                }
            },
            properties(){
                try{
                    return this.$root.productTypes.hardware.properties[this.productId].props;
                }
                catch (e) {
                    try{
                        return this.product.feature_list;
                    }
                    catch (e) {}
                    return {}
                }
            },
            hasProperties(){
                return Object.keys(this.properties).length > 0;
            },
            pictureUrl(){
                try {
                    if (this.product.hasOwnProperty("val_picturepath")) {
                        return this.$root.requestUrl + '/viewimage.php?image=' + this.product.val_picturepath;
                    }
                }catch (e) {}
                return 'https://www.voipzeker.nl/templates/Voipzeker/contentsLast/computer/images/emptyfoto2.jpg';
            },
            minusDisable(){
                return this.basketCount < 1;
            },
            addMe(){
                if(this.basketCount > 0){
                    return {};
                }
                return {
                    animate__animated:true,
                    animate__pulse:true,
                    animate__infinite:true
                }
            },
            chart_color1(){ return '#E7E7E7'; },
            chart_color2(){
                return this.basketCount > 0 ?  '#82B636' : this.chart_color1;
            },
            chart_percent(){
                //return this.productCount * 2;
                return (100 * ( Math.sin(Math.PI * ( (0.9 * this.basketCount) / 100 ) ) ));
            }
        },
        watch:{
            chart_percent(val) {
                this.drawCircle(this.chart_color1, 100 / 100);
                this.drawCircle(this.chart_color2, val / 100);
            }
        },
        methods:{
            decreaseProduct(){
                this.$root.basket.hardware.options[this.productId].orderCount -= 1;
                if(this.$root.basket.hardware.options[this.productId].orderCount < 1){
                    delete this.$root.basket.hardware.options[this.productId];
                }
                if(Object.keys(this.$root.basket.hardware.options).length < 1){
                    delete this.$root.basket.hardware;
                }
                this.$root.appEvents.emit('basket-re-calculate');
                this.$root.appEvents.emit('change_basket', this.basket);
            },
            increaseProduct(){
                let tmp = {};
                if(this.basketCount >= 50){
                    this.$myAlert({
                        title:'Maximale afname bereikt',
                        text:'Voor dit product is online een afname van maximaal 50 stuks toegestaan. Meer nodig? Bel ons op 088 0660 550',
                    });
                    return;
                }
                if(!this.$root.basket.hasOwnProperty('hardware')){
                    this.$root.basket.hardware = {};
                    this.$root.basket.hardware.formType      = 'hardware';
                    this.$root.basket.hardware.options      = {};
                    this.$root.basket = Object.assign({}, this.$root.basket); /* required reactive */
                }
                if(!this.$root.basket.hardware.options.hasOwnProperty(this.productId)){
                    this.$root.basket.hardware.options[this.productId] = {};
                    this.$root.basket.hardware.options[this.productId].id = this.productId;
                    this.$root.basket.hardware.options[this.productId].orderCount = 0;
                    this.$root.basket.hardware.options = Object.assign({}, this.$root.basket.hardware.options); /* required reactive */
                }
                this.$root.basket.hardware.options[this.productId].orderCount += 1;

                tmp = Object.assign(tmp, this.$root.basket.hardware.options[this.productId]);

                this.$set(this.$root.basket.hardware.options, this.productId, tmp); /* required reactive */
                this.$root.appEvents.emit('basket-re-calculate');
                this.$root.appEvents.emit('change_basket', this.basket);
            },
            drawCircle(color, pecent) {
                //this.chart_percent = Math.min(Math.max(0, this.chart_percent || 1), 1);
                this.chart._c_ctx.beginPath();
                this.chart._c_ctx.arc(0, 0, this.chart._c_radius, 0, Math.PI * 2 * pecent, false);
                this.chart._c_ctx.strokeStyle = color;
                this.chart._c_ctx.lineCap = 'round'; // butt, round or square
                this.chart._c_ctx.lineWidth = this.chart_line;
                this.chart._c_ctx.stroke();
            },
            initCircle(){
                this.chart          = document.getElementById('chart_'+this.productId);
                this.chart_canvas   = document.getElementById('chart_canvas_'+this.productId);

                if (typeof(G_vmlCanvasManager) !== 'undefined') {
                    G_vmlCanvasManager.initElement(this.chart_canvas);
                }
                this.chart._c_ctx = this.chart_canvas.getContext('2d');
                this.chart_canvas.width = this.chart_canvas.height = this.chart_size;
                this.chart._c_ctx.translate(this.chart_size / 2, this.chart_size / 2); /* change center*/
                this.chart._c_ctx.rotate((-1 / 2 + this.chart_rotate / 180) * Math.PI); /* rotate -90 deg */
                this.chart._c_radius = (this.chart_size - this.chart_line) / 2;

                this.drawCircle(this.chart_color1, 100 / 100);
                this.drawCircle(this.chart_color2, this.chart_percent / 100);
            }
        },
        created() {

        },
        mounted() {
            try{
                this.initCircle();
            }catch (e) {}


            let evt = new CustomEvent("reinit_owl_carousel", {});
            document.dispatchEvent(evt);
        }
    }
</script>

<style lang="scss">
    .hardware-product{
        background: #FFFFFF;
        border: 1px solid #DDDDDD;
        box-sizing: border-box;
        border-radius: 10px;
        text-align: center;
        padding: 15px 15px 30px 15px;
        .device-photo{
            margin-bottom: 15px;
            img{
                width: auto;
                height: 112px;
                margin: 0 auto;
            }
        }
        .device-info{
            .device-title{
                font-size: 16px;
                color:#002d47;
                font-weight: bold;
                padding: 0;
                margin: 0 0 15px 0;
                height: 40px;
                overflow: hidden;
            }
            .device-props{
                padding: 0;
                margin:0 auto 15px auto;
                list-style: none;
                text-align: left;
                height: 108px;
                width: 100%;
                max-width: 330px;
                overflow-y: hidden;
                &.elastic-props{
                    height: unset;
                    max-height: unset;
                    max-width: unset;
                    overflow-y: unset;
                }
                li{
                    font-size: 14px;
                    color: #5E6575;
                    margin-bottom: 8px;
                    padding-left: 16px;
                    position: relative;
                    &:after{
                        font-family: 'Font Awesome 5 Pro';
                        font-weight: 400;
                        position: absolute;
                        font-size: 13px;
                        left: 0;
                        top: 1px;
                        content: "\f00c";
                        color: #8EB44B;
                    }
                }
            }
            .hw-price-list{
                .hw-old-price{
                    color: #B8B8B8;
                    font-size: 13px;
                    text-decoration: line-through;
                }
                .hw-new-price{
                    font-weight: 600;
                    font-size: 20px;
                    color: #002D47;
                    small{
                        font-size: 13px;
                    }
                }
                .vat-text{
                    font-size: 10px;
                    line-height: 12px;
                    color: #B8B8B8;
                }
            }
        }
        .hw-basket-action{
            .hw-counter-text{
                text-align: center;
                line-height: 45px;
                color: #102B44;
                font-weight: bold;
                font-size: 14px;
            }
            /********/
            .circle-progress{
                position:relative;
                width: 45px;
                height: 45px;
            }
            .circle-progress canvas {
                display: block;
                position:absolute;
                top:0;
                left:0;
                z-index: 1;
            }
            /**********/
        }
        .hw-is-added{
            font-size: 14px;
            .added{
                color: #8EB44B;
                font-weight: bold;
            }
            .no-added{
                color: #5E6575;
            }
        }
    }
</style>