<template>
    <div class="funnel-steps" data-step-number="3">
        <div class="step-container d-flex">
            <div class="step flex-fill d-flex align-items-center active">
                <a href="javascript:void(0);" class="d-block" style="width: 95%;" @click="$root.gotoHome()">
                    <span>{{ step1DynText }}</span>
                </a>
                <div class="right-arrow"></div>
            </div>
            <div class="step flex-fill d-flex align-items-center" :class="step2css">
                <a href="javascript:void(0);" class="d-block" style="width: 95%;" @click="clicTo2Step()">
                    <span>{{ step2DynText }}</span>
                </a>
                <div class="left-arrow"></div>
                <div class="right-arrow"></div>
            </div>
            <div class="step flex-fill d-flex align-items-center" :class="step3css">
                <a href="javascript:void(0);" class="d-block" style="width: 95%;" @click="clicTo3Step()">
                  <span>{{ step3DynText }}</span>
                </a>
                <div class="left-arrow"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PageSteps",
        props:{
            pageInfo:{
                type: Object,
                default:function () {
                    return {
                        prevUrl:'javascript:void(0);',
                    }
                }
            },
            text1:{
                type: String,
                default:'Selecteer gebruikers'
            },
            text1Mobile:{
                type: String,
                default:'Gebruikers'
            },
            text2:{
                type: String,
                default:'Selecteer telefoonnummers'
            },
            text2Mobile:{
                type: String,
                default:'Nummers'
            },
            text3:{
                type: String,
                default:'Afronden'
            },
            text3Mobile:{
                type: String,
                default:'Afronden'
            },
        },
        data(){
            return {
              step2css:'not-select',
              step3css:'not-select',
            }
        },
        computed:{
            twoStepCss(){
                if(this.$root.cartPageIsDisplayed){
                    return 'active';
                }
                return this.step2css;
            },
            step1DynText(){
              let tetx = this.responsiveText({normal:this.text1, mobile: this.text1Mobile, num:1});
              if(!this.isActive(this.step2css)){
                if(tetx === "1"){
                  tetx = this.text1Mobile;
                }
              }
              return tetx;
            },
            step2DynText(){
              let tetx = this.responsiveText({normal:this.text2, mobile: this.text2Mobile, num:2});
              if(this.isActive(this.step2css) && !this.isActive(this.step3css)){
                if(tetx === "2"){
                  tetx = this.text2Mobile;
                }
              }
              return tetx;
            },
            step3DynText(){
              let tetx = this.responsiveText({normal:this.text3, mobile: this.text3Mobile, num:3});
              if(this.isActive(this.step3css)){
                if(tetx === "3"){
                  tetx = this.text3Mobile;
                }
              }
              return tetx;
            },
        },
        methods:{
            clicTo2Step(){
                if(this.$root.hasGebruiker){
                    this.$root.gotoPage("telefoonummers");
                }
            },
            clicTo3Step(){
              if(this.$root.cartPageIsDisplayed && this.$root.hasGebruiker && this.$root.hasNumber){
                this.$root.gotoPage("winkelmand");
              }
            },
            isActive(css){
                return css.toString().indexOf('active') > -1;
            },
            setStepNumber(number){
              if(number === 2){
                  this.step2css = 'active';
                  this.step3css = 'not-select';
              }else if(number === 3){
                  this.step2css = 'active';
                  this.step3css = 'active';
              }else{
                  this.step2css = 'not-select';
                  this.step3css = 'not-select';
              }
            },
            responsiveText(textGroup){
                let text = textGroup.num.toString()+ " "+textGroup.normal;
                if(this.$root.winWidth < 992 && this.$root.winWidth > 767)
                {
                  text = textGroup.mobile;
                }
                else if(this.$root.winWidth < 767 && this.$root.winWidth > 575)
                {
                  text = textGroup.num.toString();
                }
                else if(this.$root.winWidth < 576)
                {
                  text = textGroup.num.toString();
                }

                return text;
            }
        },
        mounted() {
            let that = this;
            that.$root.appEvents.on("page.step", function (e) {
                that.setStepNumber(e.step);
            });
        }
    }
</script>

<style lang="scss">
    .funnel-steps{
        margin: 0 0 35px 0;
        .step {
            background-color: #FAFAFA;
            border: 1px solid #DDDDDD;
            margin: 0 5px;
            height: 47px;
            font-weight: 500;
            font-size: 15px;
            position: relative;
            *{color: #8794A0;}
            &.active{
                background-color: #102B44;
                font-weight: bold;
                *{color: #ffffff;}
            }
            &:first-child{
                -webkit-border-radius: 5px 0 0 5px;
                border-radius: 5px 0 0 5px;
                margin-left: 0;
                a{
                    padding-left: 15px !important;
                }
            }
            &:last-child{
                -webkit-border-radius: 0 5px 5px 0;
                border-radius: 0 5px 5px 0;
                margin-right: 0;
            }
            .left-arrow, .right-arrow{
                border-top: 1px solid #DDDDDD;
                border-right: 1px solid #DDDDDD;
                position: absolute;
                -moz-transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
                -o-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
                -webkit-border-radius: 0 5px 0 0;
                border-radius: 0 5px 0 0;
                z-index: 1;
            }
            .left-arrow{
                background-color: #ffffff;
                height: 33px;
                width: 33px;
                left: -17.5px;
                top: 6px;
                z-index: 1;
            }
            .right-arrow{
                background-color: #FAFAFA;
                height: 33px;
                width: 33px;
                right: -17.5px;
                top: 6px;
                z-index: 5;
            }
            &.active .right-arrow{
                background-color: #102B44;
            }
            a{
                display: block;
                padding-left: 35px;
                position: relative;
                z-index: 10;
            }
        }
    }
</style>