<template>
    <div class="sim-product" v-if="viewReady">
        <div class="map-box-main p-3 p-md-5">
            <div class="owl-carousel product-owl owl-theme" :id="'ovlcarousel-'+pType"
                 data-owl-carousel
                 data-owl-dots="true"
                 data-owl-nav="true"
                 data-dot-class="owl-dot dark-dot"
                 data-owl-items="2"
                 data-owl-loop="false"
                 data-owl-autoplay="false"
                 data-owl-autoplay-timeout="2000"
                 data-owl-autoplay-speed="2000"
                 data-owl-margin="40"
                 data-rwd="1-1-2-3-3">
                <MobileProduct v-for="(subProduct, key) in products" :key="'mobile_'+subProduct.id" :pType="pType" :product="subProduct" />
            </div>
        </div>
    </div>
</template>

<script>
    import dcollect from 'dcollect';
    import MobileProduct from "./MobileProduct";
    //import MobileProductInfoList from "./MobileProductInfoList";
    export default {
        name: "MobileProductContainer",
        components:{MobileProduct},
        props:{
            pType:{
                type: String,
                require: true
            }
        },
        data(){
            return {
                productIDs:[],
                products:[],
                viewReady:false
            }
        },
        computed:{

        },
        watch:{

        },
        methods:{

        },
        created() {
            let that = this;
            let tempProduct;
            let collect;

            //readyVastOpMobielProducts
            that.$root.getVastOpMobielProducts(function () {
                that.productIDs = that.$root.productTypes[that.pType].id;
                tempProduct   = that.$root.getProducts(that.pType);
                if(Array.isArray(tempProduct)){
                    collect = new dcollect(tempProduct);
                    that.products = collect.query().rawFilter(function (item) {
                        item.sortno = parseInt(item.sortno);
                        return true;
                    }).orderBy("sortno","asc").get();
                }
                that.viewReady = true;
            });
        },
        mounted() {

        }
    }
</script>

<style lang="scss">
    .mobile-produc-selector{
        .btn-icon-border{
            padding: 0;
            margin: 0;
            line-height: 38px;
            text-align: center;
        }
        .gebruikers-text{
            .data-size, .data-text{
                margin: 0;
                padding: 0;
                font-size: 16px;
                line-height: 19px;
                text-align: center;
                color: #102B44;
            }
            .data-size{
                /*font-size: 28px;*/
                font-weight: bold;
                line-height: 41px;
                text-align: center;
                color: #102B44;
            }
        }
    }
</style>