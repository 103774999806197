<template>
    <div class="item">
        <div class="hardware-product px-md-5">
            <div class="device-photo">
                <img :src="pictureUrl" :alt="product.name">
            </div>
            <div class="device-info mb-3">
                <h3 class="device-title">{{ product.name }}</h3>
                <ul class="device-props">
                    <li class="text-truncate" v-for="pItem in properties">{{ pItem }}</li>
                </ul>
                <div class="hw-price-list">
                    <!--<span class="hw-old-price">{{ product.old_price|price }}</span>-->
                    <div class="" v-if="hasDiscount">
                        <div class="bottom-price d-flex align-items-center justify-content-end mr-5" v-if="hasDiscount">
                            <div class="old-price font-weight-bold text-nowrap mr-2">{{ price|price }}</div>
                            <div class="new-price hw-new-price font-weight-bold text-nowrap font-size-20">{{ discountPrice|price }} <small>/mnd</small></div>
                        </div>
                    </div>
                    <span class="hw-new-price" v-else>{{ price|price }} <small>/mnd</small></span>
                    <!--<span class="vat-text">excl. btw</span>-->
                </div>
            </div>
            <div class="hw-basket-action d-flex justify-content-center align-items-center">
                <button class="btn btn-icon-border up-btn" :disabled="minusDisable" @click="decreaseProduct()">
                    <i class="fal fa-minus"/>
                </button>
                <div class="circle-progress hidden-percent mx-2" :id="'chart_'+product.id">
                    <div class="hw-counter-text" :class="addMe">
                        <span class="gebruiker-count" :id="product.type">{{ basketCount }}</span>
                    </div>
                    <canvas :id="'chart_canvas_'+product.id"/>
                </div>
                <button class="btn btn-icon-border down-btn" @click="increaseProduct()">
                    <i class="fal fa-plus"/>
                </button>
            </div>
            <div class="hw-is-added mt-3">
                <span class="added" v-if="basketCount > 0"><i class="fa fa-check"/> Toegevoegd</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "MobileProduct",
        props: {
            product: {
                type: Object,
                require: true
            },
            pType: {
                type: String,
                require: true
            }
        },
        data(){
            return {
                chart:null,
                chart_canvas:null,
                chart_size:45,
                chart_line:4,
                chart_rotate:5,
            }
        },
        computed: {
            basketCount(){
                try{
                    return this.$root.basket[this.pType].options[this.product.id].orderCount
                }
                catch (e) {//just vamo or only sim came, It will explode because it is not one
                    return 0
                }
            },
            minusDisable(){
                return this.basketCount < 1;
            },
            hasProperties(){
                try{
                    if(Array.isArray(this.product.feature_list)){
                        return this.product.feature_list.length > 0;
                    }
                    else{
                        return Object.keys(this.product.feature_list).length > 0;
                    }
                }
                catch (e) {
                    return false;
                }
            },
            properties() {
                if (this.hasProperties) {
                    return this.product.feature_list;
                }
                return ["3 apparaat toegestaan", "500 minuten nationaal"];
            },
            price() {
                return this.$root.productPrice(this.product.id, 1);
            },
            discountPrice(){
                return this.$root.setPromotionsProductPrice("monthly", this.product.id, this.price).total;
            },
            hasDiscount(){
                return this.$root.setPromotionsProductPrice("monthly", this.product.id, this.price).hasDiscount;
            },
            pictureUrl(){
                return this.$root.productPicture(this.product);
                // try {
                //     if (this.product.hasOwnProperty("val_picturepath")) {
                //         return this.$root.requestUrl + '/viewimage.php?image=' + this.product.val_picturepath;
                //     }
                // }catch (e) {}
                // return 'https://www.voipzeker.nl/templates/Voipzeker/contentsLast/computer/images/emptyfoto2.jpg';
            },
            addMe(){
                if(this.basketCount > 0){
                    return {};
                }
                return {
                    animate__animated:true,
                    animate__pulse:true,
                    animate__infinite:true
                }
            },
            chart_color1(){ return '#E7E7E7'; },
            chart_color2(){
                return this.basketCount > 0 ?  '#82B636' : this.chart_color1;
            },
            chart_percent(){
                //return this.productCount * 2;
                return (100 * ( Math.sin(Math.PI * ( (0.9 * this.basketCount) / 100 ) ) ));
            }
        },
        watch:{
            chart_percent(val) {
                this.drawCircle(this.chart_color1, 100 / 100);
                this.drawCircle(this.chart_color2, val / 100);
            }
        },
        methods:{
            decreaseProduct(){
                let tmpOption;
                this.$root.basket[this.pType].options[this.product.id].orderCount -= 1;

                if(this.$root.basket[this.pType].options[this.product.id].orderCount < 1){ //urun sayisi yok ise usrunu sepetten sil
                    delete this.$root.basket[this.pType].options[this.product.id];
                    if(Object.keys(this.$root.basket[this.pType].options).length < 1){  //urun sepette ürün secenekleri yok ise sepeti sil
                        delete this.$root.basket[this.pType];
                        this.$root.basket = Object.assign({}, this.$root.basket);
                    }else{
                        this.$root.basket[this.pType].orderCount = Object.keys(this.$root.basket[this.pType].options).length;
                        tmpOption = Object.assign({}, this.$root.basket[this.pType].options);
                        this.$set(this.$root.basket[this.pType], 'options', tmpOption);
                    }
                }
                else{
                    let info = this.$root.basket[this.pType].options[this.product.id].info;

                    this.$root.basket[this.pType].options[this.product.id].info = info.slice(0, this.$root.basket[this.pType].options[this.product.id].orderCount); //urun sayisi kadar info olsun
                    tmpOption = Object.assign({}, this.$root.basket[this.pType].options);
                    this.$set(this.$root.basket[this.pType], 'options', tmpOption);
                }

                this.$root.appEvents.emit('change_basket', this.basket);
                this.$root.appEvents.emit('basket-re-calculate');
            },
            increaseProduct(){
                let optionPath;

                if(this.basketCount >= 50){
                    this.$myAlert({
                        title:'Maximale afname bereikt',
                        text:'Voor dit product is online een afname van maximaal 50 stuks toegestaan. Meer nodig? Bel ons op 088 0660 550',
                    });
                    return;
                }

                if(!this.$root.basket.hasOwnProperty(this.pType)){
                    this.$root.basket[this.pType]               = {};
                    this.$root.basket[this.pType].formType      = this.pType;
                    this.$root.basket[this.pType].options       = {};
                    this.$root.basket[this.pType].orderCount    = 0;
                    this.$root.basket = Object.assign({}, this.$root.basket); /* required reactive */
                }

                if(!this.$root.basket[this.pType].options.hasOwnProperty(this.product.id)){
                    this.$root.basket[this.pType].options[this.product.id] = {
                        id: this.product.id,
                        orderCount:0,
                        info:[]

                    };
                    this.$root.basket[this.pType].options = Object.assign({}, this.$root.basket[this.pType].options); /* required reactive */
                }

                this.$root.basket[this.pType].options[this.product.id].orderCount += 1;

                optionPath = this.$root.basket[this.pType].options;

                if(optionPath[this.product.id].info.length < optionPath[this.product.id].orderCount) { //info alanlarini esitle

                    let diff = optionPath[this.product.id].orderCount - optionPath[this.product.id].info.length;

                    for (let x = 0; x < diff; x++) {
                        optionPath[this.product.id].info.push({
                            useDijitalSim: false,
                            useTransfer: false,
                            transferNumber: '',
                            formatterTransferNumber:'',
                            internationalTransferNumber:'',
                            isValidTransferNumber:false,
                        });
                    }
                }

                this.$root.basket[this.pType].orderCount = Object.keys(optionPath).length;
                this.$root.basket[this.pType].options    = Object.assign(this.$root.basket[this.pType].options, optionPath);

                this.$root.appEvents.emit('change_basket', this.basket);
                this.$root.appEvents.emit('basket-re-calculate');
            },
            drawCircle(color, pecent) {
                //this.chart_percent = Math.min(Math.max(0, this.chart_percent || 1), 1);
                this.chart._c_ctx.beginPath();
                this.chart._c_ctx.arc(0, 0, this.chart._c_radius, 0, Math.PI * 2 * pecent, false);
                this.chart._c_ctx.strokeStyle = color;
                this.chart._c_ctx.lineCap = 'round'; // butt, round or square
                this.chart._c_ctx.lineWidth = this.chart_line;
                this.chart._c_ctx.stroke();
            },
            initCircle(){
                this.chart          = document.getElementById('chart_'+this.product.id);
                this.chart_canvas   = document.getElementById('chart_canvas_'+this.product.id);

                if (typeof(G_vmlCanvasManager) !== 'undefined') {
                    G_vmlCanvasManager.initElement(this.chart_canvas);
                }
                this.chart._c_ctx = this.chart_canvas.getContext('2d');
                this.chart_canvas.width = this.chart_canvas.height = this.chart_size;
                this.chart._c_ctx.translate(this.chart_size / 2, this.chart_size / 2); /* change center*/
                this.chart._c_ctx.rotate((-1 / 2 + this.chart_rotate / 180) * Math.PI); /* rotate -90 deg */
                this.chart._c_radius = (this.chart_size - this.chart_line) / 2;

                this.drawCircle(this.chart_color1, 100 / 100);
                this.drawCircle(this.chart_color2, this.chart_percent / 100);
            }
        },
        mounted() {
            try{
                this.initCircle();
            }catch (e) {}
            let evt = new CustomEvent("reinit_owl_carousel", {});
            document.dispatchEvent(evt);
        }
    }
</script>

<style lang="scss">

</style>