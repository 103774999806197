const MapManager = {
    data(){
        return {
            allMapIndexes:[],
            currentMap:{},
            mapIndex:'',
            defaultMapIndex:'home',
            whereAmI:{},
        }
    },
    methods:{
        gotoPage(mapSubIndex, opt={}){

            try{if(this.$route.params.sub === mapSubIndex){
                return;
            }}catch (e) {}



            if(Object.keys(this.currentMap).includes(mapSubIndex)){
                this.$router.push({name:'subPage', params:Object.assign({map:this.mapIndex, sub:mapSubIndex}, opt) });
            }
            else{
                this.$router.push({name:'mainPage', params:Object.assign({map:this.mapIndex}, opt)});
            }
            this.$root.appEvents.emit('route-changed', mapSubIndex);
        },
        gotoNextPage(currentSubIndex, nextIndex = 0){
            let that = this;
            let currenLoop = 0, lastLoop = 10;
            let sTimer = null;
            // console.log(this.nextPageId(currentSubIndex, nextIndex));
            if(this.$root.mapLoading) {
                this.gotoPage(this.nextPageId(currentSubIndex, nextIndex));
            }
            else{
                sTimer = setInterval(function () {
                    if(that.$root.mapLoading){
                        clearInterval(sTimer);
                        that.gotoPage(that.nextPageId(currentSubIndex, nextIndex));
                    }
                    currenLoop += 1;
                    if(currenLoop >= lastLoop){
                        clearInterval(sTimer);
                        that.gotoHome();
                    }
                },500);
            }
        },
        gotoPreviousPage(currentSubIndex){
            let that = this;
            let currenLoop = 0, lastLoop = 10;
            let sTimer = null;
            if(this.$root.mapLoading) {
                this.gotoPage(this.previousPageId(currentSubIndex));
            }else{
                sTimer = setInterval(function () {
                    if(that.$root.mapLoading){
                        clearInterval(sTimer);
                        that.gotoPage(that.previousPageId(currentSubIndex));
                    }
                    currenLoop += 1;
                    if(currenLoop >= lastLoop){
                        clearInterval(sTimer);
                        that.gotoHome();
                    }
                },500);
            }
        },
        /**
         * async
         */
        gotoHome(){
            let that = this;
            if(this.$root.mapLoading){
                if(this.mapIndex.length < 1){
                    if(this.$route.params.hasOwnProperty('map')){
                        this.mapIndex = this.$route.params.map;
                    }
                    else{
                        this.mapIndex = this.defaultMapIndex;
                    }
                }
                this._gotoHome();
            }
            else{
                 this.mapInit().then(function () {
                     that._gotoHome();
                 });
            }
        },
        _gotoHome(){
            if(!this.isMapIndex(this.mapIndex)){
                this.mapIndex = this.defaultMapIndex;
            }
            try {
                if (this.currentMap.hasOwnProperty("home")) {
                    if(this.currentMap.home !== null){
                        this.gotoPage(this.currentMap.home);
                        return;
                    }
                }
            }catch (e) {}


            this.$router.push({name:'mainPage', params:{map:this.mapIndex}});
            this.$root.appEvents.emit('route-changed', 'home');
        },
        availableIndex(index){
            //return index.replace(/\-/g, '_');
            return this.$options.filters.mapIndex(index);
        },
        /**
         * a valid map key
         * @param index
         */
        isMapIndex(index){
            return this.allMapIndexes.includes(
                this.availableIndex(index)
            );
        },

        getMapIndexInfo(index){
            if(!this.availableIndex(index)){
                return null;
            }
            try{
                return this.currentMap[this.availableIndex(index)];
            }catch (e) {
                return null;
            }
        },

        /**
         * change the main map key and get the relevant map information on the server
         * @param index
         */
        setMapIndex(index){
            if(this.isMapIndex(index)){
                this.mapIndex = this.availableIndex(index);
                this.requestMap(this.mapIndex);
            }else{
                this.mapIndex = this.defaultMapIndex;
                this.requestMap(this.mapIndex);
            }
        },

        nextPageId(currentId, goto=0){
            if(this.currentMap.hasOwnProperty(currentId)){
                return this.currentMap[currentId].next[goto];
            }
            else{
                return this.mapIndex;
            }
        },
        previousPageId(currentId){
            if(this.currentMap.hasOwnProperty(currentId)){
                return this.currentMap[currentId].prev;
            }
            else{
                return this.mapIndex;
            }
        },
        hasprevious(curentSubIndex){
            try{

                if(this.currentMap[curentSubIndex].prev !== null){
                    return true;
                }

            }catch (e) {}

            return false;
        },
        requestMap(index){
            let that = this;
            return this.$root.sendRequest({
                handler:'onGetLandingMap',
                landing_page:this.availableIndex(index)
            },function (http, response) {
                that.currentMap = http.giveAsObject().data;
                that.$root.appEvents.emit("map-loaded");
            });
        },
        prepareMapkeys(){
            let that = this;
            return this.$root.sendRequest({
                handler:'onMapsKeys'
            },function (http, response) {
                that.allMapIndexes = http.giveAsObject().data.keys;
                that.defaultMapIndex = http.giveAsObject().data.default;
            });
        },
        mapInit(){
            let that = this;
            let mapIndex = '';

            return this.prepareMapkeys().then(function (http) {

                if(that.$route.params.hasOwnProperty("map")){
                    if(that.isMapIndex(that.$route.params.map)){
                        mapIndex = that.availableIndex(that.$route.params.map);
                    }
                    else {
                        mapIndex = that.defaultMapIndex;
                    }
                }
                else{
                    mapIndex = that.defaultMapIndex;
                }

                //that.requestMap(that.mapIndex);
                that.setMapIndex(mapIndex);
            });
        },
        documentRouteEvent(mapIndex){
            let evt = new CustomEvent("funnel-changed-page", {
                bubbles:true,
                detail:{mapIndex}
            });
            document.dispatchEvent(evt);
        }
    },
    created(){
        this.mapInit();
    },
    mounted() {
        this.$root.appEvents.on('changed-page', this.documentRouteEvent);
    }
};

export { MapManager }