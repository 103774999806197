<template>
    <div class="loading-screen" :class="addCss" id="loading-screen">
        <div class="animated-loading absolute">
            <div class="loading-container">
                <div class="logo animate__animated animate__pulse animate__infinite"></div>
                <span>{{ $root.loadingText }}</span>
                <div class="loading-bar">
                    <div></div>
                    <div></div>
                </div>
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Loading",
        props:{
            addCss:{
                type: String,
                required: false
            }
        }
    }
</script>

<style scoped>

</style>