<template>
    <div class="extension-list">

        <div class="extension-accordion" v-for="type in extensionTypes">

            <div class="e-accordion-header d-flex align-items-center" :class="{opened:openedDiv===type}">
                <!--<div class="d-flex align-items-center flex-column flex-sm-row w-100 position-relative" @click="setOpnedDiv(type)">-->
                <div class="d-flex align-items-center w-100 position-relative">

                    <div class="d-flex align-items-center w-100">

                        <div style="width: 90px" class="text-center d-none d-sm-inline-block">
                            <img :src="typeImgSrc(type)" :alt="type">
                        </div>

                        <i class="d-inline-block d-sm-none gebruiker-icon" :class="typeIcon(type)" />

                        <div class="ml-2 ex-item-name-wrap w-100 font-size-xs-13">
                            <p class="ex-item-name" :title="typeName(type)">{{ typeName(type) }}</p>
                        </div>
                    </div>

                    <!--<div class="d-block d-md-none mt-2 mt-sm-0"></div>-->


                    <div class="d-flex align-items-center ml-auto" v-if="type != 'desktop'">
                        <!--<i class="fas fa-check-square mr-2 theme-text-blue" />-->
                        <i class="fal fa-check theme-text-green card-delete-btn mr-1 mr-md-3"></i>
                        <div class="d-flex align-items-center justify-content-end" v-if="hasSubProductDiscount">
                          <div class="old-price text-nowrap font-size-12 mr-2">{{ typePrice(type)|price }}</div>
                          <div class="new-price text-nowrap font-weight-bold">{{ typeDiscountPrice(type)|price }}</div>
                        </div>
                        <div class="d-flex align-items-center justify-content-end" v-else>
                          <div class="new-price text-nowrap font-weight-bold mr-2">{{ typePrice(type)|price }}</div>
                          <!--<div class="old-price text-nowrap font-size-12">{{ subProductPrice|price }}</div>-->
                        </div>
                        <!--
                        <div class="custom-control custom-switch d-flex align-items-center ml-auto">
                            <input type="checkbox" class="custom-control-input" v-model="selectedExtensions" :value="type" :id="type+'-'+gebrukerKey">
                            <label class="custom-control-label ex-price-label text-nowrap" :for="type+'-'+gebrukerKey" v-html="subExtensionPrice(type)"></label>
                        </div>
                        -->
                    </div>
                    <div class="d-flex align-items-center ml-auto" v-else>
                        <i class="fal fa-check theme-text-green card-delete-btn mr-1 mr-md-3"></i>
                        <div class="d-flex align-items-center justify-content-end">
                          <div class="new-price text-nowrap font-weight-bold" v-if="hasSubProductDiscount">Inbegrepen</div>
                          <div class="new-price text-nowrap font-weight-bold mr-2" v-else>Inbegrepen</div>
                        </div>
                    </div>

                    <!-- <i class="fas hand ml-auto position-absolute" :class="{'fa-chevron-right':openedDiv !==type, 'fa-chevron-down':openedDiv === type}" style="right:10px; top:calc(50% - 8px);"></i> -->
                </div>

            </div>
            <!--
            <div class="e-accordion-body" :class="{opened:openedDiv===type}">
                <div class="row">
                    <div class="col-md-8 order-1 order-md-0">
                        <span v-html="typeDescription(type)"></span>
                        <div class="clearfix my-4"></div>
                        <ul class="ex-type-props row">
                            <li class="text-truncate col-sm-12 col-md-6" v-for="pItem in typeProps(type)">{{ pItem }}</li>
                        </ul>
                    </div>
                    <div class="col-md-4 order-0 order-md-1 text-center">

                        <div class="owl-carousel product-owl owl-theme"
                             data-owl-carousel
                             data-owl-dots="true"
                             data-owl-nav="false"
                             data-dot-class="owl-dot dark-dot"
                             data-owl-items="1"
                             data-owl-loop="true"
                             data-owl-autoplay="true"
                             data-owl-autoplay-timeout="5000"
                             data-owl-autoplay-speed="2000"
                             data-owl-margin="0"
                             data-rwd="1-1-1-1-1" v-if="typeImgsSrc(type).length > 1">
                            <div class="item" v-for="(item, index) in typeImgsSrc(type)" :class="{active:index===0}">
                                <img :src="item" class="d-block w-100" alt="...">
                            </div>
                        </div>
                        <img :src="typeImgsSrc(type)[0]" :alt="type" class="w-100" v-else>
                    </div>
                </div>
            </div>
            -->
        </div>

    </div>
</template>

<script>
export default {
    name: "GebruikerTypeSelect",
    props:{
        gebrukerKey:[String, Number],
        gebruiker:[Object],
        gebruikerCount:{
          type: Number,
          required:false,
          default:1
        }
    },
    data(){
        return {
            subProduct:{},
            vastProduct: {},
            selectedExtensions:[],
            extensionTypes:[],
            extensionTypesIcon:{},
            openedDiv:'false'
        }
    },
    watch:{
        selectedExtensions: {
            handler: function (newVal, oldVal) {
                this.selectedExtensionType();
            },
            deep: true
        }
    },
    computed:{

        destopImgSrc(){
            return this.$root.productPicture(this.$root.flexProduct);
        },
        mobileImgSrc(){
            return this.$root.productPicture(this.subProduct);
        },
        vastImgSrc(){
            return this.$root.productPicture(this.$root.basisProduct);
        },
        destopImgsSrc(){
            return this.$root.productOtherImages(this.$root.flexProduct);
        },
        mobileImgsSrc(){
            return this.$root.productOtherImages(this.subProduct);
        },
        vastImgsSrc(){
            return this.$root.productOtherImages(this.$root.basisProduct);
        },
        subProductPrice(){
            let price = this.$root.calculateProductPrice(this.subProduct.id, this.gebruikerCount);
            try{
              return price / this.gebruikerCount;
            }catch (e) {
              return 0;
            }
        },
        vastProductPrice(){
          let price = this.$root.calculateProductPrice(this.vastProduct.id, this.gebruikerCount);
          try{
            return price / this.gebruikerCount;
          }catch (e) {
            return 0;
          }
        },
        subProductDiscountPrice(){
          return this.$root.setPromotionsProductPrice("monthly", this.subProduct.id, this.subProductPrice).total;
        },
        vastProductDiscountPrice(){
          return this.$root.setPromotionsProductPrice("monthly", this.vastProduct.id, this.vastProductPrice).total;
        },
        hasSelection(){
            return this.selectedExtensions.length > 0;
        },
        hasSubProductDiscount(){
          return this.$root.setPromotionsProductPrice("monthly", this.subProduct.id, this.subProductPrice).hasDiscount;
        }
    },
    methods:{
        subExtensionPrice(subType){
            if(!this.hasSelection){
                return '&nbsp;';
            }

            if(subType === "desktop"){
                if(this.selectedExtensions.includes(subType)){
                    return 'Inbegrepen';
                }else{
                    return '&nbsp;';
                }
            }

            if(subType === "mobiel"){
                if(this.selectedExtensions.includes("desktop")){
                    if(this.selectedExtensions.includes(subType)){
                        return this.$options.filters.price(this.subProductPrice)+' p.m';
                    }
                }
                if(this.selectedExtensions.includes(subType)){
                    return 'Inbegrepen';
                }else{
                    return '&nbsp;';
                }
            }

            if(subType === "vast"){
                if(this.selectedExtensions.includes("desktop") || this.selectedExtensions.includes("mobiel")){
                    if(this.selectedExtensions.includes(subType)){
                        return this.$options.filters.price(this.subProductPrice)+' p.m';
                    }
                }
                if(this.selectedExtensions.includes(subType)){
                    return 'Inbegrepen';
                }else{
                    return '&nbsp;';
                }
            }
            return '&nbsp;';
        },

        prepareExtraProduct(){
            let that = this;
            that.subProduct = that.$root.flexSubProduct;
            that.vastProduct = that.$root.flexVastProduct;

            Object.entries(that.gebruiker).forEach(function ([item, key]) {
                if(key){
                    that.selectedExtensions.push(item);
                }
            });

            that.extensionTypes = Object.keys(that.$root.productTypes.flex.propsIcons);
            that.extensionTypesIcon = Object.assign(that.$root.productTypes.flex.propsIcons);

            let evt = new CustomEvent("reinit_owl_carousel", {});
            document.dispatchEvent(evt);
        },

        selectedExtensionType(){
            this.$emit("selectedExType", {key:this.gebrukerKey, selected:this.selectedExtensions});
        },

        setOpnedDiv(type){
            if(this.openedDiv === type){
                this.openedDiv = false;
            }
            else{
                this.openedDiv = type;
                if(type){
                    let evt = new CustomEvent("reinit_owl_carousel", {});
                    document.dispatchEvent(evt);
                }
            }
        },

        typeImgSrc(type){
            if(type === 'mobiel'){
                return this.mobileImgSrc;
            }
            if(type === 'vast'){
                return this.vastImgSrc;
            }
            return this.destopImgSrc;
        },

        typeImgsSrc(type){
            if(type === 'mobiel'){
                return this.mobileImgsSrc;
            }
            if(type === 'vast'){
                return this.vastImgsSrc;
            }
            return this.destopImgsSrc;
        },

        typeIcon(type){
            if(this.extensionTypesIcon.hasOwnProperty(type)){
                return this.extensionTypesIcon[type];
            }
            return '';
        },

        typeName(type){
            if(type === 'mobiel'){
                return 'Mobiele app';
            }
            if(type === 'vast'){
                return 'Vast';
            }
            return 'Desktop';
        },

        typeDescription(type){
            try {
                if (type === 'mobiel') {
                    return "" + this.subProduct.description.replace(/\n|\r\n|\r/g, '<br/>');
                }
                if (type === 'vast') {
                    return "" + this.$root.basisProduct.description.replace(/\n|\r\n|\r/g, '<br/>');
                }
                return "" + this.$root.flexProduct.description.replace(/\n|\r\n|\r/g, '<br/>');
            }catch (e) {
                return '';
            }
        },

        typeProps(type){
            let props = [];
            try {
                if (type === 'mobiel') {
                    props = this.subProduct.feature_list;
                } else if (type === 'vast') {
                    props = this.$root.basisProduct.feature_list;
                } else {
                    props = this.$root.flexProduct.feature_list;
                }
            }catch (e) {}
            return props;
        },
      typePrice(type){
          let price = 0;
          try{
            if (type === 'mobiel') {
                return this.subProductPrice;
            }else if (type === 'vast') {
                return this.vastProductPrice;
            }
          }catch (e) {}
          return price;
      },
      typeDiscountPrice(type){
        let price = 0;
        try{
          if (type === 'mobiel') {
            return this.subProductDiscountPrice;
          }else if (type === 'vast') {
            return this.vastProductDiscountPrice;
          }
        }catch (e) {}
        return price;
      }
    },
    mounted() {
        this.prepareExtraProduct();
    }
}
</script>

<style lang="scss">
    .ex-price-label{
        display: inline-block;
        width: 110px;
        min-width: 110px;
    }
    .ex-item-name-wrap{
        .ex-item-name{
            margin: 0;
            padding: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
        }
    }
    .ex-type-props{
        margin: 0;
        padding: 0;
        li{
            font-size: 14px;
            color: #5E6575;
            margin-bottom: 8px;
            padding-left: 20px;
            position: relative;
            &:after{
                font-family: 'Font Awesome 5 Pro';
                font-weight: 600;
                position: absolute;
                font-size: 13px;
                left: 0;
                top: 1px;
                content: "\f058";
                color: #8EB44B;
            }
        }
    }
    .gebruiker-icon{
      width: 16px;
    }
</style>