<template>
    <div id="basket-bottom-list" style="margin-bottom: -2px">

        <div class="sepet-list-header" v-if="viewHeader">
          <div class="container pt-4 pb-3 d-flex align-items-center">
              <h3 class="font-weight-bold theme-text-dark m-0 p-0">Je bestelling</h3>
              <i class="fal fa-times hand ml-auto list-close" @click="$root.appEvents.emit('funnel-bottombasket-hide')"/>
          </div>
        </div>

        <div class="container" v-if="itemCounts > 0">

          <div class="row">
            <div class="col-6 col-sm-8"></div>
            <div class="col-3 col-sm-2 pr-0 text-right">
              <span class="d-none d-sm-block">Eenmalig</span>
              <span class="d-block d-sm-none">eenmalig</span>
            </div>
            <div class="col-3 col-sm-2 text-right pl-0">
              <span class="d-none d-sm-block">Maandelijks</span>
              <span class="d-block d-sm-none">p/maand</span>
            </div>
          </div>
        </div>

        <div class="basket-item" v-for="(item, k) in items" :key="'baskey_item_'+k" v-if="itemCounts > 0 && item.count > 0">
            <div class="container border-bottom mb-3 pb-2">
              <div class="item-name font-weight-bold d-flex align-items-center mb-3">
                <span class="theme-text-dark">{{ item.name }}</span>
                <i class="fal fa-cog theme-text-blue hand ml-2" @click="$root.gotoPage(item.goto); $root.appEvents.emit('funnel-bottombasket-hide')" v-if="isGoto(item.goto)" />
              </div>
              <component :basketItem="getBasketItemByList(item)" :totalItem="item" v-if="existComponent(item.type)" :viewHeader="false" v-bind:is="basketListComponentName(item.type)"/>
            </div>
        </div>

      <div class="container border-bottom mb-3 pb-2" v-if="itemCounts > 0">
        <div class="item-name font-weight-bold d-flex align-items-center mb-3">
          <span class="theme-text-dark">Belminuten</span>
        </div>
        <div class="row">
          <div class="col-6 col-sm-8 d-flex flex-column mb-1">
            <span class="font-weight-bold theme-text-green">Inclusief onbeperkt bellen NL</span>
          </div>
          <div class="col-3 col-sm-2 pr-0 text-right text-nowrap">{{ 0|price }}</div>
          <div class="col-3 col-sm-2 text-right pl-0 text-nowrap">{{ 0|price }}</div>
        </div>
      </div>

        <!-- ########################################## -->

        <div class="container border-bottom border2px mb-3 pb-2" v-if="itemCounts > 0">
          <div class="row">
            <div class="col-6 col-sm-8 text-nowrap">
              <span class="font-weight-bold theme-text-dark mr-1">Totaal p.m.</span>
              <span class="font-size-8 font-size-md-12">(excl. BTW)</span>
            </div>
            <div class="col-3 col-sm-2 pr-0 text-right text-nowrap"></div>
            <div class="col-3 col-sm-2 text-right pl-0 text-nowrap">{{ monthlyTotal|price }}</div>
          </div>
        </div>

        <div class="container mb-1 pb-2" v-if="itemCounts > 0">
          <div class="row">
            <div class="col-6 col-sm-8">
              <span>Resterend abonnement {{ allValues.monthlyName}}</span>
            </div>
            <div class="col-3 col-sm-2 text-right text-nowrap"></div>
            <div class="col-3 col-sm-2 text-right pl-0 text-nowrap">{{ allValues.remainingDayPrice|price }}</div>
          </div>
        </div>

        <div class="container mb-1 pb-2" v-if="itemCounts > 0">
          <div class="row">
            <div class="col-6 col-sm-8 text-nowrap">
              <span>Totale eenmalige kosten</span>
            </div>
            <div class="col-3 col-sm-2 text-right text-nowrap"></div>
            <div class="col-3 col-sm-2 text-right pl-0 text-nowrap">{{ oneTimeTotal|price }}</div>
          </div>
        </div>


        <div class="container mb-1 pb-2" v-if="itemCounts > 0">
          <div class="row">
            <div class="col-6 col-sm-8 text-nowrap">
              <span>BTW 21%</span>
            </div>
            <div class="col-3 col-sm-2 text-right text-nowrap"></div>
            <div class="col-3 col-sm-2 text-right pl-0 text-nowrap">{{ allValues.btvPrice|price }}</div>
          </div>
        </div>

        <div class="container mb-1 pb-2" v-if="itemCounts > 0">
          <div class="row">
            <div class="col-6 col-sm-8 text-nowrap">
              <span class="font-weight-bold theme-text-dark mr-1">Te voldoen</span>
              <span class="font-size-8 font-size-md-12">(incl. BTW)</span>
            </div>
            <div class="col-3 col-sm-2 text-right text-nowrap"></div>
            <div class="col-3 col-sm-2 text-right pl-0 text-nowrap font-weight-bold">{{ allValues.currentTotal|price }}</div>
          </div>
        </div>

        <!-- ########################################## -->


        <div class="" v-if="itemCounts < 1">
            <div class="basket-item">
                <div class="container">
                    <div class="basket-list-item">
			                  <p class="pt-4 pb-0 theme-text-dark font-weight-bold" v-if="inWinkelmand === false">Je hebt momenteel geen producten toegevoegd aan je winkelwagen</p>
                        <div class="height-380 position-relative" v-else>
                          <loading :addCss="'abslt'" />
                        </div>
                    </div>
                </div>
            </div>
          <div class="clearfix"></div>
        </div>
    </div>
</template>

<script>
    import FlexBasketList from "./FlexBasketList";
    import BasisBasketList from "./BasisBasketList";
    import HardwareBasketList from "./HardwareBasketList";
    import NewNumberBasketList from "./NewNumberBasketList";
    import TransferNumberBasketList from "./TransferNumberBasketList";
    import VamoBasketList from "./VamoBasketList";
    import OnlySimBasketList from "./OnlySimBasketList";
    import Loading from "@/components/Loading";

    export default {
        name: "BottomProductsList",
        components:{
          FlexBasketList,
          BasisBasketList,
          HardwareBasketList,
          NewNumberBasketList,
          TransferNumberBasketList,
          VamoBasketList,
          OnlySimBasketList,
          Loading
        },
        props:{
            items:{
                type: Object,
                required: true
            },
            setupPrices:{
                type: Object,
                required: true
            },
            monthlyTotal:{
                type: Number,
                required: true
            },
            oneTimeTotal:{
                type: Number,
                required: true
            },
            allValues:{
              type: Object,
              required: true
            },
            inWinkelmand:{
              type: Boolean,
              required: false,
              default: false
            }
        },
        data(){
            return {
                openedItems:[],
            }
        },
        computed:{
            itemCounts(){
                return Object.keys(this.items).length;
            },
            viewHeader(){
              return this.itemCounts > 0 && this.inWinkelmand === false;
            }
        },
        methods:{
            isGoto(goto){
                return goto !== this.$route.params.sub;
            },
            getSetupPrice(item){
                try{
                    return this.setupPrices[item.basketIndex];
                }catch (e) {
                    return 0;
                }
            },
            getBasketItemByList(item){
                if(Object.prototype.hasOwnProperty.call(this.$root.basket, item.basketIndex)){
                  return this.$root.basket[item.basketIndex];
                }

                return item;
            },
            basketListComponentName(formType){
                return this.$options.filters.capitalize(formType)+'BasketList';
            },
            existComponent(formType) {
                let names = Object.keys(this.$options.components);
                let name = this.basketListComponentName(formType);
                return names.includes(name);
            },
            isOpenedItem(item){
                return this.openedItems.includes(item.basketIndex);
            },
            hideBottomList(){
              this.$root.appEvents.emit('funnel-bottombasket-hide')
            }
        },
        mounted() {
          this.$root.appEvents.on('route-changed', this.hideBottomList);
        },
        destroyed() {
          this.$root.appEvents.removeListener('route-changed', this.hideBottomList);
        }
    }
</script>

<style lang="scss">
    #basket-bottom-list{
      font-size: 15px;
      @include media-breakpoint-down(sm) {
        font-size: 12px;
      }
    }
    .sepet-list-header{
      position: sticky;
      top: 0;
      background-color: #ffffff;
      z-index: 1001;
      .container{
        position: relative;
      }
      .list-close{
        background-color: #f7f7f7;
        position: absolute;
        right: 15px;
        top: 15px;
        width: 32px;
        height: 32px;
        min-width: 32px;
        @include border-radius(50%);
        text-align: center;
        line-height: 32px !important;
        &:hover, &:active{
          background-color: rgba(246, 108, 9, .6);
          color: #ffffff;
        }
      }
    }
    .icon-point{
      display: inline-block;
      width: 5px;
      height: 5px;
      @include border-radius(50%);
      background-color: #000000;
    }
    .card-delete-btn{
        font-size: 20px !important;
        background: #f9f9f9;
        width: 32px;
        height: 32px;
        min-width: 32px;
        @include border-radius(50%);
        text-align: center;
        line-height: 32px !important;
        color: #0b0b0b;
        @include media-breakpoint-down(md) {
          font-size: 15px !important;
        }
      &.fa-times{
        &:hover, &:active{
          background-color: rgba(246, 108, 9, .6);
          color: #ffffff;
        }
        @include media-breakpoint-down(md) {
          &:hover{
            background-color: #f9f9f9;
            color: #0b0b0b;
          }
          &:active{
            background-color: rgba(246, 108, 9, .6);
            color: #ffffff;
          }
        }
      }
    }
    .border2px{
      border-width: 2px !important;
    }
</style>