<template>
    <div id="winkelmand">
        <!--
        <div class="load-screen" v-if="viewLoading">
            <div class="grid-loads height-100"></div>
            <div class="grid-loads height-25"></div>
            <div class="grid-loads height-75"></div>
            <div class="grid-loads height-25"></div>
            <div class="grid-loads height-50"></div>
        </div>
        -->

        <div class="height-380 position-relative" v-if="viewLoading">
            <loading :addCss="'abslt'" v-if="viewLoading" />
        </div>
        <div class="row flex-column mx-md-0 px-md-2" v-if="!viewLoading">

          <BottomProductsList :items="listItems" :setupPrices="setupPriceDetail" :monthlyTotal="monthlyTotal" :oneTimeTotal="oneTimeTotal" :allValues="subPropsValue" :inWinkelmand="true" />

          <!--
          <component v-for="(item, index) in $root.basket" :key="'basket_item'+index" :basketItem="item" v-if="existComponent(item.formType)" v-bind:is="basketListComponentName(item.formType)"/>

          <div class="map-box mobile-edit-item mb-4">
            <div class="map-box-main py-4 theme-text-dark">
              <div class="row">
                <div class="col-12 col-sm-7 d-flex justify-content-start align-items-center">
                    <span class="d-none d-md-block mr-2">1 ×</span>
                    <i class="fas theme-text-blue font-size-28 mr-2 fa-phone-square-alt"/>
                    <span class="font-weight-bold">{{ $root.getUlcValue('packetname','Inclusief onbeperkt bellen') }}</span>
                </div>
                <div class="col-12 col-sm-5 d-flex justify-content-end align-items-center mt-3 mt-md-0">
                  <span class="font-size-20 font-weight-bold ml-sm-auto text-nowrap mr-5">{{ $root.getUlcValue('ulcb2b')|price }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="map-box winkelmand-total">
              <div class="map-box-main p-4">

                <div class="row border-bottom align-items-center mb-4 pb-2">
                    <div class="col-8 col-sm-8">
                        <span class="font-size-17 font-weight-bold font-size-xs-13">Maandelijkse kosten</span>
                        <span class="font-size-12 ml-1 text-nowrap">(excl. BTW)</span>
                    </div>
                    <div class="col-4 text-right">
                        <strong class="mandatory float-sm-right text-nowrap font-size-17 font-size-xs-13"> {{ monthlyTotal|price }}</strong>
                    </div>
                </div>

                <div class="clearfix"></div>
                <div class="row border-bottom align-items-center mb-3 pt-2 pb-2">
                    <div class="col-8 col-sm-8">
                        <span class="font-size-15 font-size-xs-13">Eenmalige kosten </span>
                    </div>
                    <div class="col-4 text-right">
                        <span class="float-sm-right text-nowrap font-size-15 font-size-xs-13"> {{ oneTimeTotal|price }}</span>
                    </div>
                </div>

                <div class="row border-bottom align-items-center mb-3 pb-2">
                    <div class="col-8 col-sm-8 pr-0 pr-sm-2">
                      <span class="font-size-15 font-size-xs-13">Resterende maandelijkse</span>
                      <div class="clearfix d-block d-sm-none"></div>
                      <span class="font-size-15 font-size-xs-13">kosten {{ monthlyName }}</span>
                    </div>
                    <div class="col-4 text-right">
                        <span class="float-sm-right text-nowrap font-size-15 font-size-xs-13"> {{ remainingDayPrice|price }}</span>
                    </div>
                </div>

                <div class="row border-bottom align-items-center mb-4 pb-2">
                  <div class="col-8 col-sm-8">
                    <span class="font-size-15 font-size-xs-13">BTW 21%</span>
                  </div>
                  <div class="col-4 text-right">
                    <span class="float-sm-right text-nowrap font-size-15 font-size-xs-13"> {{ btvPrice|price }}</span>
                  </div>
                </div>

                <div class="row align-items-center pt-2">
                  <div class="col-8 col-sm-8">
                    <span class="font-size-17 font-weight-bold font-size-xs-13">Totaal te betalen vandaag</span>
                    <span class="font-size-12 ml-1 text-nowrap">(incl. BTW)</span>
                  </div>
                  <div class="col-4 text-right">
                    <strong class="mandatory float-sm-right text-nowrap font-size-17 font-size-xs-13"> {{ currentTotal|price }}</strong>
                  </div>
                </div>


              </div>
          </div>
          -->

          <div class="col-12">

            <div class="alert alert-warning d-flex align-items-center flex-column flex-md-row mt-5" v-if="isOnlyNumber">
                <p class="p-0 pt-1 m-0"><i class="fa fa-info-circle mr-2 pt-1"></i><strong class="mandatory">Melding:</strong> Om verder te gaan met bestellen dien je minimaal 1 gebruiker te selecteren.</p>
                <button class="btn btn-green ml-md-auto mt-3 mt-md-0" @click="$root.gotoHome()">Selecteer gebruikers</button>
            </div>

            <div class="alert alert-warning d-flex align-items-center flex-column flex-md-row mt-5" v-if="requiredNumber">
                <p class="p-0 pt-1 m-0"><i class="fa fa-info-circle mr-2 pt-1"></i><strong class="mandatory">Melding:</strong> Om verder te gaan met bestellen dien je minimaal 1 telefoonnummer te selecteren.</p>
                <button class="btn btn-green ml-md-auto mt-3 mt-md-0" @click="$root.gotoPage('telefoonummers')">Selecteer telefoonnummer</button>
            </div>

            <div class="alert alert-success d-flex align-items-center flex-column flex-md-row mt-5" v-if="isOnlyMobile && !requiredNumber">
              <p class="p-0 pt-1 m-0"><i class="fa fa-info-circle mr-2 pt-1"></i>Would you like to add additional users for deskphones and desktop VoIP</p>
              <button class="btn btn-green ml-md-auto mt-3 mt-md-0" @click="$root.gotoHome()">Selecteer gebruikers</button>
            </div>

            <div class="map-box-external-footer mt-5 d-flex align-items-center flex-column flex-sm-row">
                <button class="btn btn-vzkr btn-green ml-sm-auto mt-4 mt-sm-0" @click="onFunnelSet()" :disabled="disabledNextBtn">
                    Ga naar betalen
                    <i class="far fa-arrow-right" v-if="!requestServer"/>
                    <i class="fas fa-spinner fa-pulse" v-else/>
                </button>
            </div>
          </div>

        </div>

    </div>
</template>

<script>
    import FlexBasketList from "./basket/FlexBasketList";
    import BasisBasketList from "./basket/BasisBasketList";
    import HardwareBasketList from "./basket/HardwareBasketList";
    import NewNumberBasketList from "./basket/NewNumberBasketList";
    import TransferNumberBasketList from "./basket/TransferNumberBasketList";
    import VamoBasketList from "./basket/VamoBasketList";
    import OnlySimBasketList from "./basket/OnlySimBasketList";
    import Loading from "./Loading";
    import {Abstract_wilkelmand} from "../mixins/Abstract_wilkelmand";
    import BottomProductsList from "@/components/basket/BottomProductsList";

    export default {
        name: "Winkelmand",
        mixins:[Abstract_wilkelmand],
        components:{
            FlexBasketList,
            BasisBasketList,
            HardwareBasketList,
            NewNumberBasketList,
            TransferNumberBasketList,
            VamoBasketList,
            OnlySimBasketList,
            BottomProductsList,
            Loading
        },
        data(){
            return {
                showTotals:false,
                requestServer:false,
            }
        },
        computed:{
            getBasket(){
                return this.$root.basket;
            },

            isOnlyNumber(){
                //return this.$root.hasNumber === true && (this.$root.hasGebruiker !== true || this.hasMobile !== true);
                if(this.$root.hasNumber === true){
                    if(this.$root.hasGebruiker === true || this.hasMobile === true){
                      return false;
                    }
                }
                return this.$root.hasNumber;
            },

            isOnlyAccount(){
                return this.$root.hasNumber !== true && this.$root.hasGebruiker === true;
            },

            isOnlyMobile(){
                return this.hasMobile === true && this.$root.hasGebruiker !== true;
            },

            hasMobile(){
              return this.$root.hasVamo === true || this.$root.hasSimOnly === true;
            },

            requiredNumber(){
              return (this.hasMobile || this.$root.hasGebruiker) && !this.$root.hasNumber;
            },

            disabledNextBtn(){
              if(this.requestServer === true){
                return true;
              }
              else if(this.$root.hasNumber === true && (this.$root.hasGebruiker !== true && this.hasMobile !== true)){
                return 2;
              }
              else if(this.requiredNumber){
                return true;
              }
              return false;
              //return this.isOnlyNumber === true || this.isOnlyAccount === true;
            },

            viewLoading(){
                // if(this.$root.cookieProductLoaded){
                //     return false;
                // }
                // if(parseFloat(this.monthlyTotal) > 0 || parseFloat(this.oneTimeTotal) > 0){
                //     return false;
                // }
                // return !this.showTotals;
                return !this.$root.cookieProductLoaded;
            }
        },
        watch:{
            getBasket:{
                handler: function(newVal, oldVal){
                    if(Object.keys(this.$root.basket).length < 1){
                        this.$root.gotoHome();
                    }
                },
                deep:true
            }
        },
        methods:{
            basketListComponentName(formType){
                return this.$options.filters.capitalize(formType)+'BasketList';
            },
            existComponent(formType) {

                try{//catch empty item
                  let itemCount = parseInt(this.listItems[formType].count);
                  if(itemCount < 1){
                    return false;
                  }
                }catch (e) {}

                let names = Object.keys(this.$options.components);
                let name = this.basketListComponentName(formType);
                return names.includes(name);
            },
            allProductLoaded(){
                this.basketReCalculate();
                this.showTotals = true;
            },
            cookieLoadListen(){
                let that = this;
                this.allProductLoaded();
                setTimeout(function () {
                    if(Object.keys(that.$root.basket).length < 1){
                        that.$root.gotoHome();
                    }
                },1500);
            },
            hasTheProductBeenAdded(e){
              let that = this;
              setTimeout(function () {
                if(Object.keys(that.$root.basket).length < 1){
                  if(that.showTotals){
                    that.$root.gotoHome();
                  }
                }
              },1500);
            },
            onFunnelSet(){
                let that            = this;

                if(that.isOnlyNumber){
                    that.$myAlert({
                        text: 'Selecteer minimaal 1 gebruiker'
                    });
                    that.$root.gotoPage(that.$root.currentMap.next[0]);
                    return
                }else if(that.isOnlyAccount){
                    that.$myAlert({
                        text: 'Selecteer minimaal 1 telefoonnummer'
                    });
                    that.$root.gotoPage('telefoonummers');
                    return
                }

                try{
                    that.$root.setFunnelCookie();
                }catch (e) {
                    that.$root.errorLog(e);
                }

                that.requestServer = true;
                return this.$root.sendRequest({
                    handler: 'onFunnelSet',
                    key: 'f_info',
                    f_info:JSON.stringify(this.$root.basket),
                    funnelName:that.$root.cookieName,
                    period: that.$root.undertaking
                }, function (http, response) {
                    let data = http.giveAsObject();
                    if(parseInt(data.code) === 200){
                        ///voip/zakelijke-telefonie-pakketten-bestellen
                        window.location.href = '/voip/zakelijke-telefonie-pakketten-bestellen';
                    }
                    else{
                        //@todo
                    }
                    that.requestServer = false;
                });
            }
        },
        mounted() {
            let that = this;
            this.$root.cartPageIsDisplayed = true;
            this.$root.appEvents.on('cookie-product-loaded', this.cookieLoadListen);
            this.$root.appEvents.on('basket-re-calculate', this.allProductLoaded);
            this.$root.appEvents.on('basket.calculated', this.hasTheProductBeenAdded);

            this.$root.appEvents.emit('funnel-bottombasket-status',{status:false});

            setTimeout(function () {
                that.allProductLoaded()
            },1500);

            this.$root.appEvents.emit("page.step", {step:3});
        },
        destroyed() {
            this.$root.appEvents.removeListener('cookie-product-loaded', this.cookieLoadListen);
            this.$root.appEvents.removeListener('basket-re-calculate', this.allProductLoaded);
            this.$root.appEvents.removeListener('basket.calculated', this.hasTheProductBeenAdded);
        }
    }
</script>

<style lang="scss">
    .map-box{
        &.winkelmand-total{
            min-height: auto;
            p{
                margin: 0;
                padding: 0;
                font-size: 15px;
                color:#002d47;
                &.all-total{
                    font-size: 16px;
                }
            }
        }
    }
</style>