<template>
    <div class="carousel-container">
        <div class="packet-price-box d-none">
            <div class="owl-carousel owl-theme packet-owl font-size-15" id="ovlcarousel"
                 data-owl-carousel
                 data-owl-dots="true"
                 data-owl-nav="false"
                 data-owl-center="false"
                 data-owl-start-position="1"
                 data-dot-class="owl-dot dark-dot"
                 data-owl-items="1"
                 data-owl-loop="false"
                 data-owl-autoplay="false"
                 data-owl-autoplay-timeout="2000"
                 data-owl-autoplay-speed="2000"
                 data-owl-margin="0"
                 data-rwd="1-1-1-3-3">

                <div class="item">
                    <div class="packet-box">
                        <div class="packet-box-header">
                            <div class="packet-img"><img src="/templates/Voipzeker/contentsLast/computer/images/funnel/sim_cards.jpg" alt="" /></div>
                            <h3 class="packet-title p-0 m-0">Zakelijk mobiel</h3>
                            <p class="packet-title-info p-0 mb-0 mt-3">Vanaf</p>

                            <div class="packet-price d-flex justify-content-center align-items-center" v-if="hasOnlyVoiceDiscount">
                                <div class="old-price">{{ onlyVoicePrice|price }}</div>
                                <div class="new-price">{{ hasOnlyVoiceDiscountTotal|price }} <span>/mnd*</span></div>
                            </div>
                            <strong class="packet-price p-0  mb-3" v-else>{{ onlyVoicePrice|price }} <span>/mnd</span></strong>

                            <p class="packet-info">VaMo en Sim-Only</p>
                            <button class="btn btn-vzkr btn-orange btn-shadow mb-3" @click="selectPackage(1)" v-if="hasNext">Stel je pakket samen</button>
                            <!-- <p class="mt-4">1 t/m 5 gebruikers {{ basisPrice()|price }} p.m.</p>-->
                            <!--<p class="mt-4"><b>+</b> Eerste 3 maanden 50% korting!</p>-->
                        </div>
                        <div class="packet-box-main" :style="packageBoxStyle">
                            <div class="packet-poperties">
                                <div class="properties-title">Mogelijkheden</div>
                                <div class="properties-items">
                                    <div class="properties-item"><i class="fal fa-mobile"/><span>Vamo en Sim-Only </span></div>
                                    <div class="properties-item"><i class="fal fa-sim-card"/> <span>Dual-sim en eSIM </span></div>
                                    <div class="properties-item"><i class="fal fa-phone-volume"/> <span>500 minuten NL + data</span></div>
                                    <div class="properties-item"><i class="fal fa-phone-office"/><span>Vast nummer op mobiel</span></div>
                                    <div class="properties-item"><i class="fal fa-phone-laptop"/> <span>Integratie met telefooncentrale</span></div>
                                    <div class="properties-item"><i class="fal fa-house"/><span>Zakelijk en privé gescheiden</span></div>
                                    <div class="properties-item"><i class="fal fa-users"/><span>Data delen met collega’s </span></div>
                                    <div class="properties-item"><i class="fal fa-exchange"/><span>Intern doorverbinden </span></div>
                                    <div class="properties-item"><i class="fal fa-microphone"/><span>Gespreksopname </span></div>
                                    <div class="properties-item"><i class="fal fa-voicemail"/><span>Zakelijke voicemail </span></div>
                                    <div class="properties-item">&nbsp;</div>
                                    <div class="properties-item">&nbsp;</div>
                                    <div class="properties-item">&nbsp;</div>
                                    <div class="properties-item">&nbsp;</div>
                                    <div class="properties-item">&nbsp;</div>
                                </div>
                            </div>
                        </div>
                        <div class="packet-box-footer">
                            <a class="packet-collapse" href="/voip/telefoon-centrale">Bekijk alle Unite functies</a>
                        </div>
                    </div>
                </div>

                <div class="item" id="center-packet">
                    <div class="packet-box default-packet">
                        <div class="selected-packet">Nu maandelijks opzegbaar</div>
                        <div class="packet-box-header">
                            <!--<div class="package-flag"><span>1 mnd gratis</span></div>-->
                            <div class="packet-img"><img src="/templates/Voipzeker/contentsLast/computer/images/funnel/desktop_and_vast_op.png" alt="" /></div>
                            <h3 class="packet-title p-0 m-0 mb-3">Zakelijke telefonie</h3>

                            <div class="row mb-4" style="padding-bottom: 13px">
                                <div class="col-6">
                                    <p class="packet-title-info theme-text-blue p-0 mb-3">1 - 5 gebruikers</p>
                                    <!--
                                    <div class="packet-price d-flex flex-column align-items-center" v-if="hasFlexDiscount">
                                        <div class="old-price small-price">{{ flexOnePerPrice|price }}</div>
                                        <div class="new-price small-price text-nowrap">{{ flexDiscountOnePrice|price }} <span>/mnd *</span></div>
                                    </div>
                                    <strong class="packet-price small-price text-nowrap p-0 mb-3" v-else>{{ flexOnePerPrice|price }} <span>/mnd *</span></strong>
                                    -->
                                    <strong class="packet-price small-price text-nowrap p-0 mb-3">{{ flexOnePerPrice|price }} <span>/mnd *</span></strong>
                                </div>
                                <div class="col-6">
                                    <p class="packet-title-info theme-text-blue p-0 mb-3">6 + gebruikers</p>
                                    <!--
                                    <div class="packet-price d-flex flex-column align-items-center" v-if="hasFlexDiscount">
                                        <div class="old-price small-price">{{ flexViewPrice|price }}</div>
                                        <div class="new-price small-price text-nowrap">{{ flexDiscountPrice|price }} <span>/mnd *</span></div>
                                    </div>
                                    <strong class="packet-price small-price text-nowrap p-0 mb-3" v-else>{{ flexViewPrice|price }} <span>/mnd *</span></strong>
                                    -->
                                    <strong class="packet-price small-price text-nowrap p-0 mb-3">{{ flexViewPrice|price }} <span>/mnd *</span></strong>
                                </div>
                            </div>

                            <button class="btn btn-vzkr btn-green btn-shadow mb-3" @click="selectPackage(0)" v-if="hasNext">Stel je pakket samen</button>
                            <!--<p class="mt-4">1 t/m 5 gebruikers {{ flexPrice()|price }} p.m.</p>-->
                            <!--<p class="mt-4"><b>+</b> Eerste 3 maanden 50% korting!</p>-->
                        </div>
                        <div class="packet-box-main" :style="packageBoxStyle">
                            <div class="packet-poperties">
                                <div class="properties-title">Unite Phone</div>
                                <div class="properties-items">
                                    <div class="properties-item"><i class="fal fa-phone-office"/><span>Vaste telefonie</span></div>
                                    <div class="properties-item"><i class="fal fa-desktop"/><span>Desktop bellen(Unite Phone)</span></div>
                                    <div class="properties-item"><i class="fal fa-video"/> <span>Videobellen (intern- en extern)</span></div>
                                    <div class="properties-item"><i class="fal fa-phone-laptop"/> <span>Flexwerken op 3 apparaten</span></div>
                                    <div class="properties-item"><i class="fal fa-phone-volume"/> <span>500 minuten NL</span></div>
                                    <div class="properties-item"><i class="fal fa-cogs"/> <span>75+ telefooncentrale functies</span></div>
                                    <div class="properties-item"><i class="fal fa-users-class"/> <span>Onbeperkt vergaderen</span></div>
                                    <div class="properties-item"><i class="fal fa-columns"/> <span>Receptie dashboard</span></div>
                                    <div class="properties-item"><i class="fal fa-city"/> <span>Bedrijfsnaamherkenning</span></div>
                                    <div class="properties-item"><i class="fal fa-link"/> <span>CRM koppeling</span></div>
                                    <div class="properties-item">&nbsp;</div>
                                    <div class="properties-item">&nbsp;</div>
                                    <div class="properties-item">&nbsp;</div>
                                    <div class="properties-item">&nbsp;</div>
                                    <div class="properties-item">&nbsp;</div>
                                </div>
                            </div>
                        </div>
                        <div class="packet-box-footer">
                            <a class="packet-collapse" href="/voip/telefoon-centrale">Bekijk alle Unite functies</a>
                        </div>
                    </div>
                </div>

                <div class="item">
                    <div class="packet-box">
                        <div class="packet-box-header">
                            <div class="packet-img"><i class="fal fa-city theme-text-dark" /></div>
                            <h3 class="packet-title p-0 m-0 mb-3">Enterprise</h3>
                            <p class="packet-header-text p-0" style="margin-bottom: 11px">Prijsopgave op maat</p>
                            <p class="packet-info-link"><a href="/voip/grootzakelijk/informatie-aanvragen">Demo aanvragen</a></p>
                            <a class="btn btn-vzkr btn-orange-border mb-2" href="/voip/grootzakelijk">Bekijk oplossingen</a>
                            <a class="btn btn-vzkr btn-blue btn-shadow mb-3" href="/voip/grootzakelijk/informatie-aanvragen">Maatwerk offerte aanvragen</a>
                        </div>
                        <div class="packet-box-main" :style="packageBoxStyle">
                            <div class="properties-title"><span> Offerte op maat</span></div>
                            <p class="property-text mb-3 pb-3">Onze experts brengen de specifieke behoeften van het bedrijf in kaart </p>

                            <div class="properties-items">
                                <div class="properties-item"><i class="fal fa-phone-office"/><span>Vaste telefonie</span></div>
                                <div class="properties-item"><i class="fal fa-desktop"/><span>Desktop bellen</span></div>
                                <div class="properties-item"><i class="fal fa-mobile"/><span>Mobiele telefonie</span></div>
                                <div class="properties-item"><i class="fal fa-server"/><span>Internet & netwerkbeheer</span></div>
                                <div class="properties-item"><i class="fal fa-router"/><span>Hardware & bekabeling</span></div>
                                <div class="properties-item"><i class="fal fa-clipboard-check"/><span>Telecomcheck op locatie</span></div>
                                <div class="properties-item"><i class="fal fa-badge-check"/><span>Premium functies</span></div>
                                <div class="properties-item"><i class="fal fa-house-signal"/><span>DECT- en WiFi meting</span></div>
                            </div>

                        </div>
                        <div class="packet-box-footer">
                            <a class="packet-collapse" href="/voip/telefoon-centrale">Bekijk alle Unite functies</a>
                        </div>
                    </div>
                </div>

            </div>

            <p class="font-weight-bold theme-text-dark p-0 font-size-15 korting-text" v-if="hasFlexDiscount">EK voetbal deal - 1 maand gratis zakelijk bellen + extra korting voor elke voetbalwedstrijd die Nederland wint</p>

            <div class="map-box-external-footer mt-5 d-flex align-items-center flex-column flex-sm-row mb-5" v-if="$root.hasprevious('package_select')">
                <a @click="$root.gotoPreviousPage('package_select')" class="theme-text-blue"><i class="far fa-arrow-left"/> Vorige stap</a>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: "Package_select",
        data(){
            return {
                packageBoxStyle:{}
            }
        },
        computed:{
            flex(){
                return this.$root.flexProduct;
            },
            flexOnePerPrice(){
                return this.$root.productPrice(this.flex.id, 1);
            },
            flexViewPrice(){
                return this.$root.packageDefaultPrice(this.$root.flexProduct);
            },
            flexName(){
                return this.flex.name;
            },
            hasFlexDiscount(){
                return this.$root.setPromotionsProductPrice("monthly", this.flex.id, this.flexViewPrice).hasDiscount;
            },
            flexDiscountPrice(){
                return this.$root.setPromotionsProductPrice("monthly", this.flex.id, this.flexViewPrice).total;
            },
            flexDiscountOnePrice(){
                return this.$root.setPromotionsProductPrice("monthly", this.flex.id, this.flexOnePerPrice).total;
            },
            flexPictureUrl(){
                return this.$root.productPicture(this.flex);
            },
            basis(){
                return this.$root.basisProduct;
            },
            basisViewPrice(){
                return this.$root.packageDefaultPrice(this.$root.basisProduct);
            },
            hasBasisDiscount(){
                return this.$root.setPromotionsProductPrice("monthly", this.basis.id, this.basisViewPrice).hasDiscount;
            },
            basisDiscountPrice(){
                return this.$root.setPromotionsProductPrice("monthly", this.basis.id, this.basisViewPrice).total;
            },
            basisName(){
                return this.basis.name;
            },
            hasNext(){
                return this.$root.mapLoading === true && this.$root.loadedPackage === true;
            },
            onlyVoice(){
                return this.$root.onlySimOnlyVoiceProduct;
            },
            onlyVoicePrice(){
                try{
                    return this.$root.productPrice(this.onlyVoice.id, 1);
                }catch (e) {
                    return 0;
                }
            },
            hasOnlyVoiceDiscount(){
                try{
                    return this.$root.setPromotionsProductPrice("monthly", this.onlyVoice.id, this.onlyVoicePrice).hasDiscount;
                }catch (e) {
                    return false;
                }
            },
            hasOnlyVoiceDiscountTotal(){
                try{
                    return this.$root.setPromotionsProductPrice("monthly", this.onlyVoice.id, this.onlyVoicePrice).total;
                }catch (e) {
                    return 0
                }
            },
            onlVoicePictureUrl(){
                return this.$root.productPicture(this.onlyVoice);
            }

        },
        created() {
            let that = this;
        },
        methods:{
            selectPackage(next){
                let redirect;

                // if(!this.$root.basket.hasOwnProperty(id)){
                //     this.$root.addToBasket(id,1,true);
                // }

                /*
				try {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({event: "Start-funnel"});
                }catch (e) {}
				*/

                if(this.$route.name === "singlePackage"){
                    redirect = this.$router.resolve({ name: 'subPage', params: { map:this.$route.params.map, sub:this.$root.currentMap.package_select.next[next] } });
                    window.location.href = redirect.href;
                }else{
                    this.$root.gotoNextPage("package_select", next);
                }
            },
            flexPrice(){
                try {
                    let product = this.$root.getProducts("flex");
                    let price = product.priceList[1];
                    if(this.hasFlexDiscount){
                        price = this.$root.setPromotionsProductPrice("monthly", this.flex.id, price).total
                    }
                    return price;
                }catch (e) {
                    return '...';
                }
            },
            basisPrice(){
                try {
                    let product = this.$root.getProducts("basis");
                    let price = product.priceList[1];
                    if(this.hasBasisDiscount){
                        price = this.$root.setPromotionsProductPrice("monthly", this.basis.id, price).total
                    }
                    return price;
                }catch (e) {
                    return '...';
                }
            },
            redirectGebruiker(){
              this.$root.gotoPage("gebruikers");
            }
        },
        mounted() {
            let boxes;
            let that = this;

            if(that.$root.cookieProductLoaded){
              that.redirectGebruiker();
            }


            this.$root.appEvents.on("cookie-product-loaded", function () {
              that.redirectGebruiker();
            });

            if(!this.$route.params.hasOwnProperty('map')){
                //this.$root.gotoHome()
            }
            let evt = new CustomEvent("reinit_owl_carousel", {});
            document.dispatchEvent(evt);
        },
        destroyed() {
          let that = this;
          this.$root.appEvents.removeListener("cookie-product-loaded", function () {
            that.redirectGebruiker();
          });
        }
    }
</script>

<style lang="scss">
    .carousel-container{
        position: relative;
        width: 100%;
        overflow-x: hidden;
    }
    .packet-price-box{
        margin: 100px auto 30px auto;
        width: 100%;
        max-width: 1100px;
        /**/
        @include media-breakpoint-down(md) {
             max-width: 500px;
        }
        @include media-breakpoint-down(xs) {
            max-width:320px;
        }
        /**/
        .owl-carousel.packet-owl {
            .owl-stage-outer{
                width: 100%;
                /**/overflow: unset !important;/**/
            }
            .owl-dots{
                position: absolute;
                top: -50px;
                left: 0;
                width: 100%;
                text-align: center;
                .owl-dot{
                    span{
                        display: block;
                        width: 10px;
                        height: 10px;
                        margin: 0 5px;
                        border-radius: 50%;
                        background-color: #CCCCCC;
                    }
                    &.active{
                        span{
                            background-color: #002d47;
                        }
                    }
                }
            }
        }
        .packet-box{
            background-color: #ffffff;
            -webkit-border-radius: 10px;
            border-radius: 10px;
            -webkit-box-shadow: 0 0 10px 3px #C7C7C7;
            box-shadow: 0 0 10px 3px #C7C7C7;
            font-size: 15px;
            margin: 50px 10px;
            .selected-packet{
                display: none;
                -webkit-border-radius: 15px 15px 0 0;
                border-radius: 15px 15px 0 0;
            }
            &.default-packet{
                -webkit-border-radius: 0 0 5px 5px;
                border-radius: 0 0 5px 5px;
                .selected-packet{
                    display: block;
                    background-color: #002d47;
                    width: 100%;
                    height: 50px;
                    margin: -50px 0px 0 0;
                    float: left;
                    color: #ffffff;
                    padding: 15px 15px 0 15px;
                    text-align: center;
                    font-weight: bold;
                    font-size: 15px;
                }
            }
            .packet-box-header{
                text-align: center;
                padding: 30px 15px 15px 15px;
                border-bottom: 1px solid #ebebeb;
                position: relative;
                .package-flag{
                    height: 29px;
                    background-color: #82b636;
                    color: #FFFFFF;
                    font-size: 15px;
                    position: absolute;
                    left: 0;
                    top: 15px;
                    line-height: 29px;
                    padding-left: 5px;
                    padding-right: 5px;
                    &:after{
                        content: "";
                        position: absolute;
                        right: -9.5px;
                        top: 0;
                        width: 0;
                        height: 0;
                        border: 0 solid transparent;
                        border-bottom-width: 14.5px;
                        border-top-width: 14.5px;
                        border-left: 10px solid #82b636;
                    }
                }
                .packet-img{
                    text-align: center;
                    height: 54px;
                    img{
                        width: auto;
                        height: 54px;
                        margin: 0 auto;
                    }
                    i{
                        font-size: 40px;
                        margin: 0 auto 28px auto;
                    }
                }
                .packet-title{
                    font-weight: bold;
                    font-size: 28px;
                    color:#002d47;
                }
                .packet-title-info{
                    font-size: 13px;
                }
                .packet-header-text{
                    font-size: 18px;
                    color:#002d47;
                    margin-bottom: 50px;
                }
                .packet-info-link{
                    a {
                        font-size: 13px;
                        color: #00ccff;
                        display: block;
                        margin-bottom: 18px;
                    }
                }
                .packet-price{
                    font-size: 38px;
                    color:#002d47;
                    font-weight: bold;
                    display: block;
                    &.small-price{
                        font-size: 26px;
                    }
                    .new-price{
                        &.small-price{
                            margin-top: -5px;
                            font-size: 28px;
                        }
                    }
                    .old-price{
                        font-size: 22px;
                        margin-right: 10px;
                        &.small-price{
                            margin-right: 0;
                            margin-bottom: 0;
                            font-size: 15px;
                        }
                    }
                    span{
                        font-size: 13px;
                        font-weight: normal;
                        color:#002d47;
                    }
                }
                .packet-info{
                    font-size: 13px;
                    color:#002d47;
                }
                .btn.btn-vzkr{
                    -webkit-border-radius: 5px;
                    border-radius: 5px;
                    display: block;
                    width: 100%;
                    max-width: 95%;
                    margin-left: auto;
                    margin-right: auto;
                }
            }

            .packet-box-main{
                padding: 15px;
                .properties-title{
                    font-size: 15px;
                    color: #002d47;
                    font-weight: bold;
                    margin-bottom: 5px;
                }
                .properties-items{
                    margin-bottom: 0;
                    .properties-item{
                        font-size: 15px;
                        color: #002d47;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items:center;
                        margin-bottom: 5px;
                        .fal{
                            margin-right: 5px;
                            color:#187DC1;
                            display: inline-block;
                            width: 18px;
                            text-align: center;
                        }
                    }
                }
            }

            .packet-box-footer{
                text-align: center;
                padding: 15px;
                border-top: 1px solid #ebebeb;
                -webkit-transition: all 300ms ease;
                -moz-transition: all 300ms ease;
                -o-transition: all 300ms ease;
                transition: all 300ms ease;
                &:hover{
                    background-color: #ebebeb;
                }
                .packet-collapse{
                    font-size: 14px;
                    color:#00ccff;
                    text-decoration: none;
                    cursor: pointer;
                }
            }
        }
    }
    .korting-text{
        float: left;
        margin-top: -30px;
        margin-left: 15px;
    }
</style>