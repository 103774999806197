const AllFilters = {
    price:function (value) {
        let val;
        if (!value) {
            value = 0;
        }
        val = (value/1).toFixed(2).replace('.', ',');
        return '€ '+val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    htmlPrice:function (value) {
        let val;
        let piece = [];
        if (!value) {
            value = 0;
        }
        val = (value/1).toFixed(2).replace('.', ',');
        val = '€ '+val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        piece = val.split(',');
        piece[piece.length - 1] = '<span class="cent">'+piece[piece.length - 1]+'</span>';
        return '<span class="h-price text-nowrap">'+piece.join(',')+'</span>';
    },
    rawPrice(value){
        let val;
        if (!value) {
            value = 0;
        }
        val = (value/1).toFixed(2).replace('.', ',');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    capitalize: function (value) {
        if (!value) {
            return '';
        }
        value = value.toString();
        return value.charAt(0).toUpperCase() + value.slice(1);
    },
    deCapitalize: function (value) {
        if (!value) {
            return '';
        }
        value = value.toString();
        return value.charAt(0).toLowerCase() + value.slice(1);
    },
    mapIndex(index){
        if(!index){
            return '';
        }
        return index.replace(/\-/g, '_');
    },
    dataText(val){
        if(val < 1024){
            return val+' MB';
        }
        return (val / 1024)+ ' GB';
    },
    makeid(length) {
        let result           = [];
        let characters       = 'abcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for ( let i = 0; i < length; i++ ) {
            result.push(characters.charAt(Math.floor(Math.random() *
                charactersLength)));
        }
        return result.join('');
    }
};

export { AllFilters }