<template>
    <div class="item">
        <div class="hardware-product package-product-item px-md-5">

            <div class="dicount-message" v-if="hasDiscount">
                <span>Nu maandelijks opzegbaar</span>
            </div>
            <div class="dicount-message" v-else>
                <span>Nu maandelijks opzegbaar</span>
            </div>


            <div class="device-photo package-img" :class="imgWrapClass">
                <div class="package-img-wrap" v-for="imgSrc in productImgs">
                    <img :src="imgSrc" alt="" />
                </div>
            </div>
            <div class="device-info mb-3">
                <h3 class="device-title">{{ productTitle }}</h3>
                <ul class="device-props elastic-props">
                    <li class="text-truncate" v-for="pItem in productProp">{{ pItem }}</li>
                </ul>

                <div class="hw-price-list">
                    <div class="" v-if="hasDiscount">
                        <div class="bottom-price d-flex align-items-center justify-content-center" v-if="hasDiscount">
                            <div class="old-price grey-text font-weight-bold text-nowrap mr-2">{{ productPrice|price }}</div>
                            <div class="new-price hw-new-price font-weight-bold text-nowrap font-size-20">{{ discountPrice|price }}*</div>
                            <div class="new-price hw-new-price font-weight-bold text-nowrap font-size-20 ml-1" v-if="variants">+ {{ variantPrice|price }}</div>
                        </div>
                    </div>
                    <div class="bottom-price d-flex align-items-center justify-content-center" v-else>
                        <span class="hw-new-price">{{ productPrice|price }}*</span>
                        <div class="new-price hw-new-price font-weight-bold text-nowrap font-size-20 ml-1" v-if="variants">+ {{ variantPrice|price }}</div>
                    </div>
                </div>
            </div>

            <NumberCounter :product="product" :pType="pType" :basketCount="basketCount" v-on:changeCount="basketChanged" />
        </div>
    </div>
</template>

<script>
    import NumberCounter from "../tools/NumberCounter";
    export default {
        name: "FlexBasisProduct",
        components:{NumberCounter},
        props:{
            product: {
                type: Object,
                require: true
            },
            pType: {
                type: String,
                require: true
            },
            productTitle:String,
            variants:{
                type:Boolean,
                default:false
            },
            variant:String,
            basketCount:[String, Number],
            productProp:Array
        },
        data(){
            return {
                vastProduct:{},
                mobileProducts:{},
                variantsProps:["vast1", "vast2", "usesim", "useDijitalSim"]
            }
        },
        computed:{
            productPrice(){
                //let price   = this.$root.packageDefaultPrice(this.product);
                //price       += this.variantPrice;
                //return price;
                return this.$root.productPrice(this.product.id, 1);
            },
            hasDiscount(){
                //let price = this.$root.packageDefaultPrice(this.product);
                let price = this.$root.productPrice(this.product.id, 1);
                return this.$root.setPromotionsProductPrice("monthly", this.product.id, price).hasDiscount;
            },
            discountPrice(){
                //let price   = this.$root.packageDefaultPrice(this.product);
                let price   = this.$root.productPrice(this.product.id, 1);
                let total   = this.$root.setPromotionsProductPrice("monthly", this.product.id, price).total;
                //total       += this.variantPrice;
                return total;
            },
            variantPrice(){
                let price = 0;
                if(this.variants){
                    if(this.variant === "vast1"){
                        price = parseFloat(this.vastProduct.priceList[1]);
                    }
                    else if(this.variant === "vast2"){
                      price = parseFloat(this.vastProduct.priceList[1]);
                    }
                }
                return price;
            },
            imgWrapClass(){
                if(this.variants){
                    return 'double-img';
                }
                return {};
            },
            productImgs(){
                let imgs = [];
                imgs.push(this.$root.productPicture(this.product));
                if(this.variants){
                    imgs.push(this.$root.productPicture(this.$root.basisProduct));
                }
                return imgs;
            },
            productId(){
                return this.product.id;
            },
            selfBasket(){/*only readonly*/
                return this.$root.basket[this.productId];
            },
            orderCount(){/*only readonly*/
                return this.$root.basket[this.productId].orderCount;
            },
        },
        methods:{
            basketChanged(upDown){
                if(upDown !== "up"){
                    this.decreaseProduct();
                }else{
                    this.increaseProduct();
                }
                if(this.pType === "flex"){
                    this.flexOptionFormatter();
                }
            },
            increaseProduct(){
                if(this.basketCount >= 50){
                    this.$myAlert({
                        title:'Maximale afname bereikt',
                        text:'Voor dit product is online een afname van maximaal 50 stuks toegestaan. Meer nodig? Bel ons op 088 0660 550',
                    });
                }
                else{
                    this.$root.addToBasket(this.productId);
                }
            },
            decreaseProduct(){
                let that = this;
                let basketOptions = [];
                let deletedKey = 0;

                if(that.pType === "flex"){
                    basketOptions = this.$root.basket[this.productId].options;
                    deletedKey = 0;

                    if(this.variants){
                        deletedKey = that.findFlexVairantKey();
                    }else{
                        deletedKey = that.findWithOutVariantFlex();
                    }

                    basketOptions = basketOptions.filter(function (item, key) {
                        return deletedKey !== key;
                    });

                    this.$root.basket[this.productId].options = basketOptions;
                    this.$root.basket[this.productId].orderCount -= 1;
                    if(this.$root.basket[this.productId].orderCount < 1){
                        try {
                            delete this.$root.basket[this.productId];
                        }catch (e) {}
                    }
                    this.$root.appEvents.emit('change_basket', this.basket);
                    this.$root.appEvents.emit('basket-re-calculate');
                }
                else{
                    this.$root.removeToBasket(this.productId);
                }
            },
            
            prepareExtraProduct(){
                let that = this;
                that.mobileProducts = {};
                Object.entries(this.product.relation).forEach(function ([k,v]) {
                    if(v.type === 115 || parseInt(v.type) === 115){
                        that.vastProduct = v;
                    }
                    else if(v.type === 117 || parseInt(v.type) === 117){
                        that.mobileProducts[k] = v;
                    }
                });
            },

            flexOptionFormatter(){
                let vast1 = false, vast2 = false, usesim = false, useDijitalSim = false;
                let x;
                let basketOptions = [];

                if(!this.$root.basket.hasOwnProperty(this.productId)){
                    return;
                }

                basketOptions = this.$root.basket[this.productId].options;

                if(this.variants){
                    if(this.variant === "vast1"){
                        vast1 = true;
                    }else if(this.variant === "vast2"){
                        vast2 = true;
                    }else if(this.variant === "usesim"){
                        usesim = true;
                    }else if(this.variant === "useDijitalSim"){
                        useDijitalSim = true;
                    }
                }
                for(x = 0; x < this.selfBasket.orderCount; x++ ){
                    if(typeof basketOptions[x] === "undefined"){
                        basketOptions[x] = {
                            name:'Gebruiker '+(x + 1),
                            vastProductId:this.vastProduct.id,
                            vast1,
                            vast2,
                            vastPrice:this.vastProduct.priceList[1],
                            vastOrderCount:0,
                            usesim,
                            selectInternetPackage:Object.keys(this.mobileProducts)[0],
                            useDijitalSim,
                            useTransfer:false,
                            transferNumber:'',
                            isValidTransferNumber:false,
                            internationalTransferNumber:'',
                            formatterTransferNumber:''
                        }
                    }
                    else{
                        if(typeof basketOptions[x].name === "undefined"){
                            basketOptions[x].name = 'Gebruiker '+(x + 1);
                        }
                    }
                }
                if(this.orderCount === basketOptions.length){
                    this.$root.basket[this.productId].options = Array.from(basketOptions);
                }else{
                    this.$root.basket[this.productId].options = Array.from(basketOptions.slice(0, this.orderCount));
                }
                this.$root.appEvents.emit('change_basket', this.$root.basket);
                this.$root.appEvents.emit('basket-re-calculate');
            },

            /**
             * variant olarak eklenmis bir girdinin key degerini doner
             * @returns {number}
             */
            findFlexVairantKey(){
                let x = 0;
                let basketOptions = this.$root.basket[this.productId].options;
                for(x = 0; x < basketOptions.length; x++){
                    if(basketOptions[x][this.variant] === true){
                        return x;
                    }
                }
                return x;
            },
            /**
             * hic variant eklenmemeis girdinin key degerini doner
             * @returns {number}
             */
            findWithOutVariantFlex(){
                let x = 0, i = 0, vName = '';
                let basketOptions = this.$root.basket[this.productId].options;
                let item = {};
                let status = null;
                for(x = 0; x < basketOptions.length; x++){
                    status = true;
                    item = basketOptions[x];
                    for(i = 0; i < this.variantsProps.length; i++){
                        vName = this.variantsProps[i];
                        if(item[vName] === true){
                            status = false;
                        }
                    }
                    if(status === true){
                        return x;
                    }
                }
                return x;
            }
        },
        created() {
            if(this.pType === "flex"){
                this.prepareExtraProduct();
            }
        }
    }
</script>

<style lang="scss">
    .hardware-product{
        .device-photo{
            .picture-separator{
                display: none;
            }
            &.double-img{
                height: 112px;
                width: 100%;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: center;
                align-items: flex-start;
                align-content: center;
                .picture-separator{
                    display: inline-block;
                }
                .package-img-wrap{
                    position: relative;
                    padding-right: 28px;
                    img{
                        height: 80px;
                        margin-top: 16px;
                    }
                    &:after{
                        font-family: 'Font Awesome 5 Pro';
                        font-weight: 300;
                        content: "\f067";
                        position: absolute;
                        width: 28px;
                        height: 28px;
                        font-size: 28px;
                        top: calc(50% - 18px);
                        right: 2px;
                    }
                    &:last-child{
                        padding-right: 0;
                        margin-right: 0;
                        &:after{
                            display: none;
                            content: "";
                        }
                    }
                }
            }
            .package-img-wrap{
                height: inherit;
            }
        }
        &.package-product-item{
            position: relative;
            padding-top: 80px;
            .dicount-message{
                width: 100%;
                height: 34px;
                color: #ffffff;
                font-weight: bold;
                background: #82b636;
                position: absolute;
                top: 20px;
                left: 0;
                font-size: 14px;
                line-height: 35px;
            }
        }
    }
</style>